import React, { createContext, useContext, useReducer } from "react";
import { initialState, photographerReducer } from "./photographerReducer";

// Prepares the dataLayer
const photographerStateContext = createContext();
const photographerDispatchContext = createContext();

export function usePhotographerState() {
	const context = useContext(photographerStateContext);
	if (context === undefined) {
		throw new Error('usephotographerState must be used within a photographerProvider');
	}

	return context;
}

export function usePhotographerDispatch() {
	const context = useContext(photographerDispatchContext);
	if (context === undefined) {
		throw new Error('usephotographerDispatch must be used within a photographerProvider');
	}

	return context;
}

export const PhotographerProvider = ({ children }) => {
  const [photographer, dispatch] = useReducer(photographerReducer, initialState);

  return (
    <photographerStateContext.Provider value={photographer}>
      <photographerDispatchContext.Provider value={dispatch}>
        {children}
      </photographerDispatchContext.Provider>
    </photographerStateContext.Provider>
  );
};
