import React from "react";
import PropTypes from 'prop-types';
import DownIcon from "mdi-react/ChevronDownIcon";
import AccountCircleIcon from "mdi-react/AccountCircleOutlineIcon";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import TopbarMenuLink from "./TopbarMenuLink";
import * as ROUTES from "../../constants/routes";
import {  useHistory } from 'react-router-dom';
import { welcomeLoginClicked, welcomeSignupClicked } from "../../utils/cleverTapLogger";
const TopbarGuest = () => {

  return (
    <div className="user d-inline-block">
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle className="p-0 pl-1 header-icon" color="empty">
           <AccountCircleIcon size='28'  className="vm"/>
         
        </DropdownToggle>
        <DropdownMenu className="mt-3" right>
        <TopbarMenuLink
        title="Login"
            icon="logInIcon" //"user"
            path={ROUTES.LOGIN}
            onClick={()=>welcomeLoginClicked()}
          />
           <TopbarMenuLink
            title="Sign Up"
            icon="signUpIcon"
            path={ROUTES.SIGN_UP}
            onClick={()=>welcomeSignupClicked()}
          />
        
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};


export default TopbarGuest;
