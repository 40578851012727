import React from "react";
import PropTypes from 'prop-types';
import DownIcon from "mdi-react/ChevronDownIcon";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

import TopbarMenuLink from "./TopbarMenuLink";
import * as ROUTES from "../../constants/routes";
import {  useUserDispatch } from "../../contexts/user/userContext";
import {logout} from "../../contexts/user/userActions";
import {  useHistory } from 'react-router-dom';
import { useCampaignDispatch } from "../../contexts/campaign/campaignContext";
import { useCartDispatch } from "../../contexts/cart/cartContext";
import { useExploreDispatch } from "../../contexts/explore/exploreContext";
import { setUpBrandClicked, accountDetailsClicked} from "../../utils/cleverTapLogger"
const TopbarProfile = ({ user }) => {
  //const { user } = useUserState();
  const dispatch = useUserDispatch();
  const campaignDispatch = useCampaignDispatch();
  const cartDispatch = useCartDispatch();
  const exploreDispatch = useExploreDispatch();
  const history = useHistory();

  const handleLogout = () => {
    logout(dispatch, campaignDispatch,cartDispatch);
    history.push(ROUTES.HOME);
    
    window.location.reload();
};

const title = (!user.is_brand  && !user.brand_data_updates) ? "Setup your brand" :
              (!user.is_brand  && user.brand_data_updates && user.brand_status === 3) ? "Brand pending aproval" :
              (!user.is_brand  && user.brand_data_updates && user.brand_status === 1) ? "Complete your brand data" :
              (user.is_brand  && !user.brand_data_updates) ? "Edit your brand" : 
              (user.is_brand  && user.brand_data_updates)? "Your updates are under review": "Brand pending aproval"

  return (
    <div className="user d-inline-block">
      <UncontrolledDropdown className="dropdown-menu-right">
        <DropdownToggle className="p-0" color="empty">
        <span>
            <img alt="Profile" src={user.thumbnail} />
          </span>
          <span className="name mr-1">{user.fullName}  <DownIcon/></span>
         
        </DropdownToggle>
        <DropdownMenu className="mt-3" right>
        <TopbarMenuLink
            title="My Account"
            icon="profileIcon" //"user"
            path={ROUTES.PROFILE}
            onClick={()=>accountDetailsClicked()}
          />
           <TopbarMenuLink
            title="Change Password"
            icon="cog"
            simple= {true}
            path={ROUTES.CHANGE_PASSWORD}
          />
          <TopbarMenuLink
            title={title}
            icon= "brandIcon"  //"cog"
            path={ROUTES.BRAND}
            onClick={()=> title === "Setup your brand" ? setUpBrandClicked() : null }
          />
          <DropdownItem divider />
          <TopbarMenuLink
            title="Sign Out"
            icon= "logOutIcon"  //"exit"
            path={ROUTES.HOME}
            onClick={handleLogout}
          />
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

TopbarProfile.propTypes = {
    userThumbnail: PropTypes.string, 
    fullName: PropTypes.string, 
    isBrand: PropTypes.bool
  };

export default TopbarProfile;
