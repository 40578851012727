import axios from "./axios";
import {logout} from "../contexts/user/userActions";

export const setAuthToken = (token) => {
  axios.defaults.headers = {
    "content-type": "application/json",
    "x-lang-code": "en-us",
    "x-user-type": 1,
    'Accept': 'application/json',
  }
  if (token) {
    axios.defaults.headers["x-auth-token"] = token;
  } else {
    delete axios.defaults.headers["x-auth-token"];
  }

};
var interceptor;
export const setAxiosTokenInterceptor = (accessToken, dispatch, campaignDispatch,cartDispatch)=> {
  if (interceptor != undefined)
{
  axios.interceptors.request.eject(interceptor)
}
   interceptor = axios.interceptors.request.use( config => {
    const requestConfig = config
    if (accessToken) {
      requestConfig.headers["x-auth-token"]  = accessToken
    } 
    else {
    delete requestConfig.headers["x-auth-token"];
  }

    return requestConfig
  })

  if (!accessToken.includes("guest")) {
  axios.interceptors.response.use(response => {
     return response;
  }, error => {

  if (error.response.status === 401) {
    
    logout(dispatch, campaignDispatch,cartDispatch);
  }

  return Promise.reject(error);
});
  }
 }

export default setAuthToken;
