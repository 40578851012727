import React from "react";
import TopbarBasic from "../components/topbar/TopbarBasic";
import { withRouter} from "react-router-dom";
const PublicLayout = (props) => {
  return (
    <div id="app-container" className="menu-default sub-hidden">
      <TopbarBasic />
      <main>
        <div className="container-fluid">
        {props.children}
        </div>
      </main>
    </div>
  );
};

export default withRouter(PublicLayout);
