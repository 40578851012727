export const emailPatter =
  /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;

export const phonePatter = /^-?[0-9]\d*\.?\d*$/;
export const urlPattern =
  /(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/; ///https?:\/\

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const getUserIP = async () => {
  try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      const location = {
        ip: data.ip,
        city: data.city,
        region: data.region,
        timezone: data.timezone,
      };
      return location;
    } catch (error) {
      console.log(error);
      return null;
    }
};

export const getCommaSeparatedString = (array, property) => {
  return array.map(obj => obj[property]).join(', ');
};

export const winLocation = (term) => {
  return window.location.href.includes(term);
}

export const  findSectionTitle= ()=>
{
var sectionTitle;
switch (true) {
  case winLocation("/ml/"):
      sectionTitle = "Most Liked"
      break;
  case winLocation("/np/"):
    sectionTitle = "New Phlogs";
      break;
 case winLocation("/nu/"):
    sectionTitle = "New Uploads";
      break;
   case winLocation("/ep/"):
    sectionTitle = "Editor's Pick";
      break;
case winLocation("/ct/"):
    sectionTitle = "Common Tags";
      break;
  default:
    sectionTitle = "";
}
return sectionTitle;
}

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
}

export default emailPatter;
