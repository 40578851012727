import React from "react";
import Topbar from "../components/topbar/Topbar";
import Sidebar from "../components/sidebar/Sidebar";
import { useSidebarState } from "../contexts/sidebar/sidebarContext";
import { withRouter } from "react-router-dom";
import Footer from "../components/footer/Footer";
const AppLayout = (props) => {
  const { containerClassnames } = useSidebarState();
  return (
    <div id="app-container" className={containerClassnames}>
      <Topbar />
      <Sidebar />
      <main>
        <div className="container-fluid">{props.children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default withRouter(AppLayout);
