import * as React from "react"

const BrandIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M130 57.8c-1.9.9-4.5 3.2-5.7 5.1l-2.3 3.4V235H94.1c-27.2 0-27.9 0-31.2 2.3-7.3 4.8-6.9.2-6.9 87.7 0 72.7.1 78.8 1.8 82 4.7 9.5 16.5 10.7 24.2 2.5l2.5-2.7.3-71.4.2-71.4h92.9l.3 70.2c.3 68.2.4 70.4 2.3 73.7 5.8 9.8 20 9.3 25.2-.9l2.3-4.4V208h159l.2 99.4.3 99.4 2.5 2.7c7.7 8.2 19.5 7 24.2-2.5 1.7-3.3 1.8-10.6 1.8-110.5 0-102.5-.1-107.2-1.9-110.5-1-1.9-3.4-4.4-5.2-5.5-3.1-1.8-5.5-2-31.1-2.3l-27.8-.3V66.3l-2.3-3.4c-4.9-7.3 1.5-6.9-101.7-6.9-86.1 0-92.7.1-96 1.8zm171 73.7v46.4l-56.4.3c-55.2.3-56.5.3-59.2 2.4-6.8 5-6.9 5.2-7.2 31l-.4 23.4H151V85h150v46.5z" />
    <path d="M182.7 114.1c-3.2 1.9-4.7 7.2-4.7 17.1 0 18.4 1.4 19.8 20.5 19.8 12.6 0 12.7 0 15.6-2.9l2.9-2.9v-13c0-12.6-.1-13.2-2.6-16.1l-2.6-3.1h-13.6c-7.9 0-14.5.5-15.5 1.1zM237.6 116.1c-2.5 2.9-2.6 3.5-2.6 16.1v13l2.9 2.9c2.9 2.9 3 2.9 15.6 2.9 19.1 0 20.5-1.4 20.5-19.8 0-10.1-1.5-15.3-4.8-17.1-1.2-.6-7.8-1.1-15.5-1.1h-13.5l-2.6 3.1zM237.9 237.9c-2.9 2.9-2.9 3-2.9 15.5 0 18.8.9 19.8 17.5 20.4 19.3.7 22-2 21.3-21.3-.6-16.6-1.6-17.5-20.4-17.5-12.5 0-12.6 0-15.5 2.9zM303.9 237.9c-2.9 2.9-2.9 3-2.9 15.6 0 19.1 1.4 20.5 19.8 20.5 10.1 0 15.3-1.5 17.1-4.8.6-1.2 1.1-7.8 1.1-15.5v-13.5l-3.1-2.6c-2.9-2.5-3.5-2.6-16.1-2.6h-13l-2.9 2.9zM118.3 292.5c-1.7.7-3.6 2.2-4.2 3.4-.6 1.1-1.1 7.7-1.1 15.4v13.5l3.1 2.6c2.9 2.5 3.5 2.6 16.1 2.6h13l2.9-2.9c2.9-2.9 2.9-3 2.9-15.6 0-15-1-17.9-6.8-19.4-5.4-1.6-22.1-1.3-25.9.4zM242.5 291.8c-6.6 2.2-7.5 4.6-7.5 19.7 0 12.7 0 12.7 2.9 15.6 2.9 2.9 3 2.9 15.5 2.9 18.8 0 19.8-.9 20.4-17.5.7-18.8-1.9-21.6-19.5-21.4-5.4.1-10.7.4-11.8.7zM307.3 292.1c-5.2 1.5-6.3 4.8-6.3 19.4 0 12.6 0 12.7 2.9 15.6l2.9 2.9h13c12.6 0 13.2-.1 16.1-2.6l3.1-2.6v-13.5c0-7.7-.5-14.3-1.1-15.5-1.8-3.4-7-4.8-17.6-4.7-5.4 0-11.3.5-13 1zM116.1 350.6l-3.1 2.6v13.5c0 7.7.5 14.3 1.1 15.5 1.8 3.3 7 4.8 17.1 4.8 18.4 0 19.8-1.4 19.8-20.5 0-12.6 0-12.7-2.9-15.6l-2.9-2.9h-13c-12.6 0-13.2.1-16.1 2.6zM237.9 350.9c-2.9 2.9-2.9 3-2.9 15.5 0 18.8.9 19.8 17.5 20.4 19.3.7 22-2 21.3-21.3-.6-16.6-1.6-17.5-20.4-17.5-12.5 0-12.6 0-15.5 2.9zM303.9 350.9c-2.9 2.9-2.9 3-2.9 15.6 0 19.1 1.4 20.5 19.8 20.5 10.1 0 15.3-1.5 17.1-4.8.6-1.2 1.1-7.8 1.1-15.5v-13.5l-3.1-2.6c-2.9-2.5-3.5-2.6-16.1-2.6h-13l-2.9 2.9z" />
  </svg>
)

export default BrandIcon
