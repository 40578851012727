import CleverTapPlugin from "clevertap-web-sdk";
import format from "date-fns/format";
import {getUserIP} from "../utils/helpers";

export const loginCTUser = async(data) => 
{
  var ipInfo = await getUserIP();
    CleverTapPlugin.onUserLogin.push({"Site": {
        Name: data.name,
        Identity: data.id,
        Email: data.email,
        Phone: data.phone,
        Ip: ipInfo?.ip ?? '',
        City: ipInfo?.city ?? '',
        Region: ipInfo?.region ?? '',
        Timezone: ipInfo?.timezone ?? '',
    }});
    localStorage.setItem('profileCreated', true); 
}

export const updateCTProfile = (data) => {
  CleverTapPlugin.profile.push({
    Name: data.name,
    dob:  new Date(),
    Identity: data.id,
    Email: data.email,
    Phone: data.phone,
  });
};

export const logEvent = (
  event,
  customProperties = {},
  processingTime = "",
  apiTimestamp = ""
) => {
  // Map<String, dynamic> properties = await ClevertapStandardProperties.getProperties(
  // event,
  // processingTime: processingTime,
  // apiTimestamp: apiTimestamp,
  // );
    // Retrieve last visit timestamp from localStorage
    //const lastVisit = localStorage.getItem('lastVisit');

  var properties = {
      screen_dpi: window.devicePixelRatio,
    screen_height: window.innerHeight,
    screen_width: window.innerWidth,
    wifi: navigator.onLine,
    event_id: event.id,
    processing_time: processingTime,
    API_timestamp: apiTimestamp,
    date_time: new Date(),
    os_source: "Web",
    event_name: event.e,
    // 'user_id': user.id,
    // 'user_name': user.name,
    // 'user_email': user.email,
    //user_last_seen: lastVisit,
    //user_first_app_open: !lastVisit ? new Date().toLocaleString() : ,
    //'business_id': user.id
  };
  properties = {...properties , ...customProperties};

  CleverTapPlugin.event.push(event.e, properties);
};

export default logEvent;
