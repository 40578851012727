import React, { Suspense,lazy, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import PrivateDefaultLayoutRoute from './PrivateDefaultLayoutRoute';
import PublicDefaultLayoutRoute from './PublicDefaultLayoutRoute';
import Alerts from "../alert/Alerts";
import NotFound from "../../layout/NotFound";
import {loadUser} from "../../contexts/user/userActions";
import {useUserState, useUserDispatch} from "../../contexts/user/userContext";
import {useAlertDispatch} from "../../contexts/alert/alertContext";
import {setAxiosTokenInterceptor} from "../../utils/setAuthToken";
import { useCampaignDispatch } from "../../contexts/campaign/campaignContext";
import { useCartDispatch } from "../../contexts/cart/cartContext";
import {pageRefreshed} from "../../utils/cleverTapLogger"
import {loginCTUser} from "../../utils/cleverTapUtil"

const Login = lazy(() => import('../../pages/Login'));
const Home = lazy(() => import('../../pages/Home'));
const ForgotPass = lazy(() => import('../../pages/ForgotPass'));
const Account = lazy(() => import('../../pages/account/Profile'));
const ChangePass = lazy(() => import('../../pages/account/ChangePass'));
const SignUp = lazy(() => import('../../pages/SignUp'));
const Search = lazy(() => import('../../pages/search/Search'));
const Search_Photo = lazy(() => import('../../pages/search/SearchPhotos'));
const SEARCH_PHOTO_NO_KEY = lazy(() => import('../../pages/search/SearchPhotos'));
const Photographer = lazy(() => import('../../pages/photographer/Photographer'));
const PhotographerPhoto = lazy(() => import('../../pages/photographer/Photos'));
const AlbumPhoto = lazy(() => import('../../pages/album/Photos'));
const Album = lazy(() => import('../../pages/album/Album'));
const Studios = lazy(() => import('../../pages/studio/Studios'));
const Studio = lazy(() => import('../../pages/studio/Studio'));
const StudioPhoto = lazy(() => import('../../pages/studio/Photos'));
const SetupBrand = lazy(() => import('../../pages/account/SetupBrand'));
const Campaigns = lazy(() => import('../../pages/campaigns/Campaigns'));
const Campaign = lazy(() => import('../../pages/campaigns/CampaignDetails'));
const Photo = lazy(() => import('../../pages/Photo'));
const Cart = lazy(() => import('../../pages/cart/Cart'));
const Checkout = lazy(() => import('../../pages/checkout/Checkout'));
const Notifications = lazy(() => import('../../pages/notification/Notifications'));
const Downloads = lazy(() => import('../../pages/downloads/Downloads'));
const MostLikedPhotos = lazy(() => import('../../pages/explore/MostLikedPhotos'));
const NewPhlogPhotos = lazy(() => import('../../pages/explore/NewPhlogPhotos'));
const NewUploadsPhotos = lazy(() => import('../../pages/explore/NewUploadsPhotos'));
const EditorPicks = lazy(() => import('../../pages/explore/EditorPicks'));
const CommonTagsPhotos = lazy(() => import('../../pages/explore/CommonTagsPhotos'));
const AboutUs = lazy(() => import('../../pages/AboutUs'));
const ContactUs = lazy(() => import('../../pages/ContactUs'));
const Policy = lazy(() => import('../../pages/Policy'));
const Feedback = lazy(() => import('../../pages/FeedBack'));
const Landing = lazy(() => import('../../pages/LandingNew'));
const Room = lazy(() => import('../../pages/Room'));
// const AddCampaign = lazy(() => import('../../pages/campaigns/AddCampaign'));


const Routes = () => {
 
  const {user, token, loading} = useUserState();
  const dispatch = useUserDispatch();
  const campaignDispatch = useCampaignDispatch();
  const alertDispatch = useAlertDispatch();
  const cartDispatch = useCartDispatch();
  const [axoisHeaderLoaded, setAxiosHeaderLoaded] = useState(false);
  useEffect(async() => {
    if (!user && token){
      await setAxiosTokenInterceptor(token, dispatch, campaignDispatch,cartDispatch);
      if (!token.includes("guest")) await loadUser(dispatch,alertDispatch);
      setAxiosHeaderLoaded(true);
    }
   
    pageRefreshed();
    // eslint-disable-next-line
  }, []);

  useEffect(async() => {
    const ctProfileCreated = localStorage.getItem('profileCreated'); 
    if (user && !ctProfileCreated)
    {
      await loginCTUser({name:user.full_name, id:user.id, email:user.email,  phone:user.phone})
    
    }
  },[user]);

  if ((token && token!== "guest-user") || !axoisHeaderLoaded)
  if ( !user)
   return  (<div className="loading" />)
  return (
    <>
    <Alerts />
    <Suspense fallback={<div className="loading" />}>
    <Switch>
      <PublicDefaultLayoutRoute path={ROUTES.LOGIN} component={Login}></PublicDefaultLayoutRoute>
      <PublicDefaultLayoutRoute path={ROUTES.FORGOT_PASSWORD} component={ForgotPass}></PublicDefaultLayoutRoute>
      <PublicDefaultLayoutRoute path={ROUTES.SIGN_UP} component={SignUp}></PublicDefaultLayoutRoute>
      <PrivateDefaultLayoutRoute path={ROUTES.POLICY} component={Policy}  allowGuest = {true}></PrivateDefaultLayoutRoute>
      <PrivateDefaultLayoutRoute exact path={ROUTES.FEEDBACK} component={Feedback}  allowGuest = {true}/>
      <PublicDefaultLayoutRoute exact path={ROUTES.DEFAULT} component={Landing} />
      <PrivateDefaultLayoutRoute exact path={ROUTES.HOME} component={Home} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.PROFILE} component={Account} allowGuest = {false}/> 
      <PrivateDefaultLayoutRoute exact path={ROUTES.CHANGE_PASSWORD} component={ChangePass} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.SEARCH} component={Search} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.SEARCHBASE} component={Search} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.SEARCH_PHOTO} component={Search_Photo} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.SEARCH_PHOTO_NO_KEY} component={Search_Photo}allowGuest = {true} />
      <PrivateDefaultLayoutRoute exact path={ROUTES.SEARCHWITHOUTKEYWORD} component={Search} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.PHOTOGRAPHER} component={Photographer} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.ALBUM} component={Album} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.PHOTOGRAPHER_PHOTO} component={PhotographerPhoto} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.ALBUM_PHOTO} component={AlbumPhoto} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.STUDIOS} component={Studios} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.STUDIO} component={Studio} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.STUDIO_PHOTO} component={StudioPhoto} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.BRAND} component={SetupBrand} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.CAMPAIGNS} component={Campaigns} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.CAMPAIGN} component={Campaign} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.CAMPAIGN_PHOTO} component={Photo} allowGuest = {false}/>
      {/* <PrivateDefaultLayoutRoute exact path={ROUTES.CAMPAIGN_PHOTO_WON} component={Photo} /> */}
      <PrivateDefaultLayoutRoute exact path={ROUTES.PHOTO} component={Photo}  allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.CART} component={Cart} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.CHECKOUT} component={Checkout} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.NOTIFICATIONS} component={Notifications} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.DOWNLOADS} component={Downloads} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.MOST_LIKED_PHOTO} component={MostLikedPhotos} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.NEW_PHLOG_PHOTO} component={NewPhlogPhotos} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.NEW_UPLOADs_PHOTO} component={NewUploadsPhotos} allowGuest = {false}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.EDITOR_PICKS} component={EditorPicks} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.COMMON_TAGS_PHOTO} component={CommonTagsPhotos} />
      <PrivateDefaultLayoutRoute exact path={ROUTES.ABOUTUS} component={AboutUs} allowGuest = {true}/>
      <PrivateDefaultLayoutRoute exact path={ROUTES.CONTACTUS} component={ContactUs} allowGuest = {true}/>
      <PublicDefaultLayoutRoute exact path={ROUTES.ROOM} component={Room} />
      <PublicDefaultLayoutRoute exact path={ROUTES.LANDING} component={Landing} />
      {/* <PrivateDefaultLayoutRoute exact path={ROUTES.ADD_CAMPAIGN} component={AddCampaign} />
      <PrivateDefaultLayoutRoute exact path={ROUTES.EDIT_CAMPAIGN} component={AddCampaign} /> */}
      <Route component={NotFound} />
    </Switch>
    </Suspense>
    </>
  );
};
export default Routes;
