import React from "react";
import { Route, Redirect } from "react-router-dom";
import UserLayout from "../../layout/UserLayout";
import AppLayout from "../../layout/AppLayout"

import { useUserState } from "../../contexts/user/userContext";
import * as ROUTES from "../../constants/routes";
import PublicLayout from "../../layout/PublicLayout";
import LandigLayout from "../../layout/LandingLayout";

const PublicDefaultLayoutRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useUserState();

  if (
    isAuthenticated &&
    rest.path !== ROUTES.POLICY &&
    rest.path !== ROUTES.FEEDBACK &&
    rest.path != ROUTES.LANDING &&
    rest.path !== ROUTES.ROOM 
    //&& rest.path != ROUTES.DEFAULT
  )
    return <Redirect to={ROUTES.HOME} />;

    if (rest.path === ROUTES.LANDING || 
      rest.path == ROUTES.DEFAULT)
    {
      return (
        <Route
        {...rest}
        render={(props) => (
          <LandigLayout>
            <Component {...props} />
      </LandigLayout>
        )}
        />
      )
    }
  if (rest.path === ROUTES.POLICY || rest.path === ROUTES.FEEDBACK)
  if (isAuthenticated)
  {
    return (
      <Route
      {...rest}
      render={(props) => (
        <AppLayout>
          <Component {...props} />
    </AppLayout>
      )}
      />
    )
  }
  else
  {
    return (
      <Route
        {...rest}
        render={(props) => (
          <PublicLayout>
            <Component {...props} />
          </PublicLayout>
        )}
      />
    );
        }
  else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <UserLayout room = {rest.path === ROUTES.ROOM? true : false}>
            <Component {...props} />
          </UserLayout>
        )}
      />
    );
  }
};

export default PublicDefaultLayoutRoute;
