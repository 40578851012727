import {
GET_NOTIFICATIONS,
GET_MORE_NOTIFICATIONS,
SET_NOTIFICATION_READ,
NOTIFICATION_ERROR
 } from "../../constants/notification";
 import {  SET_LOADING, RESET_ALL_LOADED} from '../../constants/shared';

 
export const initialState = {
  loading: false,
 notifications:[],
 pagination: {},
 allLoaded: false
};

export const notificationReducer = (state, action) => {
  const { type, payload } = action;
  let copyOfObject = {};
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS:
      copyOfObject = { ...payload.data };
      delete copyOfObject['data'];
      return {
        ...state,
        notifications:payload.data.data,
        pagination:copyOfObject,
        allLoaded:payload.data.current_page === payload.data.last_page,
        loading:false,
      };
      case GET_MORE_NOTIFICATIONS:
        copyOfObject = { ...payload.data };
      delete copyOfObject['data'];
      return {
        ...state,
        notifications:state.notifications ?
        [...state.notifications, ...payload.data.data] :
        payload.data.data,
        pagination:copyOfObject,
        allLoaded:payload.data.current_page === payload.data.last_page,
        loading:false,
      };
  case SET_NOTIFICATION_READ:
    state.notifications.find(item => item.id == payload.data.id).is_read = true
      return {
        ...state,
        unreadCount:payload.count> 0 ? payload.count - 1 : 0,
        loading: false,
      };
    case NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
      };
      case RESET_ALL_LOADED:
        return {
          ...state,
          notifications:[],
        pagination:{},
        allLoaded:false
      }
    default:
      return state;
  }
};
