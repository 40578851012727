import React from "react";
import { MobileMenuIcon, MenuIcon } from "../svg";
import { NavLink,Link } from "react-router-dom";
import PropTypes from 'prop-types';
import * as ROUTES from "../../constants/routes";

const TopbarSidebarButton = ({ menuButtonClick, mobileMenuButtonClick }) => (
  <div className="d-flex align-items-center navbar-left">
    <NavLink
      to="#"
      className="menu-button d-none d-md-block"
      onClick={menuButtonClick}
    >
      <MenuIcon />
    </NavLink>
    <NavLink
      to="#"
      className="menu-button-mobile d-xs-block d-sm-block d-md-none"
      onClick={(e) => mobileMenuButtonClick(e)}
    >
      <MobileMenuIcon />
    </NavLink>

    <Link className="navbar-logo" to={ROUTES.HOME}>
        <span className="logo d-none d-xs-block" />
        <span className="logo-mobile d-block d-xs-none" />
      </Link>
  </div>
);

TopbarSidebarButton.propTypes = {
  menuButtonClick: PropTypes.func.isRequired,
  mobileMenuButtonClick: PropTypes.func.isRequired,
};

export default TopbarSidebarButton;
