import React, { createContext, useContext, useReducer } from "react";
import { initialState, cartReducer } from "./cartReducer";

// Prepares the dataLayer
const cartStateContext = createContext();
const cartDispatchContext = createContext();

export function useCartState() {
	const context = useContext(cartStateContext);
	if (context === undefined) {
		throw new Error('usecartState must be used within a cartProvider');
	}

	return context;
}

export function useCartDispatch() {
	const context = useContext(cartDispatchContext);
	if (context === undefined) {
		throw new Error('usecartDispatch must be used within a cartProvider');
	}

	return context;
}

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, initialState);

  return (
    <cartStateContext.Provider value={cart}>
      <cartDispatchContext.Provider value={dispatch}>
        {children}
      </cartDispatchContext.Provider>
    </cartStateContext.Provider>
  );
};
