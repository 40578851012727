import * as React from "react"

const CartIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M26.1 57.8c-10 5-10.6 18.6-1.2 24.9 3.2 2.2 4.4 2.3 19.2 2.3 18.1 0 22.6 1.1 25.8 6.4 1.5 2.3 6.7 27.9 19.1 92.6 9.3 49.2 17.9 92.3 19.1 95.7 2.1 6.6 7.4 15.4 12.8 21.6 5.1 5.7 17.3 13.3 26.4 16.4l8.2 2.8h98c90.5 0 98.5-.1 104.4-1.8 18.8-5.2 35.6-20.1 42.1-37.3 1.1-3.1 9.3-43.4 18.1-89.5 13.8-72.7 15.8-84.4 14.9-87.7-.9-3.6-3.4-6.6-7-8.8-.9-.5-70.2-1.1-162.9-1.4l-161.4-.5-1.4-5.4c-3.9-15.3-16.8-27.7-32.1-31-2.9-.6-12.8-1.1-22-1.1-13.9 0-17.3.3-20.1 1.8zM401 123.7c0 .5-6.1 33-13.6 72.3-9.2 48.4-14.3 73.1-15.8 76.5-2.8 6.1-9.1 12.6-15.4 15.7l-4.7 2.3h-195l-4.3-2.3c-6.5-3.5-11.9-8.8-14.7-14.7-2.1-4.2-5.5-20.4-16-75.6-7.4-38.7-13.7-71.4-14-72.7l-.5-2.2h147c80.8 0 147 .3 147 .7zM177.3 340.7c-5.1 1.8-12.1 8.3-14.8 13.8-1.2 2.5-2.1 6.8-2.3 11.6-.5 9.9 2 16.1 9 22.4 17.8 16 45.6 4.7 47.6-19.1.9-11.9-5.5-23-16.3-28-5.9-2.8-16.5-3.1-23.2-.7zM307.2 341.6c-18 8.9-21.1 33.3-6 46.9 15.5 14 40.4 7.1 46.3-12.7 3-10.2 1.1-19.6-5.7-27.3-5.9-6.7-12.3-9.5-21.9-9.5-6.1 0-8.5.5-12.7 2.6z" />
  </svg>
)

export default CartIcon
