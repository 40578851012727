
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export default function TopbarBasic() {
  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center navbar-left">
        <Link className="navbar-logo" to={ROUTES.HOME}>
          <span className="logo d-none d-xs-block" />
          <span className="logo-mobile d-block d-xs-none" />
        </Link>
      </div>
      <div className="navbar-right"></div>
    </nav>
  );
}
