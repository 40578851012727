import React, { useEffect } from 'react';

const CheckOutLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('background');
    document.body.classList.add('white-background');
    //document.html.classList.add('white-background');
    document.body.classList.add('no-footer');

    return () => {
      document.body.classList.remove('background');
      document.body.classList.remove('white-background');
      // document.html.classList.remove('white-background');
      document.body.classList.remove('no-footer');
    };
  }, []);

  return (
    <>
      <main>
        <div className="container margin-auto">{children}</div>
      </main>
    </>
  );
};

export default CheckOutLayout;
