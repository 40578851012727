import * as React from "react"

const PriceTagIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M251.3 38.5c-3.5.8-9.3 2.8-13 4.6-6.4 3-12.9 9.3-99.4 95.8-87.5 87.6-92.7 93-95.8 99.5-8.2 17.4-7.7 36.6 1.6 53.6 3 5.5 11.9 14.9 56.7 59.6 50.2 50.1 53.5 53.2 60.6 56.7 18.5 9.2 39.1 8.5 56-1.8 3.8-2.3 34.3-32.3 96.6-94.7 84.7-84.9 91.3-91.8 94.3-98 5.9-12.4 6.1-14.5 6.1-76.8 0-46.4-.3-57.6-1.5-62.8-4.2-17.8-17.9-31.5-35.6-35.7-8.9-2.1-117-2.1-126.6 0zm120.4 28.7c6.6 1.6 11.8 7.1 13.3 14 .8 3.8 1.1 20.7.8 59.3-.3 53.8-.3 54-2.6 59-1.9 4.1-18 20.8-90.5 93.2-96.6 96.6-92 92.5-104.1 92.6-11.7.1-11 .7-66.4-54.6-55.6-55.5-55.6-55.5-55.6-67.1.1-12.2-4.1-7.6 92.7-104.3 48.5-48.4 89.7-88.9 91.5-89.8 1.9-.9 5.2-2 7.5-2.5 6.8-1.3 108-1.2 113.4.2z" />
    <path d="M310.9 104.5c-2.6.8-6.9 3.5-9.7 6-19.5 17.6-7.6 49.6 18.5 49.7 25.2.2 38.3-28.5 21.9-47.9-6.2-7.4-20.7-11-30.7-7.8z" />
  </svg>
)

export default PriceTagIcon
