import React, { useEffect, useRef, useState } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import BellOutlineIcon from "mdi-react/BellOutlineIcon";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  useNotificationDispatch,
  useNotificationState,
} from "../../contexts/notification/notificationContext";
import {
  getNotifications,
  setNotificationAsRead,
} from "../../contexts/notification/notificationActions";
import { NavLink, useLocation } from "react-router-dom";
import NotificationIcon from "../svg/NotificationIcon";
import { photographerClicked } from "../../utils/cleverTapLogger";

const NotificationItem = ({
  notification,
  readNotification,
  setIsNotifOpen,
}) => {
  const handleReadNotification = () => {
    if (!notification.is_read) readNotification(notification.id);
    setIsNotifOpen(false);
  };

  const photographerClickedEvent= (photographer) =>{
    photographerClicked({sourceOfEvent: "Notification",isActive: photographer.is_active,countryId:photographer.country.id,
    countryName: photographer.country.name_en,
    clickedPhotographerName: photographer.full_name,clickedPhotographerId: photographer.id})
    }
  return (
    <>
      {notification.photographer ? (
        <NavLink
          to={`/photographer/${notification.photographer.id}`}
          onClick={ () => {
            photographerClickedEvent(notification.photographer);  
            handleReadNotification()}}
        >
          <div
            className={`d-flex flex-row justify-content-between mb-2 pb-2 pt-2 pl-2 border-bottom ${
              !notification.is_read ? "unread" : ""
            }`}
          >
            <img
              src={notification.photographer.image_profile}
              alt="photographer"
              className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
            />
            <div className="flex-grow-1 pl-3 pr-2">
              <p className="text-default font-weight-medium mb-2">
                {notification.message}
              </p>
              <p className="text-muted mb-0 text-small">{notification.date}</p>
            </div>
          </div>
        </NavLink>
      ) : notification.campaign ? (
        <NavLink
          to={`/campaign/${notification.campaign.id}`}
          onClick={handleReadNotification}
        >
          <div
            className={`d-flex flex-row justify-content-between  mb-2 pb-2 pt-2 pl-2 border-bottom ${
              !notification.is_read ? "unread" : ""
            }`}
          >
            <img
              src={notification.campaign.image_cover}
              alt="campaign"
              className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
            />
            <div className="flex-grow-1 pl-3 pr-2">
              <p className="text-default font-weight-medium mb-2">
                {notification.message}
              </p>
              <p className="text-muted mb-0 text-small">{notification.date}</p>
            </div>
          </div>
        </NavLink>
      ) : notification.brand ? (
        <NavLink
          to={notification.brand.id !== -1 ? `/setup-brand` : `#`}
          onClick={handleReadNotification}
        >
          <div
            className={`d-flex flex-row justify-content-between  mb-2 pb-2 pt-2 pl-2 border-bottom ${
              !notification.is_read ? "unread" : ""
            }`}
          >
            <img
              src={notification.brand.thumbnail}
              alt="brand"
              className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
            />
            <div className="flex-grow-1 pl-3 pr-2">
              <p className="text-default font-weight-medium mb-2">
                {notification.message}
              </p>
              <p className="text-muted mb-0 text-small">{notification.date}</p>
            </div>
          </div>
        </NavLink>
      ) : notification.photo ? (
        <NavLink
          to={`/photo/${notification.photo.id}`}
          onClick={handleReadNotification}
        >
          <div
            className={`d-flex flex-row justify-content-between  mb-2 pb-2 pt-2 pl-2 border-bottom ${
              !notification.is_read ? "unread" : ""
            }`}
          >
            <img
              src={notification.photo.photographer.image_profile}
              alt="brand"
              className="img-thumbnail list-thumbnail xsmall border-0 rounded-circle"
            />
            <div className="flex-grow-1 pl-3 pr-2">
              <p className="text-default font-weight-medium mb-2">
                {notification.message}
              </p>
              <p className="text-muted mb-0 text-small">{notification.date}</p>
            </div>
          </div>
        </NavLink>
      ) : (
        <>
          <div
            className={`d-flex flex-row justify-content-between  mb-2 pb-2 pt-2 pl-2 border-bottom ${
              !notification.is_read ? "unread" : ""
            }`}
            onClick={handleReadNotification}
          >
            <div className="align-self-center list-thumbnail-letters list-thumbnail rounded-circle xsmall">
              {" "}
            </div>
            <div className="flex-grow-1 pl-3 pr-2">
              <p className="text-default font-weight-medium mb-2">
                {notification.message}
              </p>
              <p className="text-muted mb-0 text-small">{notification.date}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const TopbarNotifications = ({ total }) => {
  const { notifications, unreadCount, pagination, allLoaded, loading } =
    useNotificationState();
  const dispatch = useNotificationDispatch();
  const [isNotifOpen, setIsNotifOpen] = useState(false);
  const scrollNotificationRef = useRef(null);

  const location = useLocation();

  const focusScrollBottom = () => {
    setTimeout(() => {
      if (scrollNotificationRef.current) {
        scrollNotificationRef.current._ps.element.scrollTop = 1;
      }
    }, 100);
  };

  useEffect(() => {
    focusScrollBottom();
  }, [notifications]);

  useEffect(() => {
    getNotifications(dispatch, 1, false);
  }, [dispatch, location]);

  const readNotification = async (notificationId) => {
    await setNotificationAsRead(dispatch, { id: notificationId }, total);
    //setIsNotifOpen(false)
  };
  return (
    <div className="position-relative d-inline-block">
      <UncontrolledDropdown
        className="dropdown-menu-right"
        onToggle={(expand) => {
          focusScrollBottom();
          setIsNotifOpen(!isNotifOpen);
        }}
        isOpen={isNotifOpen}
      >
        <DropdownToggle
          className="header-icon notificationButton"
          color="empty"
        >
          <BellOutlineIcon size="28" className="vm" />
          {/* <NotificationIcon width="28" height="28" className="vm" /> */}
          {((unreadCount !== undefined && unreadCount > 0) ||
            (unreadCount === undefined && total > 0)) && (
            <span className="count">
              {unreadCount !== undefined ? unreadCount : total}
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu
          className={`position-absolute mt-3 p-0 scroll notification-drop ${
            notifications && notifications.length < 3 ? "noNotification" : ""
          }`}
          right
          id="notificationDropdown"
        >
          {notifications && notifications.length === 0 ? (
            <p className="text-muted "> No notifications.</p>
          ) : (
            <>
              <PerfectScrollbar
                ref={scrollNotificationRef}
                options={{ suppressScrollX: true, wheelPropagation: true }}
              >
                {notifications &&
                  notifications.map((notification, index) => {
                    return (
                      <NotificationItem
                        key={index}
                        notification={notification}
                        readNotification={readNotification}
                        setIsNotifOpen={setIsNotifOpen}
                      />
                    );
                  })}
              </PerfectScrollbar>
              <div className="justify_center pt-2 pb-2">
                <NavLink
                  to={`/notifications`}
                  onClick={() => setIsNotifOpen(false)}
                >
                  <p className="color-theme-1 m-0">View All Notifications</p>
                </NavLink>
              </div>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default TopbarNotifications;
