// Auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT = "LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CHANGE_AVATAR = "CHANGE_AVATAR";
export const LOADING_AVATAR = "LOADING_AVATAR";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const RESEND_VERIFICATION_SUCCESS = "RESEND_VERIFICATION_SUCCESS";
export const RESEND_VERIFICATION_FAIL = "RESEND_VERIFICATION_FAIL";
export const EDIT_PROFILE_FAIL = "EDIT_PROFILE_FAIL";

export const GET_DEVICEID_SUCCESS = "GET_DEVICEID_SUCCESS";
export const SET_DEVICEID_SUCCESS = "SET_DEVICEID_SUCCESS";
export const DOCUMENTS_LOADED = "DOCUMENTS_LOADED";
export const BRAND_FAIL = "BRAND_FAIL";
export const SETUP_BRAND = "SETUP_BRAND";
export const EDIT_BRAND = "EDIT_BRAND";
export const MAIL_SENT = "MAIL_SENT";
export const MAIL_FAILED = "MAIL_FAILED";
export const BRAND_REQUEST_VERFICATION = "BRAND_REQUEST_VERFICATION";
