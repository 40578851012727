export const GET_PHOTOS = 'GET_PHOTOS';
export const GET_PHOTO = 'GET_PHOTO';
export const PHOTO_ERROR = 'PHOTO_ERROR';
export const GET_MORE_PHOTOS = 'GET_MORE_PHOTOS';
export const FILTERS_LOADED = 'FILTERS_LOADED';
export const SET_FILTER_LOADING = 'SET_FILTER_LOADING';
export const SET_PHOTO_RATING= 'SET_PHOTO_RATING';
export const LIKE_PHOTO = 'LIKE_PHOTO';
export const DISLIKE_PHOTO = 'DISLIKE_PHOTO';
export const REPORT_RESASONS_LOADED = 'REPORT_RESASONS_LOADED';
export const COMMENT_REPORT_RESASONS_LOADED = 'COMMENT_REPORT_RESASONS_LOADED';
export const REPORT_PHOTO = 'REPORT_PHOTO';
export const GET_COMMENTS = 'GET_COMMENTS';
export const GET_MORE_COMMENTS = 'GET_MORE_COMMENTS';
export const GET_COMMENT_CHILDERN = 'GET_COMMENT_CHILDERN';
export const GET_MORE_COMMENT_CHILDERN = 'GET_MORE_COMMENT_CHILDERN';
export const ADD_COMMENT = 'ADD_COMMENT'
export const EDIT_COMMENT = 'EDIT_COMMENT'
export const EDIT_COMMENT_CHILD = 'EDIT_COMMENT_CHILD'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const DELETE_COMMENT_CHILD = 'DELETE_COMMENT_CHILD'
export const ADD_COMMENT_CHILD = 'ADD_COMMENT_CHILD'
export const ADDED_TO_CART = 'ADDED_TO_CART';
export const RESET_COMMENTS = 'RESET_COMMENTS';
export const RESET_PARENT = 'RESET_PARENT';



