import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  CHANGE_AVATAR,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAIL,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  SET_DEVICEID_SUCCESS,
  DOCUMENTS_LOADED,
  BRAND_FAIL,
  SETUP_BRAND,
  EDIT_BRAND,
  BRAND_REQUEST_VERFICATION,
  MAIL_FAILED,
  MAIL_SENT,
  RESEND_VERIFICATION_FAIL,
  RESEND_VERIFICATION_SUCCESS,
} from "../../constants/userConstant";
import {  SET_LOADING} from '../../constants/shared';
import {encryptData, decryptData} from '../../utils/cryptoUtil';

// let user = localStorage.getItem("user")
//   ? JSON.parse(localStorage.getItem("user"))
//   : "";
let token = localStorage.getItem("token")
  ? decryptData(localStorage.getItem("token"),process.env.REACT_APP_SALT, process.env.REACT_APP_IV)
  : "guest-user";
 
export const initialState = {
  loading: false,
  token: token,
  user: null,//user,
  //deviceId: "",
  errorMessage: [],
  forgotPassErr: "",
  isAuthenticated: false,//user && token,
};

export const userReducer = (state, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
      case LOGIN_SUCCESS:
          localStorage.setItem("token", encryptData(payload.token, process.env.REACT_APP_SALT, process.env.REACT_APP_IV));
      localStorage.removeItem("cartList");
          return {
            ...state,
            loading: false,
            errorMessage: [],
            token: payload.token,
          };
    case REGISTER_SUCCESS:
    localStorage.removeItem("cartList");
      return {
        ...state,
        loading: false,
        errorMessage: [],
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotPassErr:""
        //forgotUserMail: action.payload,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        //forgotUserMail: "",
        forgotPassErr: error,
      };
      case SET_DEVICEID_SUCCESS:
        return {
          ...state,
          loading: false,
          //deviceId: action.payload,
        };
    case USER_LOADED:
      //localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        loading: false,
        user: payload,
        isAuthenticated: true,
      };
    case DOCUMENTS_LOADED:
      return{
        ...state,
        loading:false,
        documents: payload
      }
    case CHANGE_AVATAR:
      return {
        ...state,
        loadingAvatar: false,
        user: payload ? payload : state.user,
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        //user: payload,
      };
      case SETUP_BRAND:
        return{
          ...state,
          loading:false,
        }
        case EDIT_BRAND:
          return{
            ...state,
            loading:false,
          }
        case BRAND_REQUEST_VERFICATION:
          return{
            ...state,
            loading:false,
          }
      case EDIT_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: error,
      };
      case BRAND_FAIL:
        return{
          ...state,
          loading:false,
        }
        case MAIL_FAILED:
          case MAIL_SENT:
          return{
            ...state,
            loading:false,
          }
      case RESEND_VERIFICATION_SUCCESS:
        return {
          ...state,
          errorMessage: null,
        };

    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
    case REGISTER_FAIL:
      case RESEND_VERIFICATION_FAIL:
      //localStorage.removeItem("user");
      localStorage.removeItem("token");
      return {
        ...state,
        loading: false,
        token:  "guest-user",
        user: null,
        errorMessage: error,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
