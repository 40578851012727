import * as React from "react"

const ProfileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M210.7 39c-36.7 6.6-66 33.6-75.9 69.7-3.2 11.6-3.1 34.5.1 46.3 12.3 45.5 55.7 75.5 101.3 70.2 49.5-5.8 86-48.6 83.5-97.7-2.1-41.3-29.2-74.9-69.8-86.5-8.3-2.4-30.8-3.6-39.2-2zm31.7 29c11.9 3 20.9 8.2 30.1 17.5 8.6 8.6 12.8 15.4 16.7 27 1.9 5.9 2.3 9 2.2 19.5 0 11-.3 13.5-2.8 20.5-3.8 10.9-8.7 18.8-16.7 26.6-7.3 7.1-18.1 13.4-28.4 16.5-8.3 2.5-26.7 2.5-35 0-27.9-8.4-46.9-32.2-48.2-60.6-.6-12.2 1.3-21.5 6.8-32.8 7.8-16.2 24.2-29.5 42.1-34.2 9.9-2.6 23.2-2.6 33.2 0zM102.3 265.4c-9.9 3.1-18.9 10.9-23.2 20.1-4.4 9.3-5.1 31.5-1.5 47.2 5.8 25.9 25.5 47.9 55.8 62.4 28.6 13.7 60.5 19.6 100.6 18.6 35.5-.8 60.5-6.5 87.5-20 28.3-14.1 47.3-36 52.9-61 3.6-15.7 2.9-37.9-1.5-47.2-3.4-7.2-10.2-14-17.7-17.7l-6.7-3.3-121-.2c-98.1-.1-121.8.1-125.2 1.1zm242.4 28.1c3.6 2.5 4.6 7.7 4 19.9-1.3 23.2-8.9 36.2-29 49.3-15 9.8-34.9 16.6-61.2 21-16.5 2.7-53.7 2.4-69.5-.5-45.5-8.4-75.3-27.1-83-52.1-2.6-8.4-3.8-27.1-2.1-32.4 2.4-7.2-7.2-6.7 122.1-6.7 102.1 0 116.8.2 118.7 1.5z" />
  </svg>
)

export default ProfileIcon
