import * as React from "react"

const LogInIcon = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="1em"
  height="1em"
  viewBox="0 0 24 24"
  {...props}
>
  <g fill="currentColor">
    <path d="M15.486 20h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v2h4v12h-4v2Z" />
    <path d="m10.158 17.385-1.42-1.408 3.92-3.953H3.513a1 1 0 1 1 0-2h9.163l-3.98-3.947 1.408-1.42 6.391 6.337-6.337 6.391Z" />
  </g>
</svg>
)

export default LogInIcon
