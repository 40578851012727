import React, { createContext, useContext, useReducer } from "react";
import { initialState, exploreReducer } from "./exploreReducer";

// Prepares the dataLayer
const exploreStateContext = createContext();
const exploreDispatchContext = createContext();

export function useExploreState() {
	const context = useContext(exploreStateContext);
	if (context === undefined) {
		throw new Error('useexploreState must be used within a exploreProvider');
	}

	return context;
}

export function useExploreDispatch() {
	const context = useContext(exploreDispatchContext);
	if (context === undefined) {
		throw new Error('useexploreDispatch must be used within a exploreProvider');
	}

	return context;
}

export const ExploreProvider = ({ children }) => {
  const [explore, dispatch] = useReducer(exploreReducer, initialState);

  return (
    <exploreStateContext.Provider value={explore}>
      <exploreDispatchContext.Provider value={dispatch}>
        {children}
      </exploreDispatchContext.Provider>
    </exploreStateContext.Provider>
  );
};
