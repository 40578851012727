import { React, useState, useEffect, useRef, useCallback } from "react";
import { Nav, NavItem, Collapse } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  useSidebarState,
  useSidebarDispatch,
} from "../../contexts/sidebar/sidebarContext";
import {
  setContainerClassnames,
  addContainerClassname,
  changeSelectedMenuHasSubItems,
} from "../../contexts/sidebar/sidebarActions";

import menuItems from "../../constants/sidebar";
import IconComponent from "../svg/IconComponent";
import { useUserState } from "../../contexts/user/userContext";
import {campaignButtonClicked, exploreButtonClicked,downloadClicked, profileButtonClicked,myStudiosClicked} from "../../utils/cleverTapLogger";

const Sidebar = () => {
  const eventsFunctionMap = {
    campaignButtonClicked,
    exploreButtonClicked,
    downloadClicked,
    profileButtonClicked,
    myStudiosClicked
  };
  const {isAuthenticated} = useUserState();
  const [viewingParentMenu, setViewingParentMenu] = useState("");
  const [selectedParentMenu, setSelectedParentMenu] = useState("");
  const [collapsedMenus, setCollapsedMenus] = useState([]);
  const dispatch = useSidebarDispatch();
  const {
    menuClickCount,
    containerClassnames,
    selectedMenuHasSubItems,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
  } = useSidebarState();

  const myRef = useRef();
  const location = useLocation();

  const callFunctionByName = (functionName) => {
    const selectedFunction = eventsFunctionMap[functionName];
    if (selectedFunction && typeof selectedFunction === 'function') {
      selectedFunction();
    }
  };

  const getMenuClassesForResize = useCallback((classes) => {
    let nextClasses = classes.split(" ").filter((x) => x !== "");
    const windowWidth = window.innerWidth;
    if (windowWidth < menuHiddenBreakpoint) {
      nextClasses.push("menu-mobile");
    } else if (windowWidth < subHiddenBreakpoint) {
      nextClasses = nextClasses.filter((x) => x !== "menu-mobile");
      if (
        nextClasses.includes("menu-default") &&
        !nextClasses.includes("menu-sub-hidden")
      ) {
        nextClasses.push("menu-sub-hidden");
      }
    } else {
      nextClasses = nextClasses.filter((x) => x !== "menu-mobile");
      if (
        nextClasses.includes("menu-default") &&
        nextClasses.includes("menu-sub-hidden")
      ) {
        nextClasses = nextClasses.filter((x) => x !== "menu-sub-hidden");
      }
    }
    return nextClasses;
  },[menuHiddenBreakpoint, subHiddenBreakpoint]);

 
  const getIsHasSubItem =  useCallback(() => {
    const menuItem = menuItems.find((x) => x.id === selectedParentMenu);
    if (menuItem)
      return menuItem && menuItem.subs && menuItem.subs.length > 0
        ? true
        : false;
    else return false;
  },[selectedParentMenu]);

  const toggle = () => {//useCallback(() => {
    const hasSubItems = getIsHasSubItem();
     changeSelectedMenuHasSubItems(dispatch, hasSubItems);
    const currentClasses = containerClassnames
      ? containerClassnames.split(" ").filter((x) => x !== "")
      : "";
    let clickIndex = -1;

    if (!hasSubItems) {
      if (
        currentClasses.includes("menu-default") &&
        (menuClickCount % 4 === 0 || menuClickCount % 4 === 3)
      ) {
        clickIndex = 1;
      } else if (
        currentClasses.includes("menu-sub-hidden") &&
        (menuClickCount === 2 || menuClickCount === 3)
      ) {
        clickIndex = 0;
      } else if (
        currentClasses.includes("menu-hidden") ||
        currentClasses.includes("menu-mobile")
      ) {
        clickIndex = 0;
      }
    } else {
      if (currentClasses.includes("menu-sub-hidden") && menuClickCount === 3) {
        clickIndex = 2;
      } else if (
        currentClasses.includes("menu-hidden") ||
        currentClasses.includes("menu-mobile")
      ) {
        clickIndex = 0;
      }
    }
    if (clickIndex >= 0) {
       setContainerClassnames(
        dispatch,
        clickIndex,
        containerClassnames,
        hasSubItems
      );
    }
  }
  //,[getIsHasSubItem, dispatch,containerClassnames, menuClickCount ]);


  const handleDocumentClick =  (e) => {//useCallback((e) => {
    //const container = getContainer();
    let isMenuClick = false;
    if (
      e.target &&
      e.target.classList &&
      (e.target.classList.contains("menu-button") ||
        e.target.classList.contains("menu-button-mobile"))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.classList &&
      (e.target.parentElement.classList.contains("menu-button") ||
        e.target.parentElement.classList.contains("menu-button-mobile"))
    ) {
      isMenuClick = true;
    } else if (
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.classList &&
      (e.target.parentElement.parentElement.classList.contains("menu-button") ||
        e.target.parentElement.parentElement.classList.contains(
          "menu-button-mobile"
        ))
    ) {
      isMenuClick = true;
    }
    if (myRef.current && 
      myRef.current.contains(e.target) ||
      myRef.current === e.target ||
      isMenuClick
    ) {
      return;
    }
    setViewingParentMenu("");
   // toggle();
  }
  //,[setViewingParentMenu, toggle]);

    
  const addEvents =  () => {//useCallback(() => {
    ["click", "touchstart", "touchend"].forEach((event) =>
      document.addEventListener(event, handleDocumentClick, true)
    );
  }
  //,[handleDocumentClick]);

  const handleProps =() => { // useCallback(() => {
    addEvents();
  }
  //,[addEvents]);

  const removeEvents =  () => { //useCallback(() => {
    ["click", "touchstart", "touchend"].forEach((event) =>
      document.removeEventListener(event, handleDocumentClick, true)
    );
  }//,[handleDocumentClick]);

  const setSelectedLiActive =() => {  //useCallback(() => {
    const oldli = document.querySelector(".sub-menu  li.active");
    if (oldli != null) {
      oldli.classList.remove("active");
    }

    const oldliSub = document.querySelector(".third-level-menu  li.active");
    if (oldliSub != null) {
      oldliSub.classList.remove("active");
    }

    /* set selected parent menu */
    const selectedSublink = document.querySelector(
      ".third-level-menu  a.active"
    );
    if (selectedSublink != null) {
      selectedSublink.parentElement.classList.add("active");
    }

    const selectedlink = document.querySelector(".sub-menu  a.active");
    if (selectedlink != null) {
      selectedlink.parentElement.classList.add("active");
      //   this.setState(
      //     {
      //       selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
      //         'data-parent'
      //       )
      //     },
      //     callback
      //   );
      setSelectedParentMenu(
        selectedlink.parentElement.parentElement.getAttribute("data-parent")
      );
    } else {
      var selectedParentNoSubItem = document.querySelector(
        ".main-menu  li a.active"
      );
      if (selectedParentNoSubItem != null) {
        // this.setState(
        //   {
        //     selectedParentMenu: selectedParentNoSubItem.getAttribute(
        //       'data-flag'
        //     )
        //   },
        //   callback
        // );
        setSelectedParentMenu(
          selectedParentNoSubItem.getAttribute("data-flag")
        );
        setViewingParentMenu(selectedParentNoSubItem.getAttribute("data-flag"));
      } else if (selectedParentMenu === "") {
        // this.setState(
        //   {
        //     selectedParentMenu: menuItems[0].id
        //   },
        //   callback
        //);
        setSelectedParentMenu("");
      }
      else{
        setSelectedParentMenu("");
      }
    }
  }//,[setSelectedParentMenu, selectedParentMenu]);

  const setHasSubItemStatus = () => { // useCallback(() => {
    const hasSubmenu = getIsHasSubItem();
     changeSelectedMenuHasSubItems(dispatch, hasSubmenu);
    toggle();
  }
  //,[getIsHasSubItem,toggle, dispatch]);

 
// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const handleWindowResize = (event) => {
      if (event && !event.isTrusted) {
        return;
      }
      let nextClasses = getMenuClassesForResize(containerClassnames);
       setContainerClassnames(
        dispatch,
        0,
        nextClasses.join(" "),
        selectedMenuHasSubItems
      );
    };
  
    window.addEventListener("resize", handleWindowResize);
     handleWindowResize();
    handleProps();
    setSelectedLiActive();
    //setHasSubItemStatus();
    return () => {
      removeEvents();
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedLiActive();
    //setHasSubItemStatus();
    window.scrollTo(0, 0);
    handleProps();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleProps();
  }, [
    viewingParentMenu,
    //selectedParentMenu,
    collapsedMenus,
    menuClickCount,
    containerClassnames,
    selectedMenuHasSubItems,
  ]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (selectedParentMenu != "")
    setHasSubItemStatus();
  }, [selectedParentMenu]);

  const openSubMenu = (e, menuItem) => {
    const selectedParent = menuItem.id;
    const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
    changeSelectedMenuHasSubItems(dispatch, hasSubMenu);
    if (!hasSubMenu) {
      setViewingParentMenu(selectedParent);
      setSelectedParentMenu(selectedParent);
      toggle();
    } else {
      e.preventDefault();

      const currentClasses = containerClassnames
        ? containerClassnames.split(" ").filter((x) => x !== "")
        : "";

      if (!currentClasses.includes("menu-mobile")) {
        if (
          currentClasses.includes("menu-sub-hidden") &&
          (menuClickCount === 2 || menuClickCount === 0)
        ) {
           setContainerClassnames(dispatch, 3, containerClassnames, hasSubMenu);
        } else if (
          currentClasses.includes("menu-hidden") &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
           setContainerClassnames(dispatch, 2, containerClassnames, hasSubMenu);
        } else if (
          currentClasses.includes("menu-default") &&
          !currentClasses.includes("menu-sub-hidden") &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
           setContainerClassnames(dispatch, 0, containerClassnames, hasSubMenu);
        }
      } else {
         addContainerClassname(
          dispatch,
          "sub-show-temporary",
          containerClassnames
        );
      }
      setViewingParentMenu(selectedParent);
    }
  };

  const toggleMenuCollapse = (e, menuKey) => {
    e.preventDefault();

    if (collapsedMenus.indexOf(menuKey) > -1) {
      setCollapsedMenus(collapsedMenus.filter((x) => x !== menuKey));
    } else {
      setCollapsedMenus(collapsedMenus.push(menuKey));
    }
    return false;
  };

  return (
    <div className="sidebar" ref={myRef}>
      <div className="main-menu">
        <div className="scroll">
          <PerfectScrollbar
            options={{ suppressScrollX: true, wheelPropagation: false }}
          >
            <Nav vertical className="list-unstyled">
              {menuItems &&
                menuItems.map((item) => {
                  return (
                    <>
                    {((!item.userOnly && !isAuthenticated)||(isAuthenticated)) && (
                    <NavItem
                      key={item.id}
                      className={classnames({
                        active:
                          (selectedParentMenu === item.id &&
                            viewingParentMenu === "") ||
                          viewingParentMenu === item.id,
                      })}
                    >
                      {item.newWindow ? (
                        <a
                          href={item.to}
                          rel="noopener noreferrer"
                          target="_blank"
                          onClick={()=>{if (item.onEventClick) callFunctionByName(item.onEventClick) }}
                        >
                          
                          {item.simple ? (  <i className={item.icon} /> ) : (<IconComponent name ={item.icon} width="40" height= "42"/>)} 
                          {/* <i className={item.icon} /> */}
                         
                          {item.label}
                        </a>
                      ) : (
                        <NavLink
                          to={item.to}
                          onClick={(e) =>{ 
                            if(item.onEventClick) callFunctionByName(item.onEventClick) ; 
                            openSubMenu(e, item);  
                          }}
                          data-flag={item.id}
                        >
                          {item.simple ? (<i className={item.icon} /> ) : (  <IconComponent name ={item.icon}  width="40" height= "42"/>)}
                          {/* <span className={`lnr lnr-${item.icon}`} /> */}
                          {item.label}
                        </NavLink>
                      )}
                    </NavItem>
                    )}
                    </>
                  );
                })}
            </Nav>
          </PerfectScrollbar>
        </div>
      </div>
      <div className="sub-menu">
        <div className="scroll">
          <PerfectScrollbar
            options={{ suppressScrollX: true, wheelPropagation: false }}
          >
            {menuItems &&
              menuItems.map((item) => {
                return (
                  <Nav
                    key={item.id}
                    className={classnames({
                      "d-block":
                        (selectedParentMenu === item.id &&
                          viewingParentMenu === "") ||
                        viewingParentMenu === item.id,
                    })}
                    data-parent={item.id}
                  >
                    {item.subs &&
                      item.subs.map((sub, index) => {
                        return (
                          <NavItem
                            key={`${item.id}_${index}`}
                            className={`${
                              sub.subs && sub.subs.length > 0
                                ? "has-sub-item"
                                : ""
                            }`}
                          >
                            {sub.newWindow ? (
                              <a
                                href={sub.to}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <span className={`lnr lnr-${sub.icon}`} />
                                {sub.label}
                              </a>
                            ) : sub.subs && sub.subs.length > 0 ? (
                              <>
                                <NavLink
                                  className={`rotate-arrow-icon opacity-50 ${
                                    collapsedMenus.indexOf(
                                      `${item.id}_${index}`
                                    ) === -1
                                      ? ""
                                      : "collapsed"
                                  }`}
                                  to={sub.to}
                                  id={`${item.id}_${index}`}
                                  onClick={(e) =>
                                    toggleMenuCollapse(e, `${item.id}_${index}`)
                                  }
                                >
                                  <span
                                    className={`lnr lnr-chevron-down simple-icon-arrow-down`}
                                  />
                                  {sub.label}
                                </NavLink>

                                <Collapse
                                  isOpen={
                                    collapsedMenus.indexOf(
                                      `${item.id}_${index}`
                                    ) === -1
                                  }
                                >
                                  <Nav className="third-level-menu">
                                    {sub.subs.map((thirdSub, thirdIndex) => {
                                      return (
                                        <NavItem
                                          key={`${item.id}_${index}_${thirdIndex}`}
                                        >
                                          {thirdSub.newWindow ? (
                                            <a
                                              href={thirdSub.to}
                                              rel="noopener noreferrer"
                                              target="_blank"
                                            >
                                              <span
                                                className={`lnr lnr-${thirdSub.icon}`}
                                              />
                                              {thirdSub.label}
                                            </a>
                                          ) : (
                                            <NavLink to={thirdSub.to}>
                                              <span
                                                className={`lnr lnr-${thirdSub.icon}`}
                                              />
                                              {thirdSub.label}
                                            </NavLink>
                                          )}
                                        </NavItem>
                                      );
                                    })}
                                  </Nav>
                                </Collapse>
                              </>
                            ) : (
                              <NavLink to={sub.to}>
                                <span className={`lnr lnr-${sub.icon}`} />
                                {sub.label}
                              </NavLink>
                            )}
                          </NavItem>
                        );
                      })}
                  </Nav>
                );
              })}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
