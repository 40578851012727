import * as React from "react"

const AlbumIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M72.9 66C56.7 67.9 42 78.2 34 93.1c-6.3 11.8-6 6-6 132.9 0 100.6.2 116.3 1.5 121.5 4.7 18.1 19.6 32.8 38.1 37.7 3.9 1 36.4 1.3 159.4 1.3h154.5l6.5-2.3c3.5-1.3 8.5-3.6 10.9-5 6.6-3.9 15.1-12.8 18.8-19.8 6.6-12.4 6.3-6 6.3-133.4s.3-121-6.3-133.4c-3.7-7-12.2-15.9-18.8-19.8-2.4-1.4-7.4-3.7-10.9-5l-6.5-2.3-151-.1c-83-.1-154 .2-157.6.6zM84 226v131h-4.7c-2.7 0-6.6-.9-8.9-1.9-4.9-2.3-10.7-8.8-12.2-14-1.7-5.5-1.7-224.7 0-230.2 1.5-5.2 7.3-11.7 12.2-14 2.3-1 6.2-1.9 8.9-1.9H84v131zM381.6 96.9c4.9 2.3 10.7 8.8 12.2 14 1.7 5.5 1.7 224.7 0 230.2-1.5 5.2-7.3 11.7-12.2 14-3.9 1.8-10 1.9-136.3 1.9H113V95h132.3c126.3 0 132.4.1 136.3 1.9z" />
    <path d="M189.6 143.5c-7.3 3-13.8 9.2-17.4 16.5l-2.7 5.5-.3 57.5c-.3 60-.1 64.3 3.9 70.9l2.1 3.4 33.6-33.5c18.5-18.5 35.2-34.4 37-35.3 4.7-2.3 13.1-2.1 17.8.4 2.2 1.1 18.5 16.6 36.4 34.5s33 32.6 33.7 32.6c.6 0 2.1-2.5 3.2-5.5 2-5.4 2.1-7.3 2.1-64.5s-.1-59.1-2.1-64.5c-2.5-6.7-9.4-14.1-16.4-17.5l-5-2.5-60-.2-60-.3-5.9 2.5zm105.6 38.9c3.5 2.3 6.2 8.7 5.4 12.6-2.3 11.6-15.8 15.2-23.6 6.3-3-3.3-3.2-4.1-2.8-9 .4-4.4 1.1-6 3.5-8.5 4.9-4.8 11.5-5.4 17.5-1.4z" />
    <path d="m221.2 276.3-32.3 32.3 3.9 1.2c5.4 1.6 116.4 1.7 122.8.1 2.4-.6 4.4-1.4 4.4-1.8 0-1.4-63.6-64.1-65-64.1-.9 0-16.1 14.5-33.8 32.3z" />
  </svg>
)

export default AlbumIcon
