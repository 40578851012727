import React, { createContext, useContext, useReducer } from "react";
import { initialState, sidebarReducer } from "./sidebarReducer";

// Prepares the dataLayer
const sidebarStateContext = createContext();
const sidebarDispatchContext = createContext();

export function useSidebarState() {
	const context = useContext(sidebarStateContext);
	if (context === undefined) {
		throw new Error('usesidebarState must be used within a sidebarProvider');
	}

	return context;
}

export function useSidebarDispatch() {
	const context = useContext(sidebarDispatchContext);
	if (context === undefined) {
		throw new Error('usesidebarDispatch must be used within a sidebarProvider');
	}

	return context;
}

export const SidebarProvider = ({ children }) => {
  const [sidebar, dispatch] = useReducer(sidebarReducer, initialState);

  return (
    <sidebarStateContext.Provider value={sidebar}>
      <sidebarDispatchContext.Provider value={dispatch}>
        {children}
      </sidebarDispatchContext.Provider>
    </sidebarStateContext.Provider>
  );
};
