import CryptoJS from "crypto-js";

var JsonFormatter = {
  stringify: function (cipherParams) {
    // create json object with ciphertext
    let jsonObj = {
      ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
    };
    // optionally add iv or salt
    // if (cipherParams.iv) {
    //   jsonObj.iv = cipherParams.iv.toString();
    // }
    // if (cipherParams.salt) {
    //   jsonObj.s = cipherParams.salt.toString();
    // }
    // stringify json object
    return JSON.stringify(jsonObj);
  },

  parse: function (jsonStr) {
    // parse json string
    var jsonObj = JSON.parse(jsonStr);
    // extract ciphertext from json object, and create cipher params object
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct),
    });
    // optionally extract iv or salt
    if (jsonObj.iv) {
      cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
    }
    if (jsonObj.s) {
      cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
    }
    return cipherParams;
  },
};
export const encryptData = (data, salt, iv) =>{
  const key = CryptoJS.enc.Utf8.parse(salt);
  const ivKey= CryptoJS.enc.Utf8.parse(iv)
  const encrpted = CryptoJS.AES.encrypt(data,key , {
    mode: CryptoJS.mode.CBC,
    iv: ivKey,
  }).toString();

  return encrpted;
}
export const decryptData = (ciphertext, salt, iv) => {
  try {
  const decrypt = CryptoJS.AES.decrypt(
    ciphertext,
    CryptoJS.enc.Utf8.parse(salt),
    { mode: CryptoJS.mode.CBC, iv: CryptoJS.enc.Utf8.parse(iv), }
  );
    return decrypt.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    return null;
  }
};
