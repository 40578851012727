import { INDUSTRIES_LOADED, TAGS_LOADED } from '../../constants/dropdown';
import {  SET_LOADING} from '../../constants/shared';

export const initialState = {
    industries: [],
    dropDownLoading: false,
  };

export const dropDownReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        dropDownLoading: true,
      };
    case INDUSTRIES_LOADED:
      return {
        ...state,
        industries: payload,
        dropDownLoading: false,
      };
      case TAGS_LOADED:
        return {
          ...state,
          dropDownLoading: false,
        };
    default:
      return state;
  }
};
