import {
  GET_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  RESET_CART,
  SET_AS_EXCLUSIVE,
  GET_DOWNLOADS,
  GET_WONS,
  CART_ERROR,
  GET_MORE_DOWNLOADS,
  GET_MORE_WONS,
  ADD_TO_CART_GUEST,
  REMOVE_FROM_CART_GUEST,
  GET_GUEST_CART
} from "../../constants/cart";

import {  SET_LOADING, RESET_ALL_LOADED} from '../../constants/shared';

export const initialState = {
  cart: null,
  guestCart: localStorage.getItem("cartList") !== null
  ? JSON.parse(localStorage.getItem("cartList"))
  : [],
  count:0,
  downloads:null,
  downloadsCount:null,
  wons:null,
  loading:false
};

export const cartReducer = (state, action) => {
  
  let copyOfObject = {};
  let gCart = null;
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_CART:
      return {
        ...state,
        cart: payload.data,
        count:payload.data.length,
        loading:false
      };
      // case GET_GUEST_CART:
      //   // const gCart=localStorage.getItem("cartList") !== null
      //   // ? JSON.parse(localStorage.getItem("cartList"))
      //   // : [];
      //   return {
      //     ...state,
      //     cart: gCart,
      //     count:gCart.length,
      //     loading:false
      //   };
      case REMOVE_FROM_CART:
        const newCart = [...state.cart];
        const index = newCart.findIndex(item => item.id == payload.photo_id);
        newCart.splice(index,1);
        return {
          ...state,
          cart: newCart,
          count:newCart.length,
          loading:false
        };

    case ADD_TO_CART:
      state.count = state.count + 1;
      return{
        ...state,
        loading:false
      }
      case ADD_TO_CART_GUEST:
    gCart = [...state.guestCart];
    gCart.push(payload.photo_id);
        localStorage.setItem("cartList", JSON.stringify(gCart));
        return{
          ...state,
          count : state.count++,
          guestCart:gCart,
          loading:false
        }
        case REMOVE_FROM_CART_GUEST:
    gCart = [...state.guestCart];
        const indx = gCart.findIndex(itemId => itemId == payload.photo_id);
        gCart.splice(indx,1);
        localStorage.setItem("cartList", JSON.stringify(gCart));
        const nCart = [...state.cart];
        const ind = nCart.findIndex(item => item.id == payload.photo_id);
        nCart.splice(ind,1);
        return {
          ...state,
          guestCart: gCart,
          cart:nCart,
          count:gCart.length,
          loading:false
        };
    case SET_AS_EXCLUSIVE:
      state.cart.find(item => item.id == payload.get("photo_id")).is_marked_as_exclusive = payload.get("is_exclusive") === "true"
        return{
          ...state,
          loading:false
        }
        case GET_DOWNLOADS:
          copyOfObject = { ...payload.data };
          delete copyOfObject["data"];
          return {
            ...state,
            downloads: payload.data.data,
            purchasedPagination: copyOfObject,
            allPurchasedLoaded: payload.data.current_page === payload.data.last_page,
            //downloadsCount: payload.data.data.reduce((acc, subArr) => acc + subArr.photos.length, 0),
            loading:false
          };
          case GET_MORE_DOWNLOADS:
            copyOfObject = { ...payload.data };
            delete copyOfObject["data"];
            return {
              ...state,
              downloads: state.downloads
                ? [...state.downloads, ...payload.data.data]
                : payload.data.data,
              purchasedPagination: copyOfObject,
              allPurchasedLoaded:
                payload.data.current_page === payload.data.last_page,
              loading: false,
            };
          case GET_WONS:
            copyOfObject = { ...payload.data };
          delete copyOfObject["data"];
          return {
            ...state,
            wons: payload.data.data,
            wonsPagination: copyOfObject,
            allWonsLoaded: payload.data.current_page === payload.data.last_page,
            //downloadsCount: payload.data.data.reduce((acc, subArr) => acc + subArr.photos.length, 0),
            loading:false
            };
            case GET_MORE_WONS:
              copyOfObject = { ...payload.data };
              delete copyOfObject["data"];
              return {
                ...state,
                wons: state.wons
                  ? [...state.wons, ...payload.data.data]
                  : payload.data.data,
                wonsPagination: copyOfObject,
                allWonsLoaded:
                  payload.data.current_page === payload.data.last_page,
                loading: false,
              };
    case RESET_CART:
      localStorage.removeItem("cartList");
      return {
        ...state,
        cart: [],
        guestCart:[],
        count:0,
        loading:false
      };
      case CART_ERROR:
        return {
          ...state,
          loading: false,
        };
    default:
      return state;
  }
};
