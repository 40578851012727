import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

// Routes
import Routes from "./components/routing/Routes";

//States
import { UserProvider } from "./contexts/user/userContext";
import { SidebarProvider } from "./contexts/sidebar/sidebarContext";
import { CartProvider } from "./contexts/cart/cartContext";
import { AlertProvider } from "./contexts/alert/alertContext";
import { DropDownProvider } from "./contexts/dropDown/dropDownContext";
import { PhotoProvider } from "./contexts/photo/photoContext";
import { PhotographerProvider } from "./contexts/photographer/photographerContext";
import { AlbumProvider } from "./contexts/album/albumContext";
import { StudioProvider } from "./contexts/studio/studioContext";
import { UploadProvider } from "./contexts/upload/uploadContext";
import { CampaignProvider } from "./contexts/campaign/campaignContext";
import { NotificationProvider } from "./contexts/notification/notificationContext";
import { ExploreProvider } from "./contexts/explore/exploreContext";

function App() {
  return (
    <AlertProvider>
      <NotificationProvider>
      <CartProvider>
        <UserProvider>
          <DropDownProvider>
            <SidebarProvider>
              <PhotoProvider>
                <PhotographerProvider>
                  <AlbumProvider>
                    <StudioProvider>
                      <UploadProvider>
                        <CampaignProvider>
                        <ExploreProvider>
                <Router>
                  <Routes />
                </Router>
                </ExploreProvider>
                </CampaignProvider>
                </UploadProvider>
                </StudioProvider>
                </AlbumProvider>
                </PhotographerProvider>
              </PhotoProvider>
            </SidebarProvider>
          </DropDownProvider>
        </UserProvider>
      </CartProvider>
      </NotificationProvider>
    </AlertProvider>
  );
}

export default App;
