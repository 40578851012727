import React, { createContext, useContext, useReducer } from "react";
import { initialState, campaignReducer } from "./campaignReducer";

// Prepares the dataLayer
const campaignStateContext = createContext();
const campaignDispatchContext = createContext();

export function useCampaignState() {
	const context = useContext(campaignStateContext);
	if (context === undefined) {
		throw new Error('usecampaignState must be used within a campaignProvider');
	}

	return context;
}

export function useCampaignDispatch() {
	const context = useContext(campaignDispatchContext);
	if (context === undefined) {
		throw new Error('usecampaignDispatch must be used within a campaignProvider');
	}

	return context;
}

export const CampaignProvider = ({ children }) => {
  const [campaign, dispatch] = useReducer(campaignReducer, initialState);

  return (
    <campaignStateContext.Provider value={campaign}>
      <campaignDispatchContext.Provider value={dispatch}>
        {children}
      </campaignDispatchContext.Provider>
    </campaignStateContext.Provider>
  );
};
