// Cart
export const GET_CART = 'GET_CART';
export const GET_GUEST_CART ='GET_GUEST_CART';
export const RESET_CART = 'RESET_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_GUEST = 'ADD_TO_CART_GUEST';
export const REMOVE_FROM_CART_GUEST = 'REMOVE_FROM_CART_GUEST';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CART_ERROR = 'CART_ERROR';
export const SET_AS_EXCLUSIVE = 'SET_AS_EXCLUSIVE';
export const GET_DOWNLOADS ='GET_DOWNLOADS'
export const GET_WONS ='GET_WONS'
export const GET_MORE_DOWNLOADS ='GET_MORE_DOWNLOADS'
export const GET_MORE_WONS ='GET_MORE_WONS'