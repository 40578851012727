import { SET_ALERT, REMOVE_ALERT } from '../../constants/alert';

// Set Alert
export const setAlert = (dispatch, alerts, alertType) => {
  if (!alerts.find(
    alert => alert.code === 7))
    {
    dispatch({
      type: SET_ALERT,
      payload: { alerts, alertType },
    });

    setTimeout(() => {
      dispatch({ type: REMOVE_ALERT });
    }, 2000);
  }
  };

   export default setAlert;