import * as React from "react";

const GlobalIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 114 112"
    fill="currentColor"
    {...props}
  >
    <path d="M49.7 9.1c-8.7 1.3-19.2 6.7-26 13.6C5 41.3 5 70.7 23.7 89.3c31.9 32 85.6 5.3 80.1-39.9C101.5 29.5 83.9 12 63.4 9c-3.8-.5-7.1-.9-7.4-.9-.3.1-3.1.5-6.3 1zm12.1 8.5c2.5 1.7 7.6 11.2 8.7 16.1l.7 3.3H42.8l.7-3.3c1.1-4.9 6.2-14.4 8.7-16.1 1.2-.9 3.4-1.6 4.8-1.6s3.6.7 4.8 1.6zM38 28l-2.9 8.5-6.6.3c-3.6.2-6.5 0-6.5-.3 0-2.2 7.3-10.2 12.4-13.6 3.2-2.2 6-3.8 6.2-3.6.2.1-1 4.1-2.6 8.7zm43.7-3.8C85.3 26.9 92 34.7 92 36.5c0 .3-2.9.5-6.5.3l-6.6-.3L76 28c-1.6-4.7-3-8.6-3-8.8 0-.5 4.5 2.1 8.7 5zM33.6 56v12h-7.3c-8.9 0-9.3-.6-9.3-12s.4-12 9.3-12h7.3v12zm39.6-5.8c.4 3.2.4 8.4 0 11.5l-.7 5.8-14.9.3c-8.8.1-15.3-.1-15.9-.7-1.2-1.2-1.4-20.2-.3-22 .5-.8 5.1-1.1 15.9-.9l15.2.3.7 5.7zM96 45.9c.5 1.1 1 5.6 1 10.1 0 11.4-.4 12-9.3 12h-7.3V44h7.3c6.1 0 7.4.3 8.3 1.9zM38 84c1.6 4.6 2.8 8.6 2.6 8.7-.2.2-3-1.4-6.2-3.6C29.3 85.7 22 77.7 22 75.5c0-.3 2.9-.5 6.5-.3l6.6.3L38 84zm32.5-5.8c-1.1 5-6.2 14.5-8.7 16.2-2.9 2-6.7 2-9.6 0-2.5-1.7-7.6-11.2-8.7-16.2l-.7-3.2h28.4l-.7 3.2zM92 75.6c0 2.1-7.4 10.2-12.4 13.5-3.2 2.2-6 3.8-6.2 3.6-.2-.1 1-4.1 2.6-8.7 1.6-4.7 3-8.6 3-8.8 0-.5 13-.2 13 .4z" />
   </svg>
);

export default GlobalIcon;
