import axios from "axios";

export const instance = axios.create({
  // THE API Base URL
  //baseURL: "https://admindevs.phloggers.com", 
  baseURL: "https://admin.phloggers.com", 

  headers: {
    "content-type": "application/json",
    "x-lang-code": "en-us",
    "x-user-type": 1,
    'Accept': 'application/json',
    'x-platform-type':'web'
  },
});

export const axiosWithoutHeader = axios.create({
  // THE API Base URL
  //baseURL: "https://admindevs.phloggers.com", 
  baseURL: "https://admin.phloggers.com", 

  headers: {
    "content-type": "application/json",
    'Accept': 'application/json',
  },
});



export default instance;
