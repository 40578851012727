import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserState } from "../../contexts/user/userContext";
import * as ROUTES from "../../constants/routes";

import AppLayout from "../../layout/AppLayout"
import CheckOutLayout from "../../layout/CheckOutLayout";

const PrivateDefaultLayoutRoute = ({ component: Component, ...rest }) => {
  const {isAuthenticated, loading} = useUserState();

  if (!isAuthenticated && (!rest.allowGuest) && !loading) return <Redirect to= {ROUTES.LOGIN} />;

  if (rest.path !== ROUTES.CHECKOUT) 
  return (
    <Route
    {...rest}
    render={(props) => (
      <AppLayout>
        <Component {...props} />
  </AppLayout>
    )}
    />
  )
  else{
  return (
    <Route
      {...rest}
      render={(props) => (
        <CheckOutLayout>
          <Component {...props} />
    </CheckOutLayout>
      )}
    />
  );
      }
};

export default PrivateDefaultLayoutRoute;
