import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const NotFound = () => {
  return (
    <div className="flex_col justify_center min_height">
    <h1>
        Sorry, the page you visited does not exist.
    </h1>
        <Button color="primary">
          <Link to='/'>Back Home</Link>
        </Button>
      </div>
    
  );
};

export default NotFound;
