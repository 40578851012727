import React, { createContext, useContext, useReducer } from "react";
import { initialState, userReducer } from "./userReducer";

// Prepares the dataLayer
const userStateContext = createContext();
const userDispatchContext = createContext();

export function useUserState() {
	const context = useContext(userStateContext);
	if (context === undefined) {
		throw new Error('useuserState must be used within a userProvider');
	}

	return context;
}

export function useUserDispatch() {
	const context = useContext(userDispatchContext);
	if (context === undefined) {
		throw new Error('useuserDispatch must be used within a userProvider');
	}

	return context;
}

export const UserProvider = ({ children }) => {
  const [user, dispatch] = useReducer(userReducer, initialState);

  return (
    <userStateContext.Provider value={user}>
      <userDispatchContext.Provider value={dispatch}>
        {children}
      </userDispatchContext.Provider>
    </userStateContext.Provider>
  );
};
