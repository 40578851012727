import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import IconComponent from "../svg/IconComponent";

const TopbarMenuLink = ({ title, icon, path, onClick,simple }) => (
  <DropdownItem tag={Link} to={path}  onClick={onClick}>
   {simple ?  <span className={`color-gray mr-2 lnr lnr-${icon}`} />
   :
    <IconComponent name ={icon}  width="20" height= "20" className="mr-2"/>
}
    <span>{title}</span>
  </DropdownItem>
);
TopbarMenuLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

TopbarMenuLink.defaultProps = {
  simple: false,
};
export default TopbarMenuLink;
