import * as React from "react";

const CrownIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 116 114"
    fill="currentColor"
    {...props}
  >
     <path d="M53 19.2c-5 2.7-6.3 9.1-3.1 14.4l1.9 3.1-3.9 5.9c-2.1 3.3-4.3 5.9-4.9 5.9-.5 0-3.7-2.4-7-5.3-5.8-5.1-6-5.5-6-10.2 0-3.7-.6-5.6-2.4-7.7-2.1-2.4-3.1-2.8-7.7-2.7-6.6.1-9.9 3.2-9.9 9.6 0 3.6.6 4.7 4.4 8.2 4.3 4.1 4.3 4.2 7.1 18.1C23 66.2 24.6 77 25 82.4 26 96.9 23.9 96 58 96s32 .9 33-13.6c.4-5.4 2-16.2 3.5-23.9 2.8-13.9 2.8-14 7.1-18.1 3.8-3.5 4.4-4.6 4.4-8.2 0-6.4-3.3-9.5-9.9-9.6-4.6-.1-5.6.3-7.7 2.7-1.8 2.1-2.4 4-2.4 7.7 0 4.7-.2 5.1-6 10.2-3.3 2.9-6.5 5.3-7 5.3-.6 0-2.8-2.6-4.9-5.9l-3.9-5.8 2-3.5c2.4-4 1.9-9.6-1-12.6-2.4-2.4-8.9-3.2-12.2-1.5zm6.7 6.9c1.5 1.5.5 3.9-1.7 3.9s-3.2-2.4-1.7-3.9c.6-.6 1.4-1.1 1.7-1.1.3 0 1.1.5 1.7 1.1zM23 32c0 1.9-2.5 3.4-4.1 2.4-2.3-1.4.2-5.7 2.7-4.7.8.3 1.4 1.3 1.4 2.3zm75 .6c0 1.7-1.5 2.5-3.4 1.8s-2.1-4-.3-4.7c1.5-.5 3.7 1.1 3.7 2.9zM63.4 50c4.2 7.2 6.8 10 9.4 10 .5 0 4.2-3 8.1-6.6 4-3.7 7.4-6.4 7.7-6.1.5.4-1.7 12.9-4.2 24.4L84 74H32l-.4-2.3c-2.6-11.9-4.6-24-4.2-24.5.4-.3 2.8 1.4 5.4 3.9 9.7 9.1 10 9.3 12.6 8.1 1.3-.6 4.4-4.6 7.1-9.1 2.6-4.5 5.1-8.1 5.5-8.1.4 0 2.8 3.6 5.4 8zm20.4 34.7-.3 3.8h-51l-.3-3.8-.3-3.7h52.2l-.3 3.7z" />
   </svg>
);

export default CrownIcon;
