import * as React from "react"

const PhotosIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M95.6 57.6C77.4 62 61.9 77.7 57.5 96.1c-2.2 9.6-2.2 250.2 0 259.8 4.5 19.1 21 35 40.3 39 3.7.8 42.9 1.1 128.5 1.1 106.4 0 124.1-.2 129.6-1.5 18.6-4.4 34.2-20 38.6-38.6 2.2-9.6 2.2-250.2 0-259.8-4.4-18.7-19.9-34.2-38.6-38.6-9.3-2.2-251.2-2.1-260.3.1zm257.9 30.1c4.7 2.5 8.5 6.4 11.1 11.3 1.8 3.3 1.9 8.1 2.2 101.9l.2 98.4-4.7-4.4c-2.7-2.4-15.7-14.9-29-27.7-13.3-12.9-26.1-24.7-28.4-26.3-10.3-6.8-25.4-7.7-37.2-2.1-4.9 2.3-9.3 5.7-17.9 14l-11.4 11-29.4-28.4c-16.2-15.7-31.3-29.8-33.5-31.3-9-6.2-20.1-8.3-30.3-5.7-12 3.1-11.5 2.6-51 41.1l-9.2 9 .2-73c.3-69 .4-73.2 2.2-76.5 3.5-6.6 8.7-11 15.6-13.1 1.4-.4 57.4-.7 124.5-.6l122 .2 4 2.2zM166.8 236c5.3 4.9 18.8 17.9 30 28.7l20.4 19.8-42.9 41.3-42.8 41.4-14.5-.4c-11.7-.3-15.2-.7-18-2.2-4.9-2.6-8.8-6.4-11.3-11.1-2.1-3.8-2.2-5.5-2.5-34l-.3-30 20.8-20.1c45.9-44.2 43.8-42.4 47.8-42.4 3.1 0 4.8 1.2 13.3 9zM295 271.3c3.6 3.5 21.1 20.5 39 37.8l32.5 31.4-.1 4.5c-.2 7.8-5.4 15.3-13.4 19.6-3.3 1.8-7.3 1.9-70.5 2.2-36.8.1-76.2.1-87.4 0l-20.3-.3 51.7-50c28.4-27.5 52.7-50.6 53.9-51.3 3.7-2.2 7.7-.5 14.6 6.1z" />
    <path d="M275.2 123.6c-18.8 5-30.5 20.7-30.6 40.9 0 15.1 6.3 27.9 17.6 35.5 8 5.3 15.2 7.4 25.8 7.3 23.5-.2 40.1-15.9 41.7-39.4 2.2-30.3-24.9-52.3-54.5-44.3zm20.2 29.7c1.3.9 3.2 3.6 4.1 6.1 2.3 6 1.3 11-3 15.3-2.9 2.9-4 3.3-8.3 3.3-6.8 0-9.8-1.3-12.3-5.2-2.6-4.3-2.3-13.6.7-16.9 4.3-4.9 13.4-6.1 18.8-2.6z" />
  </svg>
)

export default PhotosIcon
