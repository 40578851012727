import * as React from "react"

const CommentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M90.5 66c-19.6 2.6-37.7 15.5-46.4 33.1-7.4 15-7.2 12.2-6.9 111.2.3 82.7.4 88.5 2.2 93.7 5 14.9 13.8 27 25.3 34.6 3.1 2.1 9.6 5.1 14.3 6.7 7.2 2.5 10.5 3.1 21.2 3.5l12.7.5.3 29.7.3 29.8 3.1 4.3c6.1 8.4 18.9 12.9 27.9 9.7 2.2-.8 25.3-17.3 52.2-37.6l48.2-36.1 59.8-.3 59.8-.3 7.9-2.8c9.9-3.5 15.3-6.6 22.2-13 9.8-9 16.9-21.8 19.3-35.1.7-4 1.1-34.2 1.1-91 0-95.3.1-92.8-7.1-107.5-8.9-18-26.6-30.4-47.2-33.1-8.7-1.1-261.6-1.1-270.2 0zm277 32.2c6.7 3.5 11.8 9 15.2 16.3l2.8 6v86c0 69.5-.3 86.8-1.3 90-3.7 10.8-13.2 19.6-23.9 22.1-4.6 1.1-18.4 1.4-65.1 1.4-42.4 0-59.9.3-61.5 1.1-1.1.7-22.1 16.2-46.6 34.6l-44.6 33.4-.5-29.8c-.6-33-.6-33.3-7.3-37.3-2.8-1.8-5.1-2-19.9-2-23.6 0-30.3-1.7-38.5-10-5-5-7.9-10.7-9.3-18.2-.7-3.7-1-34.3-.8-88.5l.3-82.8 2.8-6c4.3-9.2 11.6-15.8 20.7-18.6 1.4-.4 63.3-.7 137.5-.6l135 .2 5 2.7z" />
    <path d="M136.5 163.1c-4 3.6-6 9.2-5.1 14.3.9 4.5 6.2 10.1 10.6 11 1.8.3 39.6.6 84 .6s82.2-.3 84-.6c4.4-.9 9.7-6.5 10.6-11 .9-5.1-1.1-10.7-5.1-14.3l-3.6-3.1H140.1l-3.6 3.1zM137.7 227.8c-3.2 2-6.7 8.6-6.7 12.6 0 5 3.7 10.6 8.5 12.7 4.2 1.9 6.9 1.9 69.3 1.7 60.5-.3 65-.4 67.5-2.1 8.8-5.7 9-19.5.4-24.7-3.1-1.9-5.5-2-69.7-2-61.2.1-66.7.2-69.3 1.8z" />
  </svg>
)

export default CommentIcon
