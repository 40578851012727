import * as React from "react"

const LogOutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M95.6 57.6C77.4 62 61.9 77.7 57.5 96.1c-2.2 9.6-2.2 250.2 0 259.8 4.5 19.1 21 35 40.3 39 3.6.8 33.9 1.1 95.5 1.1 99.4 0 97 .1 109.1-6.3 7.8-4 17.3-13.5 21.3-21.3 3.7-7 6.3-16.7 6.3-23.9 0-14.5-15.9-20.8-25.8-10.2-1.6 1.7-2.6 4.5-3.3 9.2-.5 3.7-1.7 8.3-2.5 10.2-2.6 5.4-9.8 11-15.7 12.3-3.7.8-31 1-92.7.8-83.1-.3-87.7-.4-91-2.2-4.9-2.6-8.8-6.4-11.3-11.1l-2.2-4v-247l2.2-4c2.5-4.7 6.4-8.5 11.3-11.1 3.3-1.8 7.9-1.9 91-2.2 61.7-.2 89 0 92.7.8 5.9 1.3 13.1 6.9 15.7 12.3.8 1.9 2 6.5 2.5 10.2.7 4.7 1.7 7.5 3.3 9.2 9.9 10.6 25.8 4.3 25.8-10.2-.1-23.1-17.3-44.6-40.1-50-9.2-2.1-185.2-2.1-194.3.1z" />
    <path d="M310.6 146c-8 2.5-12.2 13.2-8.2 20.8.8 1.5 10.7 11.9 22 23.2l20.6 20.5-101.7.5c-114.9.6-106.6-.1-110.4 8.3-2.3 5.3-2.3 8.1 0 13.4 3.9 8.4-4.6 7.8 110.6 8.1l101.9.2-21.6 21.8c-14.1 14.1-21.9 22.7-22.4 24.5-.3 1.6-.4 4.9-.1 7.5.6 5.8 5.4 10.6 11.5 11.7 7.7 1.3 10.1-.6 47.4-38.1 37.4-37.7 37-37.1 35.3-46.2-.6-3.2-5.2-8.3-35.3-38.5-19.1-19.2-35.8-35.5-37.2-36.2-3.6-1.9-9-2.5-12.4-1.5z" />
  </svg>
)

export default LogOutIcon
