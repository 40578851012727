import React, { createContext, useContext, useReducer } from "react";
import { initialState, uploadReducer } from "./uploadReducer";

// Prepares the dataLayer
const uploadStateContext = createContext();
const uploadDispatchContext = createContext();

export function useUploadState() {
	const context = useContext(uploadStateContext);
	if (context === undefined) {
		throw new Error('useuploadState must be used within a uploadProvider');
	}

	return context;
}

export function useUploadDispatch() {
	const context = useContext(uploadDispatchContext);
	if (context === undefined) {
		throw new Error('useuploadDispatch must be used within a uploadProvider');
	}

	return context;
}

export const UploadProvider = ({ children }) => {
  const [upload, dispatch] = useReducer(uploadReducer, initialState);

  return (
    <uploadStateContext.Provider value={upload}>
      <uploadDispatchContext.Provider value={dispatch}>
        {children}
      </uploadDispatchContext.Provider>
    </uploadStateContext.Provider>
  );
};
