import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/vendor/bootstrap.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";

// import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/sass/themes/gogo.dark.yellowgranola.scss";

import clevertap from 'clevertap-web-sdk';
import App from "./App";

clevertap.init(process.env.REACT_APP_CT_PROJECT_ID); //"TEST-545-69Z-ZW7Z"); //
//clevertap.setLogLevel(3)
clevertap.spa = true;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
