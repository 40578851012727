export const HOME = "/home";
export const DEFAULT = "/";
export const SEARCH = "/search/:searchFor/:keyword";
export const SEARCHWITHOUTKEYWORD = "/search/:searchFor/";
export const SEARCHBASE = "/search";
export const LOGIN = "/login";
export const SIGN_UP = "/sign-up";
export const FORGOT_PASSWORD = "/forgot-password";
export const PROFILE = "/profile";
export const CHANGE_PASSWORD = "/change-password";
export const PHOTOGRAPHER = "/photographer/:id";
export const ALBUM = "/album/:name/:id";
export const PHOTOGRAPHER_PHOTO = "/p/photo/:id";
export const ALBUM_PHOTO = "/a/:albumId/photo/:id";
export const STUDIO_PHOTO = "/studio/:studioId/photo/:id";
export const CAMPAIGN_PHOTO = "/campaign/:campaignId/(iw)?/:won?/(cw)?/:canWon?/photo/:id";
//export const CAMPAIGN_PHOTO = "/campaign/:campaignId/photo/:id";
export const MOST_LIKED_PHOTO = "/ml/photo/:id";
export const NEW_PHLOG_PHOTO = "/np/photo/:id";
export const NEW_UPLOADs_PHOTO = "/nu/photo/:id";
export const EDITOR_PICKS = "/ep/photo/:id";
export const COMMON_TAGS_PHOTO = "/ct/photo/:id";
export const PHOTO = "/photo/:id";
export const SEARCH_PHOTO = "/s/:keyword/photo/:id";
export const SEARCH_PHOTO_NO_KEY = "/s/photo/:id";
export const CART = "/cart";
export const CHECKOUT = "/checkout";
export const NOT_FOUND = "/not-found";
export const BRAND = "/setup-brand";
export const DOWNLOADS = "/downloads";
export const CAMPAIGNS = "/campaigns";
// export const ADD_CAMPAIGN = "/add-campaign";
// export const EDIT_CAMPAIGN = "/edit-campaign/:id";
export const CAMPAIGN = "/campaign/:id";
export const CONTACTUS = "/contact-us";
export const ABOUTUS = "/about-us";
export const POLICY = "/policy";
export const STUDIO = "/studio/:name/:id";
export const STUDIOS = "/studios";
export const NOTIFICATIONS = "/notifications";
export const FEEDBACK = "/feedback";
export const LANDING = "/landing";
export const ROOM = "/room/:id";

