import {
  GET_ALBUMS,
  GET_COMMON_TAGS,
  GET_MOST_LIKED,
  GET_MOST_SELLING,
  GET_MORE_MOST_SELLING,
  GET_NEW_PHLOGS,
  GET_TOP_PICKS,
  GET_TOP_RATED,
  GET_WINNERS,
  GET_YOUR_PHLOGGERS,
  EXPLORE_ERROR,
  GET_MORE_TOP_RATED,
  SET_MOST_LIKED_LOADING,
  SET_MOST_SELLING_LOADING,
  SET_TOP_RATED_LOADING,
  SET_TOP_PICKS_LOADING,
  GET_MORE_TOP_PICKS,
  SET_NEW_PHLOGS_LOADING,
  SET_COMMON_TAGS_LOADING,
  SET_COMMON_INTERESTS_LOADING,
  GET_COMMON_INTERESTS,
  GET_MORE_COMMON_INTERESTS,
  SET_WINNERS_LOADING,
  GET_MORE_WINNERS,
  SET_YOUR_PHLOGGERS_LOADING,
  GET_MORE_YOUR_PHLOGGERS,
  SET_ALBUMS_LOADING,
  GET_MORE_ALBUMS,
  GET_NEW_UPLOADS,
  SET_NEW_UPLOADS_LOADING,
  SET_EDITOR_PICKS_LOADING,
  GET_EDITOR_PICKS,
} from "../../constants/explore";

import {
  FOLLOW_PHOTOHRAPHER,
  UNFOLLOW_PHOTOHRAPHER,
  PHOTOHRAPHER_ERROR,
} from "../../constants/photographer";
import { RESET_ALL_LOADED, SET_LOADING } from "../../constants/shared";

export const initialState = {
  likedLoading: false,
  sellingLoading: false,
  ratedLoading: false,
  picksLoading: false,
  newPhlogsLoading: false,
  tagsLoading: false,
  interestsLoading: false,
  winnersLoading: false,
  phloggersLoading: false,
  albumLoading: false,
  newUploadsLoading: false,
  editorPicksLoading: false,
};

export const exploreReducer = (state, action) => {
  const { type, payload } = action;
  let copyOfObject = {};
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_MOST_LIKED_LOADING:
      return {
        ...state,
        likedLoading: true,
      };
    case SET_MOST_SELLING_LOADING:
      return {
        ...state,
        sellingLoading: true,
      };
    case SET_TOP_RATED_LOADING:
      return {
        ...state,
        ratedLoading: true,
      };
    case SET_TOP_PICKS_LOADING:
      return {
        ...state,
        picksLoading: true,
      };
    case SET_COMMON_INTERESTS_LOADING:
      return {
        ...state,
        interestsLoading: true,
      };
    case SET_COMMON_TAGS_LOADING:
      return {
        ...state,
        tagsLoading: true,
      };
    case SET_NEW_UPLOADS_LOADING:
      return {
        ...state,
        newUploadsLoading: true,
      };
    case SET_EDITOR_PICKS_LOADING:
      return {
        ...state,
        editorPicksLoading: true,
      };
    case SET_NEW_PHLOGS_LOADING:
      return {
        ...state,
        newPhlogsLoading: true,
      };
    case SET_WINNERS_LOADING:
      return {
        ...state,
        winnersLoading: true,
      };
    case SET_YOUR_PHLOGGERS_LOADING:
      return {
        ...state,
        phloggersLoading: true,
      };
    case SET_ALBUMS_LOADING:
      return {
        ...state,
        albumLoading: true,
      };
    case GET_MOST_LIKED:
      return {
        ...state,
        mostLiked: payload.data,
        likedLoading: false,
      };
    case GET_MOST_SELLING:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        mostSelling: payload.data?.data,
        sellingPagination: copyOfObject,
        allSellingLoaded: payload.data?.current_page === payload.data?.last_page,
        sellingLoading: false,
      };
    case GET_MORE_MOST_SELLING:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        mostSelling: state.mostSelling
          ? [...state.mostSelling, ...payload.data.data]
          : payload.data.data,
        sellingPagination: copyOfObject,
        allSellingLoaded: payload.data.current_page === payload.data.last_page,
        sellingLoading: false,
      };
    case GET_NEW_PHLOGS:
      return {
        ...state,
        newPhlogs: payload.data,
        newPhlogsLoading: false,
      };
    case GET_NEW_UPLOADS:
      return {
        ...state,
        newUploads: payload.data,
        newUploadsLoading: false,
      };
    case GET_EDITOR_PICKS:
      return {
        ...state,
        editorPicks: payload.data,
        editorPicksLoading: false,
      };
    case GET_TOP_PICKS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        topPicks: payload.data?.data,
        picksPagination: copyOfObject,
        allPicksLoaded: payload.data?.current_page === payload.data?.last_page,
        picksLoading: false,
      };
    case GET_MORE_TOP_PICKS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        topPicks: state.topPicks
          ? [...state.topPicks, ...payload.data.data]
          : payload.data.data,
        picksPagination: copyOfObject,
        allPicksLoaded: payload.data.current_page === payload.data.last_page,
        picksLoading: false,
      };
    case GET_TOP_RATED:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        topRated: payload.data?.data,
        ratedPagination: copyOfObject,
        allRatedLoaded: payload.data?.current_page === payload.data?.last_page,
        ratedLoading: false,
      };
    case GET_MORE_TOP_RATED:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        topRated: state.topRated
          ? [...state.topRated, ...payload.data.data]
          : payload.data.data,
        ratedPagination: copyOfObject,
        allRatedLoaded: payload.data.current_page === payload.data.last_page,
        ratedLoading: false,
      };

    case GET_COMMON_INTERESTS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        commonInterests: payload.data?.data,
        interestsPagination: copyOfObject,
        allInterestsLoaded:
          payload.data?.current_page === payload.data?.last_page,
        interestsLoading: false,
      };
    case GET_MORE_COMMON_INTERESTS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        commonInterests: state.commonInterests
          ? [...state.commonInterests, ...payload.data.data]
          : payload.data.data,
        interestsPagination: copyOfObject,
        allInterestsLoaded:
          payload.data.current_page === payload.data.last_page,
        interestsLoading: false,
      };
    case GET_WINNERS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        winners: payload.data?.data,
        winnersPagination: copyOfObject,
        allWinnersLoaded: payload.data?.current_page === payload.data?.last_page,
        winnersLoading: false,
      };
    case GET_MORE_WINNERS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        winners: state.winners
          ? [...state.winners, ...payload.data.data]
          : payload.data.data,
        winnersPagination: copyOfObject,
        allWinnersLoaded: payload.data.current_page === payload.data.last_page,
        winnersLoading: false,
      };

    case GET_YOUR_PHLOGGERS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        phloggers: payload.data?.data,
        phloggersPagination: copyOfObject,
        allPhloggersLoaded:
          payload.data?.current_page === payload.data?.last_page,
        phloggersLoading: false,
      };
    case GET_MORE_YOUR_PHLOGGERS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        phloggers: state.phloggers
          ? [...state.phloggers, ...payload.data.data]
          : payload.data.data,
        phloggersPagination: copyOfObject,
        allPhloggersLoaded:
          payload.data.current_page === payload.data.last_page,
        phloggersLoading: false,
      };
    case GET_ALBUMS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        albums: payload.data?.data,
        albumsPagination: copyOfObject,
        allAlbumsLoaded: payload.data?.current_page === payload.data?.last_page,
        albumLoading: false,
      };
    case GET_MORE_ALBUMS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        albums: state.albums
          ? [...state.albums, ...payload.data.data]
          : payload.data.data,
        albumsPagination: copyOfObject,
        allAlbumsLoaded: payload.data.current_page === payload.data.last_page,
        albumLoading: false,
      };
    case GET_COMMON_TAGS:
      return {
        ...state,
        commonTags: payload.data,
        tagsLoading: false,
      };
    case FOLLOW_PHOTOHRAPHER:
      //if (state.topRated)
      let photographer = {};
      photographer = state.topRated?.find(
        (item) => item.id == payload.photographer_id
      );
      if (photographer) photographer.is_follow = true;
      photographer = state.mostSelling?.find(
        (item) => item.id == payload.photographer_id
      );
      if (photographer) photographer.is_follow = true;
      photographer = state.topPicks?.find(
        (item) => item.id == payload.photographer_id
      );
      if (photographer) photographer.is_follow = true;

      photographer = state.commonInterests?.find(
        (item) => item.id == payload.photographer_id
      );
      if (photographer) photographer.is_follow = true;

      photographer = state.winners?.find(
        (item) => item.id == payload.photographer_id
      );
      if (photographer) photographer.is_follow = true;

      photographer = state.phloggers?.find(
        (item) => item.id == payload.photographer_id
      );
      if (photographer) photographer.is_follow = true;
      return {
        ...state,
        loading: false,
      };

    case UNFOLLOW_PHOTOHRAPHER:
      let uPhotographer = {};
      uPhotographer = state.topRated?.find(
        (item) => item.id == payload.photographer_id
      );
      if (uPhotographer) uPhotographer.is_follow = false;
      uPhotographer = state.mostSelling?.find(
        (item) => item.id == payload.photographer_id
      );
      if (uPhotographer) uPhotographer.is_follow = false;
      uPhotographer = state.topPicks?.find(
        (item) => item.id == payload.photographer_id
      );
      if (uPhotographer) uPhotographer.is_follow = false;

      uPhotographer = state.commonInterests?.find(
        (item) => item.id == payload.photographer_id
      );
      if (uPhotographer) uPhotographer.is_follow = false;

      uPhotographer = state.winners?.find(
        (item) => item.id == payload.photographer_id
      );
      if (uPhotographer) uPhotographer.is_follow = false;

      uPhotographer = state.phloggers?.find(
        (item) => item.id == payload.photographer_id
      );
      if (uPhotographer) uPhotographer.is_follow = false;
      return {
        ...state,
        loading: false,
      };

    case PHOTOHRAPHER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case EXPLORE_ERROR:
      return {
        ...state,
        loading: false,
        // likedLoading: false,
        // sellingLoading: false,
        // ratedLoading: false,
        // newPhlogsLoading: false,
        // commonTagsLoading: false,
        // likedLoading: false,
        sellingLoading: false,
        ratedLoading: false,
        picksLoading: false,
        newPhlogsLoading: false,
        tagsLoading: false,
        interestsLoading: false,
        winnersLoading: false,
        phloggersLoading: false,
        albumLoading: false,
        newUploadsLoading: false,
        editorPicksLoading: false,
      };
    case RESET_ALL_LOADED:
      return {
        ...state,

        loading: false,
        sellingLoading: false,
        ratedLoading: false,
        picksLoading: false,
        newPhlogsLoading: false,
        tagsLoading: false,
        interestsLoading: false,
        winnersLoading: false,
        phloggersLoading: false,
        albumLoading: false,
        newUploadsLoading: false,
        editorPicksLoading: false,
        mostSelling:null,
        topRated:null,
        mostLiked:null,
        editorPicks:null,
        newPhlogs:null,
        newUploads:null,
        topPicks:null,
        phloggers:null,
        albums:null,
        winners:null,
        commonInterests:null,
      };
    default:
      return state;
  }
};
