import React, { createContext, useContext, useReducer } from "react";
import { initialState, studioReducer } from "./studioReducer";

// Prepares the dataLayer
const studioStateContext = createContext();
const studioDispatchContext = createContext();

export function useStudioState() {
	const context = useContext(studioStateContext);
	if (context === undefined) {
		throw new Error('usestudioState must be used within a studioProvider');
	}

	return context;
}

export function useStudioDispatch() {
	const context = useContext(studioDispatchContext);
	if (context === undefined) {
		throw new Error('usestudioDispatch must be used within a studioProvider');
	}

	return context;
}

export const StudioProvider = ({ children }) => {
  const [studio, dispatch] = useReducer(studioReducer, initialState);

  return (
    <studioStateContext.Provider value={studio}>
      <studioDispatchContext.Provider value={dispatch}>
        {children}
      </studioDispatchContext.Provider>
    </studioStateContext.Provider>
  );
};
