import * as React from "react"

const CalendarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M104.2 57.5c-16.9 3.7-34 17.1-41.6 32.5-6.8 14-6.6 9-6.6 136s-.2 122 6.6 136c5.2 10.6 17 22.3 27.6 27.4 14.2 6.8 8.9 6.6 135.8 6.6 127 0 122 .2 136-6.6 5.6-2.8 9.6-5.8 15.6-11.8 9.3-9.3 14.5-18.5 16.9-30.1 2.2-10.5 2.2-232.5 0-243-2.4-11.6-7.6-20.8-16.9-30.1-9.3-9.3-18.5-14.5-30.1-16.9-10-2.1-233.6-2.1-243.3 0zM347 88.1c7.9 3.7 12.5 8.1 16.1 15.5 2.9 5.7 3.3 7.8 3.7 17l.5 10.4H84.7l.5-10.3c.4-8.2 1-11.4 3-15.7 3.2-7.1 6.9-11.4 12.6-14.7 9.1-5.3 5.3-5.2 127.2-5l113.5.2 5.5 2.6zm19.8 163.2-.3 90.2-3.3 6.7c-3.7 7.6-8.2 12-16.2 15.7l-5.5 2.6h-231l-6.7-3.3c-7.6-3.7-12-8.2-15.7-16.2l-2.6-5.5-.3-90.3-.3-90.2h282.2l-.3 90.3z" />
    <path d="M140 197.9c-15.1 4.9-22 19.9-15.6 33.7 4 8.7 11.6 13.4 21.5 13.4 12.9 0 22.3-8.3 23.8-21 1.1-9.7-4.2-19.6-12.7-24-4.4-2.2-13.3-3.3-17-2.1zM220 197.9c-15 4.9-22 20-15.6 33.7 4 8.5 11.8 13.4 21.6 13.4s17.6-4.9 21.6-13.4c7.2-15.4-3.1-32.9-20-34.2-2.8-.2-6.2 0-7.6.5zM300.5 197.9c-14.9 3.9-22.6 19.8-16.1 33.7 8.4 18.1 34.8 18.1 43.2 0 5.6-12 .9-25.7-10.9-31.7-4.4-2.3-11.8-3.2-16.2-2zM137.9 283.3c-5.7 2.1-9.7 5.4-12.6 10.5-2.4 4-2.8 5.8-2.8 12.3 0 6.7.4 8.1 3 12.4 1.9 3.1 4.9 6.1 8 8 4.3 2.7 5.7 3 12.5 3s8.2-.3 12.6-3c3-1.9 6-4.9 7.9-7.9 2.7-4.4 3-5.8 3-12.6s-.3-8.2-3-12.6c-4.2-6.8-10.5-10.5-18.7-11-3.8-.3-7.7.1-9.9.9zM217.9 283.3c-5.7 2.1-9.7 5.4-12.6 10.5-2.4 4-2.8 5.8-2.8 12.3 0 6.7.4 8.1 3 12.5 1.9 3 4.9 6 7.9 7.9 4.4 2.7 5.8 3 12.6 3s8.2-.3 12.6-3c3-1.9 6-4.9 7.9-7.9 2.7-4.4 3-5.8 3-12.6s-.3-8.2-3-12.6c-4.2-6.8-10.5-10.5-18.7-11-3.8-.3-7.7.1-9.9.9z" />
  </svg>
)

export default CalendarIcon
