import { SET_ALERT, REMOVE_ALERT } from '../../constants/alert';

export const initialState = {
    alerts: [],
    alertType: null,
  };

export const alertReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERT:
      return {
        ...state,
        alerts: payload.alerts,
        alertType: payload.alertType,
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alerts: [],
        alertType: null,
      };
    default:
      return state;
  }
};
