import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";
import TopbarNotification from "./TopbarNotification";
import {
  useSidebarState,
  useSidebarDispatch,
} from "../../contexts/sidebar/sidebarContext";
import {
  setContainerClassnames,
  clickOnMobileMenu,
} from "../../contexts/sidebar/sidebarActions";
import TopbarCart from "./TopbarCart";
import { useUserState } from "../../contexts/user/userContext";
import {
  useStudioState,
  useStudioDispatch,
} from "../../contexts/studio/studioContext";
import { getStudios } from "../../contexts/studio/studioActions";
import { useAlertDispatch } from "../../contexts/alert/alertContext";
import * as ROUTES from "../../constants/routes";
import StarBoxMultipleOutlineIcon from "mdi-react/StarBoxMultipleOutlineIcon";
import StudioIcon from "../svg/StudioIcon";
import TopbarGuest from "./TopbarGuest";
import {myStudiosClicked} from "../../utils/cleverTapLogger"

export default function Topbar() {
  const { user, isAuthenticated } = useUserState();
  const { count } = useStudioState();
  const studioDispatch = useStudioDispatch();
  const alertDispatch = useAlertDispatch();

  const dispatch = useSidebarDispatch();
  const { menuClickCount, containerClassnames, selectedMenuHasSubItems } =
    useSidebarState();

  const menuButtonClick = (e) => {
    e.preventDefault();

    setTimeout(() => {
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    let menuClick = menuClickCount + 1;
    setContainerClassnames(
      dispatch,
      menuClick,
      containerClassnames,
      selectedMenuHasSubItems
    );
  };
  const mobileMenuButtonClick = (e) => {
    e.preventDefault();
    clickOnMobileMenu(dispatch, containerClassnames);
  };
  useEffect(() => {
    if (!count && isAuthenticated) {
      getStudios(studioDispatch, alertDispatch);
    }
  }, [count, studioDispatch]);
  return (
    <nav className="navbar fixed-top">
      <TopbarSidebarButton
        menuButtonClick={menuButtonClick}
        mobileMenuButtonClick={mobileMenuButtonClick}
      />
      <div className="navbar-right">
        <div className="header-icons d-inline-block align-middle">
        <div className="position-relative d-inline-block">
            <Link
              className="header-icon btn btn-empty"
              id="search"
              title="search"
              to={ROUTES.SEARCHBASE}
            >
              <span className="search-icon"><i className="simple-icon-magnifier"></i></span>
            </Link>
          </div>
          {isAuthenticated && (
            <>
          <div className="d-none position-relative d-sm-inline-block">
            <Link
              className="header-icon notificationButton btn btn-empty"
              id="studios"
              title="studios"
              to={ROUTES.STUDIOS}
            onClick={()=>myStudiosClicked()}
            >
              <StudioIcon width="28" height="28" className="vm" />
              {count > 0 && <span className="count">{count}</span>}
            </Link>
          </div>
          <TopbarNotification total={user.unread_attachments_count} />
          </>
          )}
          <TopbarCart isAuthenticated= {isAuthenticated}/>
        </div>
        {isAuthenticated? 
        <TopbarProfile user={user} />
        :
        <TopbarGuest/>
}
      </div>
    </nav>
  );
}
