import axios,{axiosWithoutHeader} from "../../utils/axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAIL,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  SET_DEVICEID_SUCCESS,
  DOCUMENTS_LOADED,
  BRAND_FAIL,
  SETUP_BRAND,
  EDIT_BRAND,
  BRAND_REQUEST_VERFICATION,
  MAIL_FAILED,
  MAIL_SENT,
  RESEND_VERIFICATION_FAIL,
  RESEND_VERIFICATION_SUCCESS
} from "../../constants/userConstant";
import {  RESET_ALL_LOADED, SET_LOADING} from '../../constants/shared';

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import setAlert from "../../contexts/alert/alertActions";
import {getStudios} from "../../contexts/studio/studioActions";
import {setAxiosTokenInterceptor} from "../../utils/setAuthToken";
import { getCart, getPurchasedList, getWonList } from "../cart/cartActions";
import { RESET_CAMPAIGNS } from "../../constants/campaign";
import { RESET_CART } from "../../constants/cart";
import {loginSuccessful, logout as logoutEvent} from "../../utils/cleverTapLogger";
import {loginCTUser,updateCTProfile} from '../../utils/cleverTapUtil';

// Set Loading
const setLoading = (dispatch) => dispatch({ type: SET_LOADING });

 // Load User
 export const loadUser = async (dispatch,alertDispatch) => {
  setLoading(dispatch);

  try {
    const res = await axios.post('/api/business/profile');

    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });

  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors;//.find(Boolean).message;
      dispatch({ type: AUTH_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      dispatch({ type: AUTH_ERROR, error:"authentication error please try again" });
      setAlert(alertDispatch, ["authentication error please try again" ], "error");
    }
  }
};

async function setDeviceId(dispatch,alertDispatch, token, email) {
  setLoading(dispatch);
  try {
    await FingerprintJS.load()
      .then((fp) => fp.get())
      .then(async (result) => {
        //console.log(result.visitorId);
        try {
          let data = {
            status: 1,
            device_id: result.visitorId,
            firebase_token: token,
            email: email,
          };
          await axios.post(
            "/api/business/auth/device/set",
            data
          );
          dispatch({
            type: SET_DEVICEID_SUCCESS,
            payload: result.visitorId,
          });
        } catch (err) {
          if (err.response && err.response.data.errors) {
            let errMsg = err.response.data.errors;//.find(Boolean).message;
            dispatch({ type: AUTH_ERROR, error: errMsg });
            setAlert(alertDispatch, errMsg, "error");
          }
        else{
          dispatch({ type: AUTH_ERROR, error:"authentication error please try again" });
          setAlert(alertDispatch, ["authentication error please try again"], "error");
        }
        }
      });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      error: "authentication error please try again",
    });
  }
}

// Login by email and password
export async function loginUser(dispatch,alertDispatch,studioDispatch,cartDispatch, loginPayload) {
  setLoading(dispatch);

  try {
    const cart= localStorage.getItem("cartList") !== null
    ? JSON.parse(localStorage.getItem("cartList"))
    : [];
    let response = await axiosWithoutHeader.post(
      "/api/business/auth/login",
       {email:loginPayload.email, password:loginPayload.password, cart_photos_ids:cart}
    );

    const token = response.data.data.token;
    const email = response.data.data.email;
    setAxiosTokenInterceptor(token);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data.data,
    });
    await loginCTUser({name:response.data.data.full_name, id:response.data.data.id, email:response.data.data.email,  phone:response.data.data.phone})
    loginSuccessful(true)
    //setAuthToken(token);
    await setDeviceId(dispatch,alertDispatch, token, email);

    await getStudios(studioDispatch,alertDispatch);
    await getCart(cartDispatch, alertDispatch);
    await getPurchasedList(cartDispatch, alertDispatch,1); 
    await getWonList(dispatch,alertDispatch,1);
    
    await loadUser(dispatch,alertDispatch);
    if (response.data.already_purchased_photos_message) 
      setAlert(alertDispatch, [response.data.already_purchased_photos_message], "success");
    return true;
  } catch (error) {
    loginSuccessful(false)
    if (error.response && error.response.data.errors) {
      let errMsg = error.response.data.errors;//.find(Boolean).message;
      dispatch({ type: LOGIN_FAIL, error: errMsg.find(Boolean).message });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      dispatch({ type: LOGIN_FAIL, error:"authentication error please try again" });
      setAlert(alertDispatch, ["authentication error please try again"], "error");
    }
    return false;
  }
}

// Forgot Password
export async function forgotPass(dispatch,alertDispatch, payload) {
  setLoading(dispatch);

  try {
    let response = await axiosWithoutHeader.post(
      "/api/business/auth/forgot_password",
      payload
    );
    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: response.data.data,
    });
    return true;
  } catch (error) {
    if (error.response && error.response.data.errors) {
      let errMsg = error.response.data.errors;//.find(Boolean).message;
      dispatch({ type: FORGOT_PASSWORD_FAIL, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      dispatch({ type: FORGOT_PASSWORD_FAIL, error:"authentication error please try again" });
      setAlert(alertDispatch, ["authentication error please try again" ], "error");
      
    }
    return false;
  }
}


// Resend Verification
export async function resendVerification(dispatch,alertDispatch, payload) {
  //setLoading(dispatch);

  try {
    let response = await axiosWithoutHeader.post(
      "/api/business/auth/resend_email_verification",
      payload
    );
    dispatch({
      type: RESEND_VERIFICATION_SUCCESS,
      payload: response.data.data,
    });
    
    setAlert(alertDispatch, ["Email verification sent successfully"], "success");
    return true;
  } catch (error) {
    if (error.response && error.response.data.errors) {
      let errMsg = error.response.data.errors;//.find(Boolean).message;
      dispatch({ type: RESEND_VERIFICATION_FAIL, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      dispatch({ type: RESEND_VERIFICATION_FAIL, error:"authentication error please try again" });
      setAlert(alertDispatch, ["authentication error please try again" ], "error");
      
    }
    return false;
  }
}

//Logout
export async function logout(dispatch, campaignDispatch,cartDispatch,exploreDispatch) {
  //setAxiosTokenInterceptor("")
  //setAxiosTokenInterceptor("guest-user")
  dispatch({ type: LOGOUT });
  logoutEvent();
  campaignDispatch({ type: RESET_CAMPAIGNS });
  cartDispatch({type:RESET_CART});
  exploreDispatch({type:RESET_ALL_LOADED})
  //reset cart
}

// Edit Profile
export async function editProfile (dispatch,alertDispatch,payload,changePass = false, userId, userEmail) {
  setLoading(dispatch);

  try {
    const res = await axios.post('/api/business/profile/update',payload);

    dispatch({
      type: EDIT_PROFILE_SUCCESS,
      payload: res.data.data,
    });
    if (!changePass) updateCTProfile({name:payload.get("first_name") + ' ' + payload.get("last_name"),
     id:userId, email:userEmail,  phone:payload.get("phone")})
    
    await loadUser(dispatch);
    if (changePass) setAlert(alertDispatch, ["Password Changed"], "success");
    else setAlert(alertDispatch, ["Profile Updated"], "success")
    return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors;//.find(Boolean).message;
      dispatch({ type: EDIT_PROFILE_FAIL, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      dispatch({ type: EDIT_PROFILE_FAIL, error: "Edit Profile Failed" });
      setAlert(alertDispatch, ["Edit Profile Failed please try again" ], "error");
    }
    return false;
  }
};

// Register
export async function signUp(dispatch,alertDispatch, payload) {
  setLoading(dispatch);

  try {
    
    const cart= localStorage.getItem("cartList") !== null
    ? JSON.parse(localStorage.getItem("cartList"))
    : [];
    payload.cart_photos_ids= cart
    let response = await axiosWithoutHeader.post(
      "/api/business/auth/signup",
      payload
    );
    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data,
    });
    setAlert(alertDispatch, ["Registration successful, Please Check your email to verify your account"], "success");
    return true;
  } catch (error) {
    if (error.response && error.response.data.errors) {
      let errMsg = error.response.data.errors;//.find(Boolean).message;
      dispatch({ type: REGISTER_FAIL, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      dispatch({ type: REGISTER_FAIL, error:"Register failed please try again" });
      setAlert(alertDispatch, ["Register failed please try again" ], "error");
    }
    return false;
  }
}

// Load Brand Documents
export const loadDocuments = async (dispatch,alertDispatch) => {
  //setLoading(dispatch);

  try {
    const res = await axios.post('/api/business/brand/document/list');

    dispatch({
      type: DOCUMENTS_LOADED,
      payload: res.data.data,
    });

  } catch (err) {
    if (err.response.data.errors) {
      let errMsg = err.response.data.errors;//.find(Boolean).message;
      dispatch({ type: BRAND_FAIL, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      dispatch({ type: BRAND_FAIL, error:"Error, please try again" });
      setAlert(alertDispatch, ["Error, please try again" ], "error");
    }
  }
};

// Setup Brand
export const setupBrand = async (dispatch,alertDispatch, brandPayload) => {
  setLoading(dispatch);

  try {
    const res = await axios.post('/api/business/brand/setup', brandPayload);

    dispatch({
      type: SETUP_BRAND,
      payload: res.data,
    });

     setAlert(alertDispatch, ["Brand submitted"], "success")
     return true;
  } catch (err) {
    if (err.response.data.errors) {
      let errMsg = err.response.data.errors;//.find(Boolean).message;
      dispatch({ type: BRAND_FAIL, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      dispatch({ type: BRAND_FAIL, error:"Error, please try again" });
      setAlert(alertDispatch, ["Error, please try again" ], "error");
    }
    return false;
  }
};

// Edit Brand
export const editBrand = async (dispatch,alertDispatch, brandPayload) => {
  setLoading(dispatch);

  try {
    const res = await axios.post('/api/business/brand/setup/update', brandPayload);

    dispatch({
      type: EDIT_BRAND,
      payload: res.data,
    });

     setAlert(alertDispatch, ["Brand updated"], "success")
     return true;
  } catch (err) {
    if (err.response.data.errors) {
      let errMsg = err.response.data.errors;//.find(Boolean).message;
      dispatch({ type: BRAND_FAIL, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      //console.log(err.message)
      dispatch({ type: BRAND_FAIL, error:"Error, please try again" });
      setAlert(alertDispatch, ["Error, please try again" ], "error");
    }
    return false;
  }
};

// Brand Request verification
export const requestVerification = async (dispatch,alertDispatch) => {
  setLoading(dispatch);

  try {
    const res = await axios.post('/api/business/brand/request_verify');

    dispatch({
      type: BRAND_REQUEST_VERFICATION,
      payload: res.data,
    });

     setAlert(alertDispatch, ["Request submitted"], "success")
     return true;
  } catch (err) {
    if (err.response.data.errors) {
      let errMsg = err.response.data.errors;//.find(Boolean).message;
      dispatch({ type: BRAND_FAIL, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      //console.log(err.message)
      dispatch({ type: BRAND_FAIL, error:"Error, please try again" });
      setAlert(alertDispatch, ["Error, please try again" ], "error");
    }
    return false;
  }
};


// Send Email
export const sendEmail = async (dispatch,alertDispatch,mailPayload) => {
  setLoading(dispatch);

  try {
    const res = await axios.post('/api/common/contact-us', mailPayload);

    dispatch({
      type: MAIL_SENT,
      payload: res.data,
    });

     setAlert(alertDispatch, ["mail sent successfully"], "success")
     return true;
  } catch (err) {
    if (err.response.data.errors) {
      let errMsg = err.response.data.errors;//.find(Boolean).message;
      dispatch({ type: MAIL_FAILED, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    }
    else{
      //console.log(err.message)
      dispatch({ type: MAIL_FAILED, error:"Error, please try again" });
      setAlert(alertDispatch, ["Error, please try again" ], "error");
    }
    return false;
  }
};