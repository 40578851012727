import {
GET_STUDIOS,
GET_STUDIO_PHOTOS,
GET_MORE_STUDIO_PHOTOS,
ADD_STUDIO,
REMOVE_STUDIO,
ADD_PHOTO_TO_STUDIO,
REMOVE_PHOTO_FROM_STUDIO,
STUDIO_ERROR,
REST_ERROR,
STUDIO_ADD_ERROR
 } from "../../constants/studio";
 import {  SET_LOADING, RESET_ALL_LOADED} from '../../constants/shared';

 
export const initialState = {
  loading: false,
 studios:null,
 count: null,
 photos:[],
 photosPagination: {},
 allPhotosLoaded: false,
 errorMessage:"",
};

export const studioReducer = (state, action) => {
  const { type, payload, error } = action;
  let copyOfObject = {};
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_STUDIOS:
      return {
        ...state,
        studios:payload,
        count:payload.length,
        errorMessage:"",
        loading:false,
      };
      case GET_STUDIO_PHOTOS:
        copyOfObject = { ...action.payload.data };
        delete copyOfObject["data"];
        return {
          ...state,
          photos: action.payload.data.data,
          photosPagination: copyOfObject,
          allPhotosLoaded:
            action.payload.data.current_page === action.payload.data.last_page,
          loading: false,
        };
      case GET_MORE_STUDIO_PHOTOS:
        copyOfObject = { ...action.payload.data };
        delete copyOfObject["data"];
        return {
          ...state,
          photos: state.photos
            ? [...state.photos, ...action.payload.data.data]
            : action.payload.data.data,
          photosPagination: copyOfObject,
          allPhotosLoaded:
            action.payload.data.current_page === action.payload.data.last_page,
          loading: false,
        };
    case ADD_STUDIO:
      //state.count = state.count + 1;
      return{
        ...state,
        loading:false
      }
      case ADD_PHOTO_TO_STUDIO:
        return{
          ...state,
          loading:false,
        }
      case REMOVE_STUDIO:
        //state.count = state.count - 1;
        const newSudios = [...state.studios];
        const index = newSudios.findIndex(item => item.id == payload.lightbox_id);
        newSudios.splice(index,1);
        return{
          ...state,
          studios:newSudios,
          loading:false,
          count: newSudios.length
        }
        case REMOVE_PHOTO_FROM_STUDIO:
          const newPhotos = [...state.photos];
        const photoIndex = newPhotos.findIndex(item => item.id == payload.photo_id);
        newPhotos.splice(photoIndex,1);
       // state.photosPagination.total = state.photosPagination.total- 1;
        //const total = state.photosPagination.total;
          return{
            ...state,
            photos:newPhotos,
            //allPhotosLoaded: total === newPhotos.length,
            loading:false,
          }
    case STUDIO_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: error,
      };
      case REST_ERROR:
        return{
          ...state,
          errorMessage:"",
          addErrorMessage:error,
        }
      case STUDIO_ADD_ERROR:
        return{
          ...state,
          loading: false,
          addErrorMessage:error,
        }
      case RESET_ALL_LOADED:
        return {
          ...state,
          photos:[],
          photosPagination: {},
          allPhotosLoaded: false,
      }
    default:
      return state;
  }
};
