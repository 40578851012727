// Studio
export const GET_STUDIOS = 'GET_STUDIOS';
export const GET_STUDIO_PHOTOS = 'GET_STUDIOS_PHOTOS';
export const GET_MORE_STUDIO_PHOTOS = 'GET_MORE_STUDIOS_PHOTOS';
export const ADD_STUDIO = 'ADD_STUDIO';
export const ADD_PHOTO_TO_STUDIO = 'ADD_PHOTO_TO_STUDIO';
export const REMOVE_STUDIO = 'REMOVE_STUDIO';
export const REMOVE_PHOTO_FROM_STUDIO = 'REMOVE_PHOTO_FROM_STUDIO';
export const STUDIO_ERROR = 'STUDIO_ERROR'
export const REST_ERROR = 'REST_ERROR';
export const STUDIO_ADD_ERROR = 'STUDIO_ADD_ERROR';