import * as React from "react"

const GuideLinesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M37.7 66.4c-2 .8-4.4 2-5.3 2.7-4.6 3.8-4.4-1.9-4.4 147.6 0 137.8 0 140.9 2 144 4.4 7.2-1.5 6.7 86.5 7.3 71.1.4 79.3.7 82 2.2 7.4 4 11.7 11 12.9 20.7 1.2 9.2 6.4 14.1 14.8 14.1 5.2 0 10.1-2.8 12.2-6.8.8-1.6 2-5.7 2.5-9 1.1-7 2.8-10.6 6.7-14.6 6.5-6.5 1.1-6.1 87.9-6.6 88-.6 82.1-.1 86.5-7.3 2-3.1 2-6.2 2-144.2 0-138.1 0-141.1-2-144.2-1-1.8-3.4-4-5.2-5-3.1-1.7-8.1-1.8-81.8-1.8-62 0-79.6.3-83.5 1.3-6.6 1.8-13.8 5.4-20.3 10.3l-5.3 4-3.7-2.9c-6.2-4.9-11.7-8-18.4-10.4l-6.3-2.3-78-.2c-63.8-.2-78.7 0-81.8 1.1zm162.8 32.1c3.6 2.2 5.7 4.5 7.7 8.4l2.8 5.3v116c0 110.1-.1 116-1.7 115.3-10.5-4.6-7.4-4.4-82-4.5H57V95l69.3.2 69.4.3 4.8 3zM395 217v122h-70.2c-74.7.1-71.5-.1-82.1 4.5-1.6.7-1.7-4.9-1.5-116.2l.3-117 3-4.8c2.2-3.6 4.5-5.7 8.4-7.7l5.3-2.8H395v122z" />
    <path d="M169 132.1c-1.4.5-13.4 11.9-26.7 25.2l-24.2 24.1-10.3-10.1c-8.1-7.9-11.2-10.3-14.5-11.2-7.3-2-13.7.8-17.2 7.6-1.5 3-1.4 9.9.3 13.1 2.2 4.1 32.7 33.9 35.9 35.2 4.8 1.7 9.1 1.2 13.8-1.9 5.7-3.7 59.2-57.6 61.3-61.8 3.1-6.2 1.3-14.3-4.2-18.2-3.5-2.5-10.6-3.5-14.2-2zM82.3 236.6c-9.8 4.9-9.7 21.1.1 25.8 3 1.4 9.3 1.6 49.8 1.6 44.4 0 46.4-.1 49.6-2 9.5-5.8 9.5-19.2 0-25-3.2-1.9-5.1-2-49.8-2-40.6 0-46.9.2-49.7 1.6zM82.4 283.9c-5.3 3.2-7.4 6.8-7.4 12.5 0 6.1 2.5 10.8 7 13.1 2.6 1.4 8.9 1.5 50.9 1.3l47.9-.3 2.7-2.5c8.2-7.7 7-19.5-2.5-24.2-3.2-1.6-7.7-1.8-49.5-1.8-43.3 0-46.2.1-49.1 1.9zM279.3 124c-6.1 3.7-8.5 13-5 19.5.8 1.6 6.8 8.2 13.1 14.5l11.6 11.4-12.4 12.6c-10 10-12.6 13.3-13.5 16.7-2.6 9.7 3.9 18.3 13.9 18.3 2.3 0 5.1-.5 6.3-1.1 1.2-.6 7.7-6.6 14.4-13.2l12.2-12.1 12.9 12.6c7.5 7.4 14 13 15.6 13.4 12.8 3.2 23.3-8.2 18.2-19.9-.8-1.8-6.8-8.6-13.5-15.2l-12.1-12 12.4-12.5c6.9-6.9 13-13.8 13.6-15.3 4.3-11.6-6.1-22.4-18.6-19.3-1.6.4-8.1 6-15.6 13.4l-12.9 12.6-12.2-12.1c-6.7-6.6-13.2-12.6-14.4-13.2-3.4-1.7-10.4-1.3-14 .9zM270.2 237c-1.8 1.1-4.1 3.4-5.2 5.2-4 6.6-1.6 15.6 5.2 19.8 3.2 1.9 5.2 2 49.6 2 40.5 0 46.8-.2 49.8-1.6 9.9-4.7 9.9-21.1 0-25.8-3-1.4-9.3-1.6-49.8-1.6-44.4 0-46.4.1-49.6 2zM271 283.8c-9.5 4.7-10.7 16.5-2.5 24.2l2.7 2.5 47.9.3c53 .3 52 .4 55.9-6.1 3-4.9 2.7-12.7-.6-16.7-5.2-6.1-3.9-6-54.1-6-41.6 0-46.1.2-49.3 1.8z" />
  </svg>
)

export default GuideLinesIcon
