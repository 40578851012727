import React from "react";
import CampaignIcon from './CampaignIcon';
import CalendarIcon from "./CalendarIcon";
import BrandIcon from "./BrandIcon";
import DownloadIcon from "./DownloadIcon";
import GuideLinesIcon from "./GuideLinesIcon";
import LogOutIcon from "./LogOutIcon";
import ProfileIcon from "./ProfileIcon";
import StudioIcon from "./StudioIcon";
import ExploreIcon from "./ExploreIcon";
import CartIcon from "./CartIcon";
import LogInIcon from "./LogInIcon";
import CrownIcon from "./CrownIcon";
import GlobalIcon from "./GlobalIcon";
import SignUpIcon from "./SignUpIcon";

const componentsIcons = {
  campaignIcon : CampaignIcon,
  calendarIcon : CalendarIcon,
  brandIcon : BrandIcon, 
  downloadIcon: DownloadIcon,
  guideLinesIcon : GuideLinesIcon,
  logOutIcon : LogOutIcon,
  profileIcon : ProfileIcon,
  studioIcon : StudioIcon,
  exploreIcon : ExploreIcon,
  cartIcon : CartIcon,
  logInIcon: LogInIcon,
  signUpIcon:SignUpIcon,
  CrownIcon: CrownIcon,
  GlobalIcon:GlobalIcon
}

const IconComponent  = ({ name, ...props }) => {
   
  var SpecificIcon = componentsIcons[name];
  return <SpecificIcon {...props} />;
};

export default IconComponent ;
