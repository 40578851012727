export const GET_PHOTOHRAPHERS = 'GET_PHOTOHRAPHERS';
export const GET_PHOTOHRAPHER = 'GET_PHOTOHRAPHER';
export const PHOTOHRAPHER_ERROR = 'PHOTOHRAPHER_ERROR';
export const GET_MORE_PHOTOHRAPHERS = 'GET_MORE_PHOTOHRAPHERS';
export const FOLLOW_PHOTOHRAPHER = 'FOLLOW_PHOTOHRAPHER';
export const UNFOLLOW_PHOTOHRAPHER = 'UNFOLLOW_PHOTOHRAPHER';
export const GET_PHOTOGRAPHER_PHOTOS = 'GET_PHOTOGRAPHER_PHOTOS';
export const GET_MORE_PHOTOGRAPHER_PHOTOS = 'GET_MORE_PHOTOGRAPHER_PHOTOS';
export const RESET_FOLLOW = 'RESET_FOLLOW'
export const ROOM_LOADED = 'ROOM_LOADED'
export const GET_MORE_ROOM_PHOTOS = 'GET_MORE_ROOM_PHOTOS'
export const ROOM_ERROR = 'ROOM_ERROR';



