import React, { useEffect } from 'react';

const UserLayout = ({ children, room }) => {
  useEffect(() => {
    document.body.classList.add(`${room? `room-background`: `background` }`);
    document.body.classList.add('no-footer');

    return () => {
      document.body.classList.remove(`${room? `room-background`: `background` }`);
      document.body.classList.remove('no-footer');
    };
  }, []);

  return (
    <>
      <main>
        <div className={`container ${!room ? `remove-all-margin-padding` : ``
        }`}>{children}</div>
      </main>
    </>
  );
};

UserLayout.defaultProps = {
  remove: true,
};
export default UserLayout;
