import {
  GET_ALBUM,
  GET_ALBUMS,
  ALBUM_ERROR,
  GET_MORE_ALBUMS,
  GET_ALBUM_PHOTOS,
  GET_MORE_ALBUM_PHOTOS
 } from "../../constants/album";
 import {  SET_LOADING, RESET_ALL_LOADED} from '../../constants/shared';

 
export const initialState = {
  loading: false,
 albums:[],
 album:{},
 pagination: {},
 photos:[],
 photosPagination:{},
 allPhotosLoaded:false,
 allLoaded: false
};

export const albumReducer = (state, action) => {
  const { type, payload } = action;
  let copyOfObject = {};
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALBUMS:
      copyOfObject = { ...payload.data };
      delete copyOfObject['data'];
      return {
        ...state,
        albums:payload.data.data,
        pagination:copyOfObject,
        allLoaded:payload.data.current_page === payload.data.last_page,
        loading:false,
      };
      case GET_MORE_ALBUMS:
        copyOfObject = { ...payload.data };
      delete copyOfObject['data'];
      return {
        ...state,
        albums:state.albums ?
        [...state.albums, ...payload.data.data] :
        payload.data.data,
        pagination:copyOfObject,
        allLoaded:payload.data.current_page === payload.data.last_page,
        loading:false,
      };
  case GET_ALBUM:
      return {
        ...state,
        loading: false,
        album:payload
      };
      case GET_ALBUM_PHOTOS:
        copyOfObject = { ...payload.data.data };
        delete copyOfObject['data'];
        return {
          ...state,
          photos:payload.data.data.data,
          photosPagination:copyOfObject,
          allPhotosLoaded:payload.data.data.current_page === payload.data.data.last_page,
          albumId:payload.albumPayload.album_id,
          loading:false
        };
      case GET_MORE_ALBUM_PHOTOS:
        copyOfObject = { ...payload.data.data };
      delete copyOfObject['data'];
      return {
        ...state,
        photos:state.photos ?
        [...state.photos, ...payload.data.data.data] :
        payload.data.data,
        photosPagination:copyOfObject,
        allPhotosLoaded:payload.data.data.current_page === payload.data.data.last_page,
        albumId:payload.albumPayload.album_id,
        loading:false,
      };
    case ALBUM_ERROR:
      return {
        ...state,
        loading: false,
      };
      case RESET_ALL_LOADED:
        return {
          ...state,
          albums:[],
        pagination:{},
        photos:[],
        photosPagination:{},
        loading: false,
      }
    default:
      return state;
  }
};
