// Campaign
export const GET_RUNNING_CAMPAIGNS = 'GET_RUNNING_CAMPAIGNS';
export const GET_DRAFT_CAMPAIGNS = 'GET_DRAFT_CAMPAIGNS';
export const GET_COMPLETED_CAMPAIGNS = 'GET_COMPLETED_CAMPAIGNS';
export const GET_MORE_RUNNING_CAMPAIGNS = 'GET_MORE_RUNNING_CAMPAIGNS';
export const GET_MORE_DRAFT_CAMPAIGNS = 'GET_MORE_DRAFT_CAMPAIGNS';
export const GET_MORE_COMPLETED_CAMPAIGNS = 'GET_MORE_COMPLETED_CAMPAIGNS';
export const CAMPAIGN_UPDATED = 'CAMPAIGN_UPDATED';
export const GET_CAMPAIGN_DETAILS = 'GET_CAMPAIGN_DETAILS';
export const GET_CAMPAIGN_PHOTOS= 'GET_CAMPAIGN_PHOTOS';
export const GET_MORE_CAMPAIGN_PHOTOS= 'GET_MORE_CAMPAIGN_PHOTOS';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const CANCEL_CAMPAIGN = 'CANCEL_CAMPAIGN';
export const EXTEND_CAMPAIGN = 'EXTEND_CAMPAIGN';
export const SET_CAMPAIGN_WINNER = 'SET_CAMPAIGN_WINNER';
export const CAMPAIGN_ERROR = 'CAMPAIGN_ERROR'
export const REST_ERROR = 'REST_ERROR';
export const CAMPAIGN_ADD_ERROR = 'CAMPAIGN_ADD_ERROR';
export const RESET_CAMPAIGNS ='RESET_CAMPAIGNS'