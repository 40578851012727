import CartOutlineIcon from "mdi-react/CartOutlineIcon";
import React, { useEffect } from "react";
  
import {useCartDispatch, useCartState } from "../../contexts/cart/cartContext";
import {useAlertDispatch} from "../../contexts/alert/alertContext"
import {getCart, getPurchasedList,getWonList} from "../../contexts/cart/cartActions";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import DownloadIcon from "../svg/DownloadIcon";
import DownloadBoxOutlineIcon from "mdi-react/DownloadBoxOutlineIcon";
import CartIcon from "../svg/CartIcon";
import { useUserState } from "../../contexts/user/userContext";
import { downloadsClicked, cartClicked} from "../../utils/cleverTapLogger";

const TopbarCart = ({isAuthenticated}) => {
    const { count,purchasedPagination,wonsPagination } = useCartState();
    const dispatch = useCartDispatch();
    const alertDispatch = useAlertDispatch();
    useEffect(() => {
        if (!count)
        {
            getCart(dispatch, alertDispatch,isAuthenticated);

        }
        if (isAuthenticated && 
          (!purchasedPagination || !wonsPagination))
        {
          getPurchasedList(dispatch, alertDispatch,1)
          getWonList(dispatch,alertDispatch,1);
        }
      }, [dispatch, isAuthenticated]);

//     const getCartTotal = (cart) =>
// cart?.reduce((amount, item) => item.after + amount, 0);

    return ( 
      <>
         {isAuthenticated && (
            <>
      <div className="position-relative d-none d-inline-block">
      <Link
            className="header-icon notificationButton btn btn-empty"
            id="downloads"
            title="downloads"
            to={ROUTES.DOWNLOADS}
            onClick={()=>downloadsClicked()}
          >
            {/* <span className="vm header-icon"> <i className="simple-icon-cloud-download"/></span> */}
         {/* <DownloadBoxOutlineIcon size="28"  className="vm"/> */}
         <DownloadIcon width="28" height="28" className="vm" />
         { purchasedPagination && wonsPagination && (purchasedPagination.total > 0 || wonsPagination.total >0) && (
           <span className="count">{purchasedPagination.total + wonsPagination.total}</span>
           )}
        </Link>
        </div>
        </>
        )}
      <div className="position-relative d-none d-inline-block">
      <Link
            className="header-icon notificationButton btn btn-empty"
            id="cart"
            title="Cart"
            to={ROUTES.CART}
            onClick={()=>cartClicked()}
          >
           {/* <CartOutlineIcon size="28" className="vm"/> */}
           <CartIcon width="28" height="28" className="vm" />
           {count > 0 && (
           <span className="count">{count}</span>
           )}
        </Link>
        </div>
        </>
    
     );
}
 
export default TopbarCart;