import React, { createContext, useContext, useReducer } from "react";
import { initialState, alertReducer } from "./alertReducer";

// Prepares the dataLayer
const alertStateContext = createContext();
const alertDispatchContext = createContext();

export function useAlertState() {
	const context = useContext(alertStateContext);
	if (context === undefined) {
		throw new Error('useALertState must be used within alertProvider');
	}

	return context;
}

export function useAlertDispatch() {
	const context = useContext(alertDispatchContext);
	if (context === undefined) {
		throw new Error('useAlertDispatch must be used within alertProvider');
	}

	return context;
}

export const AlertProvider = ({ children }) => {
  const [alert, dispatch] = useReducer(alertReducer, initialState);

  return (
    <alertStateContext.Provider value={alert}>
      <alertDispatchContext.Provider value={dispatch}>
        {children}
      </alertDispatchContext.Provider>
    </alertStateContext.Provider>
  );
};
