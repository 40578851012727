// Explore 
export const GET_MOST_LIKED = 'GET_MOST_LIKED';
export const GET_TOP_RATED = 'GET_TOP_RATED';
export const GET_MORE_TOP_RATED = 'GET_MORE_TOP_RATED';
export const GET_MOST_SELLING = 'GET_MOST_SELLING';
export const GET_MORE_MOST_SELLING = 'GET_MORE_MOST_SELLING';
export const GET_TOP_PICKS = 'GET_TOP_PICKS';
export const GET_MORE_TOP_PICKS = 'GET_MORE_TOP_PICKS';
export const GET_COMMON_INTERESTS = 'GET_COMMON_INTERESTS';
export const GET_MORE_COMMON_INTERESTS = 'GET_MORE_COMMON_INTERESTS';
export const GET_NEW_PHLOGS = 'GET_NEW_PHLOGS';
export const GET_COMMON_TAGS = 'GET_COMMON_TAGS';
export const GET_EDITOR_PICKS= 'GET_EDITOR_PICKS';
export const GET_NEW_UPLOADS= 'GET_NEW_UPLOADS';
export const GET_WINNERS= 'GET_WINNERS';
export const GET_MORE_WINNERS= 'GET_MORE_WINNERS';
export const GET_YOUR_PHLOGGERS= 'GET_YOUR_PHLOGGERS';
export const GET_MORE_YOUR_PHLOGGERS= 'GET_MORE_YOUR_PHLOGGERS';
export const GET_ALBUMS= 'GET_ALBUMS';
export const GET_MORE_ALBUMS= 'GET_MORE_ALBUMS';
export const EXPLORE_ERROR= 'EXPLORE_ERROR';
export const SET_MOST_LIKED_LOADING = "SET_MOST_LIKED_LOADING";
export const SET_TOP_RATED_LOADING = "SET_TOP_RATED_LOADING";
export const SET_MOST_SELLING_LOADING = "SET_MOST_SELLING_LOADING";
export const SET_TOP_PICKS_LOADING = 'SET_TOP_PICKS_LOADING';
export const SET_NEW_PHLOGS_LOADING = 'SET_NEW_PHLOGS_LOADING';
export const SET_COMMON_TAGS_LOADING = 'SET_COMMON_TAGS_LOADING';
export const SET_NEW_UPLOADS_LOADING = 'SET_NEW_UPLOADS_LOADING';
export const SET_EDITOR_PICKS_LOADING = 'SET_EDITOR_PICKS_LOADING';
export const SET_COMMON_INTERESTS_LOADING = 'SET_COMMON_INTERESTS_LOADING';
export const SET_WINNERS_LOADING= 'SET_WINNERS_LOADING';
export const SET_YOUR_PHLOGGERS_LOADING= 'SET_YOUR_PHLOGGERS_LOADING';
export const SET_ALBUMS_LOADING= 'SET_ALBUMS_LOADING';
