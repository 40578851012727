import * as React from "react"

const StatusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M403.5 85.5c-1.7.8-58.1 56.6-125.5 124L155.5 332 103 279.6c-57.3-57.3-55.9-56.1-64.7-53.7-2.6.7-5.3 2.5-7.2 4.6-2.6 3-3.1 4.4-3.1 8.6 0 2.7.6 6.2 1.4 7.7.8 1.5 27.9 29.1 60.3 61.4 55.1 54.9 59.1 58.7 63 59.3 3.1.5 5.3.2 8.5-1.3 5.9-2.7 261.3-258.3 262.4-262.5 1.1-4.8-.2-11.6-2.7-14.4-4.7-5-11.7-6.6-17.4-3.8z" />
  </svg>
)

export default StatusIcon
