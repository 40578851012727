import * as React from "react";

const CampaignIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 344 344"
    fill="currentColor"
    {...props}
  >
    <path d="M116.4 30.1c-8.9 2.6-18.2 12.1-20.9 21.3l-1.6 5.3-12.4.5c-14.2.7-19.2 2.4-24.6 8.5-6.3 7.2-6.4 7.9-6.4 39.8.1 32.6.4 34.5 8.1 46.1C66.3 163.2 82.5 172 96 172c2 0 3 .5 3 1.4 0 .8 2.1 5.6 4.7 10.7 10.6 20.8 32.3 37.1 55.6 41.9l5.7 1.1V251h-15.3c-22.6 0-31.8 3-42.9 14.2-7.5 7.6-11.6 16.2-13 27.6-1.4 10.7-.5 15.6 3.2 19.4l2.8 2.8H251l3.2-2.9c3-2.7 3.3-3.4 3.6-10.2.7-14.4-4.1-27.3-13.7-37-10.9-10.8-20.3-13.9-42.8-13.9H186V227.3l8.8-2.1c23.9-5.9 42.5-20.8 53.3-42.7l4.9-9.9 7.7-1.3c19.7-3.3 34.7-17.1 38.8-35.8 1.4-5.9 1.6-12.1 1.3-33.6-.3-24.9-.4-26.6-2.5-30.5-2.6-4.9-8.3-10.1-13.2-12.1-2.2-.9-8.4-1.7-15.8-2.1l-12.2-.5-.7-3.1c-1.8-8.5-8.8-17.2-17.4-21.7l-4.5-2.4-57.5-.2c-32.9-.1-58.8.2-60.6.8zM231.7 52c5.4 3.2 5.5 4.7 5.1 57.5l-.3 48-3.1 8.5c-7.6 21.4-24.5 36.2-46.3 40.6-10.8 2.1-20.5 1.4-31.5-2.4-14.8-5.1-26.8-15.3-33.9-29-6.6-12.6-6.7-13.4-6.7-68.8.1-53-.1-51.2 5-54.7 2.2-1.5 7.1-1.7 55.5-1.7 50.8 0 53.1.1 56.2 2zM93.2 114.2c.2 19.4.2 35.5.1 35.7-.6 1.2-10.7-3.6-13.8-6.6-7.1-6.9-7.5-8.6-7.5-37.4 0-14.1.3-25.9.7-26.2.3-.4 5-.7 10.4-.7h9.8l.3 35.2zM279 80.9c1.1 2.1 1.4 42 .4 48.4-1.5 9-10.4 18.4-18.9 20.1l-3 .6.3-35.5.2-35.5h10c8.8 0 10 .2 11 1.9zm-60.6 192.5c8.3 2.5 15.1 9.7 17 17.8l.6 2.8H114.9l.7-2.3c2.7-9 8.9-15.9 16.3-18.1 6.7-2.1 79.7-2.2 86.5-.2z" />
  </svg>
);

export default CampaignIcon;
