import {
  GET_CAMPAIGN_DETAILS,
  GET_CAMPAIGN_PHOTOS,
  GET_COMPLETED_CAMPAIGNS,
  GET_DRAFT_CAMPAIGNS,
  GET_MORE_CAMPAIGN_PHOTOS,
  GET_MORE_COMPLETED_CAMPAIGNS,
  GET_MORE_DRAFT_CAMPAIGNS,
  GET_MORE_RUNNING_CAMPAIGNS,
  GET_RUNNING_CAMPAIGNS,
  CAMPAIGN_ADD_ERROR,
  CAMPAIGN_ERROR,
  CAMPAIGN_UPDATED,
  ADD_CAMPAIGN,
  CANCEL_CAMPAIGN,
  SET_CAMPAIGN_WINNER,
  EXTEND_CAMPAIGN,
  REST_ERROR,
  RESET_CAMPAIGNS,
} from "../../constants/campaign";
import { SET_LOADING, RESET_ALL_LOADED } from "../../constants/shared";

export const initialState = {
  loading: false,
  runningCampaigns: null,
  completedCampaigns: null,
  draftCampaigns: null,
  campaign: {},
  paginations: {},
  count: null,
  photos: [],
  photosPagination: {},
  allPhotosLoaded: false,
};

export const campaignReducer = (state, action) => {
  const { type, payload, error } = action;
  let copyOfObject = {};
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DRAFT_CAMPAIGNS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        draftCampaigns: payload.data.data,
        draftPagination: copyOfObject,
        allDraftLoaded: payload.data.current_page === payload.data.last_page,
        loading: false,
      };

    case GET_MORE_DRAFT_CAMPAIGNS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        draftCampaigns: state.draftCampaigns
          ? [...state.draftCampaigns, ...payload.data.data]
          : payload.data.data,
        draftPagination: copyOfObject,
        allDraftLoaded: payload.data.current_page === payload.data.last_page,
        loading: false,
      };
    case GET_RUNNING_CAMPAIGNS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        runningCampaigns: payload.data.data,
        runningPagination: copyOfObject,
        allRunningLoaded: payload.data.current_page === payload.data.last_page,
        loading: false,
      };

    case GET_MORE_RUNNING_CAMPAIGNS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        runningCampaigns: state.runningCampaigns
          ? [...state.runningCampaigns, ...payload.data.data]
          : payload.data.data,
        runningPagination: copyOfObject,
        allRunningLoaded: payload.data.current_page === payload.data.last_page,
        loading: false,
      };
    case GET_COMPLETED_CAMPAIGNS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        completedCampaigns: payload.data.data,
        completedPagination: copyOfObject,
        allCompletedLoaded:
          payload.data.current_page === payload.data.last_page,
        loading: false,
      };

    case GET_MORE_COMPLETED_CAMPAIGNS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        completedCampaigns: state.completedCampaigns
          ? [...state.completedCampaigns, ...payload.data.data]
          : payload.data.data,
        completedPagination: copyOfObject,
        allCompletedLoaded:
          payload.data.current_page === payload.data.last_page,
        loading: false,
      };
    case GET_CAMPAIGN_PHOTOS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        photos: payload.data.data,
        photosPagination: copyOfObject,
        allPhotosLoaded: payload.data.current_page === payload.data.last_page,
        loading: false,
      };
    case GET_MORE_CAMPAIGN_PHOTOS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        photos: state.photos
          ? [...state.photos, ...payload.data.data]
          : payload.data.data,
        photosPagination: copyOfObject,
        allPhotosLoaded: payload.data.current_page === payload.data.last_page,
        loading: false,
      };
    case GET_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaign: payload.data,
        loading: false,
      };
    case EXTEND_CAMPAIGN:
      state.campaign.end_date = payload;
      return {
        ...state,
        loading: false,
      };
    case ADD_CAMPAIGN:
      return {
        ...state,
        loading: false,
      };
    case SET_CAMPAIGN_WINNER:
      return {
        ...state,
        loading: false,
      };
    case CANCEL_CAMPAIGN:
      const newCampaigns = [...state.draftCampaigns];
      const index = newCampaigns.findIndex(
        (item) => item.id == payload.campaign_id
      );
      newCampaigns.splice(index, 1);
      return {
        ...state,
        draftCampaigns: newCampaigns,
        loading: false,
      };

    case CAMPAIGN_ERROR:
      if (action.error?.find((err) => err.code === 1404)) state.campaign = null;
      return {
        ...state,
        loading: false,
      };
    case REST_ERROR:
      return {
        ...state,
        errorMessage: "",
        addErrorMessage: error,
      };
    case CAMPAIGN_ADD_ERROR:
      return {
        ...state,
        loading: false,
        addErrorMessage: error,
      };
    case RESET_ALL_LOADED:
      return {
        ...state,
        photos: [],
        photosPagination: {},
        allPhotosLoaded: false,
      };
    case RESET_CAMPAIGNS:
      return {
        ...state,
        loading: false,
        runningCampaigns: null,
        completedCampaigns: null,
        draftCampaigns: null,
        campaign: {},
        paginations: {},
        count: null,
        photos: [],
        photosPagination: {},
        allPhotosLoaded: false,
      };
    default:
      return state;
  }
};
