import React, { createContext, useContext, useReducer } from "react";
import { initialState, albumReducer } from "./albumReducer";

// Prepares the dataLayer
const albumStateContext = createContext();
const albumDispatchContext = createContext();

export function useAlbumState() {
	const context = useContext(albumStateContext);
	if (context === undefined) {
		throw new Error('usealbumState must be used within a albumProvider');
	}

	return context;
}

export function useAlbumDispatch() {
	const context = useContext(albumDispatchContext);
	if (context === undefined) {
		throw new Error('usealbumDispatch must be used within a albumProvider');
	}

	return context;
}

export const AlbumProvider = ({ children }) => {
  const [album, dispatch] = useReducer(albumReducer, initialState);

  return (
    <albumStateContext.Provider value={album}>
      <albumDispatchContext.Provider value={dispatch}>
        {children}
      </albumDispatchContext.Provider>
    </albumStateContext.Provider>
  );
};
