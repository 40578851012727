import {
SET_UPLOAD_PROGRESS,
SUCCESS_UPLOAD_FILE,
FAILURE_UPLOAD_FILE
 } from "../../constants/upload";
 import {  SET_LOADING, RESET_ALL_LOADED} from '../../constants/shared';

 
export const initialState = {
  fileProgress: {
    // format will be like below
    // 1: {
    //   id: 1,
    //   file,
    //   progress: 0,
    //   status: 0,
    // },
  },
//   loading: false,
//  progress: 0,
//  errorMessage:"",
};

export const uploadReducer = (state, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
   case SET_UPLOAD_PROGRESS:
     return{
        ...state,
    fileProgress: {
      ...state.fileProgress,
      [payload.id]: {
        ...state.fileProgress[payload.id],
        progress: payload.progress,
      },
    },
      //  ...state,
      //  progress:payload
     }
     case SUCCESS_UPLOAD_FILE:
       return{
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [payload]: {
            ...state.fileProgress[payload],
            status: 1,
          },
        },
        //  ...state,
        //  loading:false,
       }
    case FAILURE_UPLOAD_FILE:
      return {
        ...state,
    fileProgress: {
      ...state.fileProgress,
      [payload]: {
        ...state.fileProgress[payload],
        status: 0,
        progress: 0,
      },
    },
        // ...state,
        // loading: false,
        // errorMessage: error,
      };
   
    default:
      return state;
  }
};
