import React, { Fragment } from "react";
const MenuIcon = () => {
  return (
    <Fragment>
      <svg
        className="main"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 17"
      >
        <rect x="0.48" y="0.5" width="7" height="2" />
        <rect x="0.48" y="7.5" width="7" height="2" />
        <rect x="0.48" y="15.5" width="7" height="2" />
      </svg>
      <svg
        className="sub"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 17"
      >
        <rect x="1.56" y="0.5" width="18" height="2" />
        <rect x="1.56" y="7.5" width="18" height="2" />
        <rect x="1.56" y="15.5" width="18" height="2" />
      </svg>
    </Fragment>
  );
};

export default MenuIcon;
