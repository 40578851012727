import * as React from "react"

const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M186.2 39c-3.4 1.5-4.7 2.9-6.3 6.6-1.2 2.5-1.9 5.9-1.7 7.4l.4 2.8-26 .4c-23.9.4-26.6.6-32.1 2.6-14 5.2-20.4 11.5-36.6 35.8C72.4 111.9 71.5 113 68.6 113c-4.1 0-9.1 3.2-11.1 6.9-2.1 4.1-1.9 10.9.5 14.8 3.1 5.1 6.3 6.6 15 7.1l7.9.5.6 4.1c.3 2.2 5.7 52.2 12 111.1 12.2 114 12.1 113.5 18.6 123.6 3.9 6.2 13.8 15.3 19.7 18.3 11.4 5.8 8.8 5.6 94.2 5.6s82.8.1 94.1-5.6c5.7-2.8 15-11.2 19-17 4.4-6.4 7.5-15.7 8.8-25.9.6-5 5.8-53.3 11.6-107.5 5.7-54.2 10.7-100.4 11-102.6l.6-4.1 7.9-.5c5.1-.3 8.8-1.1 10.5-2.2 11.3-7.4 6.8-26.6-6.3-26.6-2.7 0-3.9-1.6-15.1-18.4-16.2-24.3-22.6-30.6-36.6-35.8-5.5-2-8.2-2.2-32.1-2.6l-26-.4.4-2.8c.2-1.5-.5-4.9-1.7-7.4-1.6-3.7-2.9-5.1-6.3-6.6-4-1.8-6.7-2-39.8-2-33.1 0-35.8.2-39.8 2zm133.3 47.1c6.8 1.8 10.4 5 17.6 15.6 3.8 5.6 6.9 10.5 6.9 10.8 0 .3-53.1.5-118 .5s-118-.2-118-.5c0-1.6 14.3-21.3 16.6-22.9 6.3-4.5 6.2-4.5 100.9-4.5 61.5-.1 91.3.3 94 1zm21.5 58.3c0 4.7-22 208.3-23 213.3-1.5 7.1-5.7 12.5-12.1 15.7l-5.3 2.6H151.4l-5.3-2.6c-6.4-3.2-10.6-8.6-12.1-15.7-1-5-23-208.6-23-213.3V142h230v2.4z" />
  </svg>
)

export default DeleteIcon
