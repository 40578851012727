import axios from "../../utils/axios";
import {
  GET_STUDIOS,
  GET_STUDIO_PHOTOS,
  GET_MORE_STUDIO_PHOTOS,
  ADD_STUDIO,
  REMOVE_STUDIO,
  ADD_PHOTO_TO_STUDIO,
  REMOVE_PHOTO_FROM_STUDIO,
  STUDIO_ERROR,
  REST_ERROR,
  STUDIO_ADD_ERROR,
   } from "../../constants/studio";
import { SET_LOADING, RESET_ALL_LOADED } from "../../constants/shared";

import setAlert from "../../contexts/alert/alertActions";
import {addToStudio} from "../../utils/cleverTapLogger"

// Set Loading
const setLoading = (dispatch) => dispatch({ type: SET_LOADING });

//Add to studio
export const addPhotoToStudios = async (
  dispatch,
  alertDispatch,
  studioPayload,
  eventPayload,
  selectedStudios
) => {
  setLoading(dispatch);

  try {
    const res = await axios.post(
      `/api/business/lightBox/photo/save`,
      studioPayload
    );
      dispatch({
        type: ADD_PHOTO_TO_STUDIO,
        payload: res.data,
      });

      selectedStudios.forEach((studio) => {
        eventPayload.studioName = studio.name ;
        addToStudio(eventPayload);
      });
      return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: STUDIO_ERROR, error: errMsg.find(Boolean).message });
      //setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: STUDIO_ERROR,
        error: "Error, please try again",
      });
      setAlert(alertDispatch, ["Error, please try again"], "error");
    }
    return false;
  }
};

// Get Studios
export async function getStudios(dispatch, alertDispatch) {
  setLoading(dispatch);

  try {
    let response = await axios.post(
      "/api/business/lightBox/all"
    );

    dispatch({
      type: GET_STUDIOS,
      payload: response.data.data,
    });
    return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: STUDIO_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: STUDIO_ERROR,
        error: "Error, please try again",
      });
      setAlert(alertDispatch, ["Error, please try again"], "error");
    }
  }
}

//Add new studio
export const addNewStudio = async (
  dispatch,
  alertDispatch,
  studioPayload,
) => {
  setLoading(dispatch);

  try {
    await axios.post(
      `/api/business/lightBox/add`,
      studioPayload
    );

    getStudios(dispatch, alertDispatch);
      return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: STUDIO_ADD_ERROR, error: errMsg.find(Boolean).message });
      //setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: STUDIO_ADD_ERROR,
        error: "Error, please try again",
      });
      setAlert(alertDispatch, ["Error, please try again"], "error");
    }
    return false;
  }
};

//Delete studio
export const deleteStudio = async (
  dispatch,
  alertDispatch,
  studioPayload,
) => {
  setLoading(dispatch);

  try {
    await axios.post(
      `/api/business/lightBox/delete`,
      studioPayload
    );

    dispatch({
      type: REMOVE_STUDIO,
      payload: studioPayload,
    });
      return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: STUDIO_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: STUDIO_ERROR,
        error: "Error, please try again",
      });
      setAlert(alertDispatch, ["Error, please try again"], "error");
    }
    return false;
  }
};

//Get studio photos
export const getStudioPhotos = async (
  dispatch,
  alertDispatch,
  page,
  studioPayload,
  loadMore,
) => {
  setLoading(dispatch);

  try {
    const res = await axios.post(
      `/api/business/lightBox/photos?page=${page}`,
      studioPayload
    );

    if (loadMore)
    {
      dispatch({
        type: GET_MORE_STUDIO_PHOTOS,
        payload: res.data,
      });
    }
    else
    {
      dispatch({
        type: GET_STUDIO_PHOTOS,
        payload: res.data,
      });
    }
      return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: STUDIO_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: STUDIO_ERROR,
        error: "Error, please try again",
      });
      setAlert(alertDispatch, ["Error, please try again"], "error");
    }
    return false;
  }
};

//Remove photo from studio
export const RemovePhotoFromStudio = async (
  dispatch,
  alertDispatch,
  studioPayload,
  history,
  nextPhotoId
) => {
  setLoading(dispatch);

  try {
    const res = await axios.post(
      `/api/business/lightBox/photo/delete`,
      studioPayload
    );
      dispatch({
        type: REMOVE_PHOTO_FROM_STUDIO,
        payload: studioPayload,
      });
      // if (history) {
      //   history.push(`/studio/${studioPayload.lightbox_id}/photo/${nextPhotoId}`);
      // }
      return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: STUDIO_ERROR, error: errMsg});
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: STUDIO_ERROR,
        error: "Error, please try again",
      });
      setAlert(alertDispatch, ["Error, please try again"], "error");
    }
    return false;
  }
};
export const resetStudio = (dispatch) => {
  dispatch({ type: RESET_ALL_LOADED });
};

export const resetError = (dispatch)=>
{
  dispatch({type: REST_ERROR});
}