import axios from "../../utils/axios";
import {
  GET_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_AS_EXCLUSIVE,
  RESET_CART,
  CART_ERROR,
  GET_DOWNLOADS,
  GET_WONS,
  GET_MORE_DOWNLOADS,
  GET_MORE_WONS,
  ADD_TO_CART_GUEST,
  GET_GUEST_CART,
  REMOVE_FROM_CART_GUEST
} from "../../constants/cart";
import {
  ADDED_TO_CART
} from "../../constants/photo"

import { SET_LOADING,RESET_ALL_LOADED } from "../../constants/shared";
import setAlert from "../../contexts/alert/alertActions";
import { decryptData, encryptData } from "../../utils/cryptoUtil";
import {addToCart as addToCartEvent} from "../../utils/cleverTapLogger";

// Set Loading
const setLoading = (dispatch) => dispatch({ type: SET_LOADING });

// Get Cart
export const getCart = async (dispatch, alertDispatch,isAuthenticated) => {
  setLoading(dispatch);
  let response;
  try {
    if (isAuthenticated)
    {
    response = await axios.post("/api/business/cart/list");
   
  }
  else{
    const cart= localStorage.getItem("cartList") !== null
    ? JSON.parse(localStorage.getItem("cartList"))
    : [];
     response = await axios.post("/api/business/cart/list",{cart_photos_ids:cart});
    }
  dispatch({
    type: GET_CART,
    payload: response.data,
  });
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: CART_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: CART_ERROR,
        error: "Cart failed please try again",
      });
      setAlert(alertDispatch, ["Cart failed please try again"], "error");
    }
    
  }
};

// Reset Cart
export const resetCart = (dispatch) => dispatch({ type: RESET_CART });

// Add to Cart
export const addToCart = async (dispatch, alertDispatch,photoDispatch, cartPayload, isAuthenticated, eventPayload) => {
  setLoading(dispatch);
  try {
    let response ;
    if (isAuthenticated)
    {
     response = await axios.post("/api/business/cart/add", cartPayload);
    dispatch({
      type: ADD_TO_CART,
      payload: response.data,
    });
    addToCartEvent({photoId:eventPayload.photoId ,
      clickedPhotographerName:eventPayload.clickedPhotographerName,
      clickedPhotographerId:eventPayload.clickedPhotographerId,
    dimensions:eventPayload.dimensions,
  dpi:eventPayload.dpi,
size:eventPayload.size});
    photoDispatch({
      type:ADDED_TO_CART,
      payload: response.data,
    })
  }
  else{
    dispatch({
      type: ADD_TO_CART_GUEST,
      payload: cartPayload,
    });
    photoDispatch({
      type:ADDED_TO_CART,
      payload: cartPayload,
    })
    addToCartEvent({photoId:eventPayload.photoId ,
      clickedPhotographerName:eventPayload.clickedPhotographerName,
      clickedPhotographerId:eventPayload.clickedPhotographerId,
    dimensions:eventPayload.dimensions,
  dpi:eventPayload.dpi,
size:eventPayload.size});
  }
  }  catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: CART_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: CART_ERROR,
        error: "Cart failed please try again",
      });
      setAlert(alertDispatch, ["Cart failed please try again"], "error");
    }
};
};

// Remove from cart
export const removeFromCart = async (dispatch, alertDispatch,cartPayload, isAuthenticated) => {
  setLoading(dispatch);
  try {
    if (isAuthenticated)
    {
    let response = await axios.post("/api/business/cart/remove", cartPayload);
    dispatch({
      type: REMOVE_FROM_CART,
      payload: cartPayload,
    });
  }
  else{
    dispatch({
      type: REMOVE_FROM_CART_GUEST,
      payload: cartPayload,
    });
  }
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: CART_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: CART_ERROR,
        error: "Cart failed please try again",
      });
      setAlert(alertDispatch, ["Cart failed please try again"], "error");
    }
};

};

// set photo as exclusive in cart
export const setExclusive = async (dispatch, alertDispatch,cartPayload) => {
  setLoading(dispatch);
  try {
    let response = await axios.post("/api/business/cart/mark-as-exclusive", cartPayload);
    dispatch({
      type: SET_AS_EXCLUSIVE,
      payload: cartPayload,
    });
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: CART_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: CART_ERROR,
        error: "Cart failed please try again",
      });
      setAlert(alertDispatch, ["Cart failed please try again"], "error");
    }
};
};

// Get purchased list
export const getPurchasedList = async (dispatch, alertDispatch,page,
  loadMore = false) => {
  setLoading(dispatch);
  try {
    let response = await axios.post(`/api/business/photo/purchased/list?page=${page}`);
    if (loadMore)
    {
      dispatch({
        type: GET_MORE_DOWNLOADS,
        payload: response.data,
      });
    }
    else
    {
    dispatch({
      type: GET_DOWNLOADS,
      payload: response.data,
    });
  }
  return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: CART_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: CART_ERROR,
        error: "Downloads failed please try again",
      });
      setAlert(alertDispatch, ["Downloads failed please try again"], "error");
    }
};
};

// Get purchased list
export const getWonList = async (dispatch, alertDispatch,page,
  loadMore = false) => {
  setLoading(dispatch);
  try {
    let response = await axios.post(`/api/business/photo/won/list?page=${page}`);
    if (loadMore)
    {
      dispatch({
        type: GET_MORE_WONS,
        payload: response.data,
      });
    }
    else
    {
    dispatch({
      type: GET_WONS,
      payload: response.data,
    });
  }
  return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: CART_ERROR, error: errMsg });
      setAlert(alertDispatch, errMsg, "error");
    } else {
      dispatch({
        type: CART_ERROR,
        error: "Downloads failed please try again",
      });
      setAlert(alertDispatch, ["Downloads failed please try again"], "error");
    }
};
};

// Download image
export const downloadPhoto = async (alertDispatch, payload) => {
  //setLoading(dispatch);
  return new Promise(async(resolve, reject) => {
  try {
   const downloadHash = encryptData(JSON.stringify(payload), process.env.REACT_APP_SALT, process.env.REACT_APP_IV);
    //let response =await axios.get(`/api/photo/[${downloadHash}]/download`,{'responseType': 'blob'});
    let response =await axios.get(`/photo/[${downloadHash}]/get-download-url`);
    let url = decryptData(response.data.url, process.env.REACT_APP_SALT, process.env.REACT_APP_IV)
   resolve(url);
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      setAlert(alertDispatch, errMsg, "error");
    }
    else
      setAlert(alertDispatch, ["Download failed please try again"], "error");
      reject(err);
    }
  })
};