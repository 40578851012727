import * as React from "react"

const SignUpIcon = (props) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="1em"
  height="1em"
  viewBox="0 0 24 24"
  {...props}
>
  <g fill="currentColor">
    {/* <path d="M8.668 21H4c-1.105 0-2.014-.905-1.77-1.982C2.882 16.13 4.963 13 9 13c4.037 0 6.117 3.13 6.77 6.018C16.012 20.095 15.106 21 14 21zM16 11h6m-3-3v6M8.904 9a3 3 0 100-6 3 3 0 000 6z" /> */}
    <path d="M6.75 7a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0zM10 2.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5zM4.314 18.402a6.251 6.251 0 0 1 11.372 0c.208.455.114.87-.187 1.215-.32.365-.873.633-1.499.633H6c-.626 0-1.18-.268-1.5-.633-.3-.344-.394-.76-.187-1.215zM10 13.25a7.751 7.751 0 0 0-7.05 4.528c-.482 1.053-.22 2.091.421 2.826.623.712 1.599 1.146 2.629 1.146h8c1.03 0 2.006-.434 2.629-1.146.642-.735.903-1.773.421-2.826A7.751 7.751 0 0 0 10 13.25zm8-5a.75.75 0 0 1 .75.75v2.25H21a.75.75 0 0 1 0 1.5h-2.25V15a.75.75 0 0 1-1.5 0v-2.25H15a.75.75 0 0 1 0-1.5h2.25V9a.75.75 0 0 1 .75-.75z"/>
   </g>
</svg>
)

export default SignUpIcon
