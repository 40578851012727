import * as React from "react"

const SendIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M209.8 137.7c-189.4 94.8-176.7 87.9-177.1 97-.3 8.2.3 8.8 35.2 33.7l32.6 23.4 13.8 41.5c14.6 43.7 15.2 45.1 22 47.6 8.7 3.3 12.7.7 25.6-16.5l10.8-14.3 47.3 34.5 47.4 34.4h5.8c5 0 6.2-.4 9.2-3 3.3-3 5.7-9.9 61-175.8 31.7-95 57.6-173.6 57.6-174.6 0-1.1-.9-3.9-2.1-6.2-2.2-4.6-8.2-8.4-13.2-8.4-1.6 0-75.5 36.5-175.9 86.7zm56.2 5.7c-4.1 3.8-152.6 119.9-153.5 119.9-.5 0-9.1-5.7-18.9-12.8l-17.9-12.8 95.4-47.8c52.5-26.3 95.6-47.8 95.9-47.8.2-.1-.2.5-1 1.3zm39.2 118.4-39.7 119.3-37.3-27.1-37.3-27.1 2.3-2.8c1.3-1.5 35.8-43.1 76.7-92.4 40.9-49.4 74.5-89.6 74.7-89.4.2.2-17.5 54-39.4 119.5zm-58.4-48.6c-21.6 26-50 60.3-63.3 76.3-13.2 15.9-27 32.9-30.6 37.6l-6.5 8.7-7.9-23.9c-7.9-23.7-7.9-23.9-6-25.8C135 283.7 285 166 285.6 166c.2 0-17.3 21.3-38.8 47.2z" />
  </svg>
)

export default SendIcon
