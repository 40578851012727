export const loginClickedWelcome = {id: 1, e: 'Login_clicked_welcome'};
export const signupClickedWelcome = {id: 2, e: 'signup_clicked_welcome'};
export const loginClicked = {id: 3, e: 'login_clicked'};
export const forgotPassword = {id: 4, e: 'forgot_password'};
export const signupClicked = {id: 5, e: 'signup_clicked'};
export const verificationSuccessful = {id: 6, e: 'verification_successful'};
export const loginSuccessful = {id: 7, e: 'login_successful'};
export const exploreButtonClicked = {id: 8, e: 'explore_button_clicked'};
export const campaignButtonClicked = {id: 9, e: 'campaign_button_clicked'};
export const downloadsClicked = {id: 10, e: 'downloads_clicked'};
export const cartClicked = {id: 11, e: 'cart_clicked'};
export const profileButtonClicked = {id: 12, e: 'profile_button_clicked'};
export const photoClicked = {id: 13, e: 'photo_clicked'};
export const photoLiked = {id: 14, e: 'photo_liked'};
export const photoComment = {id: 15, e: 'photo_comment'};
export const addToStudio = {id: 16, e: 'add_to_studio'};
export const addToCart = {id: 17, e: 'add_to_cart'};
export const photographerClicked = {id: 18, e: 'photographer_clicked'};
export const followClicked = {id: 19, e: 'follow_clicked'};
export const viewAllClicked = {id: 20, e: 'view_all_clicked'};
export const pageRefreshed = {id: 21, e: 'page_refreshed'};
export const searchBar = {id: 22, e: 'Search_bar'};
export const policyClicked = {id: 23, e: 'policy_clicked'};
export const contactUs = {id: 24, e: 'contact_us'};
export const aboutUs = {id: 25, e: 'about_us'};
export const logout = {id: 26, e: 'logout'};
export const deleteAccount = {id: 27, e: 'delete_account'};
export const deleteAccountConfirm = {id: 28, e: 'delete_account_confirm'};
export const myStudiosClicked = {id: 29, e: 'my_studios_clicked'};
export const studioClicked = {id: 30, e: 'studio_clicked'};
export const setUpBrandClicked = {id: 31, e: 'set_up_brand_clicked'};
export const accountDetailsClicked = {id: 32, e: 'account_details_clicked'};
export const checkOutNowClicked = {id: 33, e: 'check_out_now_clicked'};
export const proceedToPaymentClicked = {id: 34, e: 'proceed_to_payment_clicked'};
export const paymentSuccessful = {id: 35, e: 'payment_successful'};
export const downloadClicked = {id: 36, e: 'download_clicked'};
export const requestCampaign = {id: 37, e: 'request_campaign'};
export const draftCampaign = {id: 38, e: 'draft_campaign'};
export const userAlerted = {id: 39, e: 'user_alerted'};
export const appCrashed = {id: 40, e: 'app_crashed'};
export const applicationBackgrounded = {id: 41, e: 'application_backgrounded'};
export const dimScreen = {id: 42, e: 'dim_screen'};