import React, { useEffect } from 'react';

const LandigLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('landing-bodybg');
    document.body.classList.add('no-footer');

    return () => {
      document.body.classList.remove('background');
      document.body.classList.remove('no-footer');
    };
  }, []);

  return (
    <div>
      {/* overlay-background */}
      {/* <div className="landing-background" />
      
      <div className="card-img-overlay landing-overlay" />
      <main>
        <div className="container">{children}</div>
      </main> */}
   {children}
    </div>
  );
};

export default LandigLayout;
