import * as ROUTES from "./routes";

const data = [{
    id: "home",
    //icon: "home",
    icon:"exploreIcon",
    label: "Explore",
    to: ROUTES.HOME,
    userOnly:false,
    onEventClick : "exploreButtonClicked",
  },
  {
    id: "campaigns",
    //simple: true,
    //icon: "simple-icon-trophy",
    icon:"campaignIcon",
    label: "Campaigns",
    to: ROUTES.CAMPAIGNS,
    userOnly:true,
    onEventClick : "campaignButtonClicked",
    // subs: [{
    //     icon: "camera",
    //     label: "Campaigns",
    //     to: ROUTES.CAMPAIGNS,
    // },
    // {
    // icon: "camera",
    // label: "Campaigns",
    // to: ROUTES.CAMPAIGNS,
    // }]
    // subs: [{
    //     icon: "simple-icon-logout",
    //     label: "menu.types",
    //     to: "/app/menu/types"
    //   },
    //   {
    //     icon: "simple-icon-layers",
    //     label: "menu.levels",
    //     to: "/app/menu/levels",
    //     subs: [{
    //         icon: "simple-icon-arrow-right",
    //         label: "menu.third-level-1",
    //         to: "/app/menu/levels/third-level-1"
    //       },
    //       {
    //         icon: "simple-icon-arrow-right",
    //         label: "menu.third-level-2",
    //         to: "/app/menu/levels/third-level-2"
    //       },
    //       {
    //         icon: "simple-icon-arrow-right",
    //         label: "menu.third-level-3",
    //         to: "/app/menu/levels/third-level-3"
    //       }
    //     ]
    //   }
    // ]
  },
  {
    id: "profile",
    //simple:true,
    //icon: "simple-icon-user",
    icon:"profileIcon",
    label: "Profile",
    to: ROUTES.PROFILE,
    userOnly:true,
    onEventClick : "profileButtonClicked",
    
  },
  {
    id: "search",
    simple:true,
    icon: "simple-icon-magnifier",
    label: "Search",
    to: ROUTES.SEARCHBASE,
    userOnly:false
    //newWindow: true
  }
  ,
  {
    id: "studios",
    //simple:true,
    //icon: "simple-icon-social-dropbox",
    icon:"studioIcon",
    label: "Studios",
    to: ROUTES.STUDIOS,
    userOnly:true,
    onEventClick : "myStudiosClicked",
    //newWindow: true
  }
];
export default data;