import axios from "../../utils/axios";
import {
  GET_NOTIFICATIONS,
  GET_MORE_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
  NOTIFICATION_ERROR
} from "../../constants/notification";
import { SET_LOADING, RESET_ALL_LOADED } from "../../constants/shared";

import setAlert from "../alert/alertActions";

// Set Loading
const setLoading = (dispatch) => dispatch({ type: SET_LOADING });

// Get Notifications
export const getNotifications = async (
  dispatch,
  page,
  loadMore
) => {
  setLoading(dispatch);

  try {
    const res = await axios.post(
      `/api/business/notification/list?page=${page}`
    );
    if (loadMore) {
      dispatch({
        type: GET_MORE_NOTIFICATIONS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data,
      });
    }

    return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: NOTIFICATION_ERROR, error: errMsg });
    } else {
      dispatch({
        type: NOTIFICATION_ERROR,
        error: "notification failed please try again",
      });
    }

    return false;
  }
};

// Set Notification as read
export const setNotificationAsRead = async (
  dispatch,
  notificationPayload,
  unreadCount
) => {
  setLoading(dispatch);

  try {
    const res = await axios.post(
      `/api/business/notification/read`,
      notificationPayload
    );
      dispatch({
        type: SET_NOTIFICATION_READ,
        payload: {data:notificationPayload, count: unreadCount},
      });

    return true;
  } catch (err) {
    if (err.response && err.response.data.errors) {
      let errMsg = err.response.data.errors; //.find(Boolean).message;
      dispatch({ type: NOTIFICATION_ERROR, error: errMsg });
    } else {
      dispatch({
        type: NOTIFICATION_ERROR,
        error: "notification failed please try again",
      });
    }

    return false;
  }
};

export const resetNotification = (dispatch) => {
  dispatch({ type: RESET_ALL_LOADED });
};