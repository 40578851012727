import React, { createContext, useContext, useReducer } from "react";
import { initialState, notificationReducer } from "./notificationReducer";

// Prepares the dataLayer
const notificationStateContext = createContext();
const notificationDispatchContext = createContext();

export function useNotificationState() {
	const context = useContext(notificationStateContext);
	if (context === undefined) {
		throw new Error('usenotificationState must be used within a notificationProvider');
	}

	return context;
}

export function useNotificationDispatch() {
	const context = useContext(notificationDispatchContext);
	if (context === undefined) {
		throw new Error('usenotificationDispatch must be used within a notificationProvider');
	}

	return context;
}

export const NotificationProvider = ({ children }) => {
  const [notification, dispatch] = useReducer(notificationReducer, initialState);

  return (
    <notificationStateContext.Provider value={notification}>
      <notificationDispatchContext.Provider value={dispatch}>
        {children}
      </notificationDispatchContext.Provider>
    </notificationStateContext.Provider>
  );
};
