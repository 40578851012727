import React, { createContext, useContext, useReducer } from "react";
import { initialState, photoReducer } from "./photoReducer";

// Prepares the dataLayer
const photoStateContext = createContext();
const photoDispatchContext = createContext();

export function usePhotoState() {
	const context = useContext(photoStateContext);
	if (context === undefined) {
		throw new Error('usephotoState must be used within a photoProvider');
	}

	return context;
}

export function usePhotoDispatch() {
	const context = useContext(photoDispatchContext);
	if (context === undefined) {
		throw new Error('usephotoDispatch must be used within a photoProvider');
	}

	return context;
}

export const PhotoProvider = ({ children }) => {
  const [photo, dispatch] = useReducer(photoReducer, initialState);

  return (
    <photoStateContext.Provider value={photo}>
      <photoDispatchContext.Provider value={dispatch}>
        {children}
      </photoDispatchContext.Provider>
    </photoStateContext.Provider>
  );
};
