import {
  GET_PHOTO,
  GET_PHOTOS,
  PHOTO_ERROR,
  GET_MORE_PHOTOS,
  FILTERS_LOADED,
  SET_FILTER_LOADING,
  SET_PHOTO_RATING,
  LIKE_PHOTO,
  DISLIKE_PHOTO,
  REPORT_RESASONS_LOADED,
  GET_COMMENTS,
  GET_MORE_COMMENTS,
  GET_COMMENT_CHILDERN,
  GET_MORE_COMMENT_CHILDERN,
  ADD_COMMENT,
  ADDED_TO_CART,
  RESET_COMMENTS,
  DELETE_COMMENT,
  DELETE_COMMENT_CHILD,
  ADD_COMMENT_CHILD,
  RESET_PARENT,
  EDIT_COMMENT,
  EDIT_COMMENT_CHILD,
  COMMENT_REPORT_RESASONS_LOADED,
} from "../../constants/photo";
import { SET_LOADING, RESET_ALL_LOADED } from "../../constants/shared";

export const initialState = {
  loading: false,
  filterLoading: false,
  photos: [],
  photo: null,
  photoNotFound: false,
  filter: ["#"],
  searchKeyWord:"#",
  allFilters: [],
  reportReasons: [],
  pagination: {},
  allLoaded: false,
};

export const photoReducer = (state, action) => {
  const { type, payload } = action;
  let copyOfObject;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_FILTER_LOADING:
      return {
        ...state,
        filterLoading: true,
      };
    case GET_PHOTOS:
      copyOfObject = { ...action.payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        photos: action.payload.data.data,
        filter: action.payload.filter,
        searchKeyWord:action.payload.seachKeyword ,
        pagination: copyOfObject,
        allLoaded:
          action.payload.data.current_page === action.payload.data.last_page,
        loading: false,
      };
    case GET_MORE_PHOTOS:
      const copyOfData = { ...action.payload.data };
      delete copyOfData["data"];
      return {
        ...state,
        photos: state.photos
          ? [...state.photos, ...action.payload.data.data]
          : action.payload.data.data,
        filter: action.payload.filter,
        searchKeyWord:action.payload.seachKeyword,
        pagination: copyOfData,
        allLoaded:
          action.payload.data.current_page === action.payload.data.last_page,
        loading: false,
      };
    case GET_PHOTO:
      //action.payload.data.url = action.payload.data?.url.toString().replace("/thumb/", "/preview/");
      return {
        ...state,
        loading: false,
        photo: action.payload.data,
        photoNotFound: action.payload?.msg === "photo not found",
      };
    case FILTERS_LOADED:
      return {
        ...state,
        allFilters: payload,
        filterLoading: false,
      };

    case GET_COMMENTS:
      copyOfObject = { ...action.payload.data.comments };
      delete copyOfObject["data"];
      return {
        ...state,
        comments: action.payload.data.comments.data,
        mentions: action.payload.data.mentions,
        commentsPagination: copyOfObject,
        commentsAllLoaded:
          action.payload.data.comments.current_page ===
          action.payload.data.comments.last_page,
        loading: false,
      };
    case GET_MORE_COMMENTS:
      copyOfObject = { ...action.payload.data.comments };
      delete copyOfObject["data"];
      if (action.payload.data.mentions.photographers.length > 0) {
        state.mentions.photographers.push(
          ...action.payload.data.mentions.photographers.filter(
            (tabToC) =>
              !state.mentions.photographers.find(
                (finalT) => finalT.id === tabToC.id
              )
          )
        );
      }

      if (action.payload.data.mentions.business.length > 0) {
        state.mentions.business.push(
          ...action.payload.data.mentions.business.filter(
            (tabToC) =>
              !state.mentions.business.find((finalT) => finalT.id === tabToC.id)
          )
        );
      }

      return {
        ...state,
        comments: state.comments
          ? [...state.comments, ...action.payload.data.comments.data]
          : action.payload.data.comments.data,
        commentsPagination: copyOfObject,
        commentsAllLoaded:
          action.payload.data.comments.current_page ===
          action.payload.data.comments.last_page,
        loading: false,
      };
    case ADD_COMMENT:
      if (action.payload.data.mentions.photographers.length > 0) {
        state.mentions.photographers.push(
          ...action.payload.data.mentions.photographers.filter(
            (tabToC) =>
              !state.mentions.photographers.find(
                (finalT) => finalT.id === tabToC.id
              )
          )
        );
      }

      if (action.payload.data.mentions.business.length > 0) {
        state.mentions.business.push(
          ...action.payload.data.mentions.business.filter(
            (tabToC) =>
              !state.mentions.business.find((finalT) => finalT.id === tabToC.id)
          )
        );
      }
      state.comments.push(action.payload.data.comment);
      state.photo.comments_count = state.photo.comments_count + 1;
      return {
        ...state,
      };
    case EDIT_COMMENT:
      const newState = [...state.comments];
      const ind = newState.findIndex(
        (item) => item.id == payload.data.comment.id
      );
      newState[ind] = payload.data.comment;
      if (action.payload.data.mentions.photographers.length > 0) {
        state.mentions.photographers.push(
          ...action.payload.data.mentions.photographers.filter(
            (tabToC) =>
              !state.mentions.photographers.find(
                (finalT) => finalT.id === tabToC.id
              )
          )
        );
      }

      if (action.payload.data.mentions.business.length > 0) {
        state.mentions.business.push(
          ...action.payload.data.mentions.business.filter(
            (tabToC) =>
              !state.mentions.business.find((finalT) => finalT.id === tabToC.id)
          )
        );
      }
      return {
        ...state,
        comments: newState,
        loading: false,
      };
    case EDIT_COMMENT_CHILD:
      return {
        ...state,
        parentId: payload,
        loading: false,
      };
    case DELETE_COMMENT:
      const newComments = [...state.comments];
      const comment_index = newComments.findIndex(
        (item) => item.id == payload.data.comment_id
      );
      newComments.splice(comment_index, 1);
      state.photo.comments_count =
        state.photo.comments_count - (payload.childernCount + 1);
      return {
        ...state,
        comments: newComments,
        loading: false,
      };
    case DELETE_COMMENT_CHILD:
      state.photo.comments_count =
        state.photo.comments_count - (payload.childernCount + 1);
      return {
        ...state,
        parentId: payload.parentId,
        loading: false,
      };
    case ADD_COMMENT_CHILD:
      state.photo.comments_count = state.photo.comments_count + 1;
      return {
        ...state,
        loading: false,
      };
    case GET_COMMENT_CHILDERN:
      let childer = [];
      let parentId = action.payload.data.comments.data[0].parent_id;
      let index = state.comments.findIndex((item) => item.id === parentId);
      state.comments[index].comments = action.payload.data;
      // state.comments[index].mentions = action.payload.data.mentions;
      // state.comments[index].commentsPagination = copyOfObject,
      childer[parentId] = action.payload.data;
      return {
        ...state,
        childern: state.childern ? [...state.childern, ...childer] : childer,
      };

    case GET_MORE_COMMENT_CHILDERN:
      parentId = action.payload.data.comments.data[0].parent_id;
      index = state.comments.findIndex((item) => item.id === parentId);
      state.comments[index].comments.data = state.comments[index].comments
        ? [
            ...state.comments[index].comments,
            ...action.payload.data.comments.data,
          ]
        : action.payload.data.comments.data;

      var ids = new Set(
        state.comments[index].mentions.business.map((d) => d.id)
      );
      state.comments[index].mentions.business = [
        action.payload.data.mentions.business,
        state.comments[index].mentions.business.filter((d) => !ids.has(d.id)),
      ];
      ids = new Set(
        state.comments[index].mentions.photographers.map((d) => d.id)
      );
      state.comments[index].mentions.photographers = [
        action.payload.data.mentions.photographers,
        state.comments[index].mentions.photographers.filter(
          (d) => !ids.has(d.id)
        ),
      ];

      return {
        ...state,
      };
    //Object.assign({}, state, action.payload.data)

    case REPORT_RESASONS_LOADED:
      return {
        ...state,
        reportReasons: payload,
        loading: false,
      };
    case COMMENT_REPORT_RESASONS_LOADED:
      let othersInd = payload.findIndex((item) => item.id === -1);
      if (othersInd === -1) payload.push({ id: -1, system_name: "others" });
      return {
        ...state,
        commentReportReasons: payload,
        loading: false,
      };
    case RESET_ALL_LOADED:
      return {
        ...state,
        photos: [],
        photo: null,
        pagination: {},
      };
    case RESET_COMMENTS:
      return {
        ...state,
        comments: null,
        commentsPagination: {},
        mentions: null,
      };
    case RESET_PARENT:
      return {
        ...state,
        parentId: null,
      };
    case SET_PHOTO_RATING:
      state.photo.is_rated = true;
      state.photo.rate = payload.total_rate;
      return {
        ...state,
        loading: false,
      };
    case LIKE_PHOTO:
      state.photo.is_liked = true;
      state.photo.likes_count = state.photo.likes_count + 1;
      return {
        ...state,
        loading: false,
      };
    case DISLIKE_PHOTO:
      state.photo.is_liked = false;
      state.photo.likes_count = state.photo.likes_count - 1;
      return {
        ...state,
        loading: false,
      };
    case ADDED_TO_CART:
      state.photo.is_cart = true;
      return {
        ...state,
        loading: false,
      };
    case PHOTO_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
