import * as React from "react"

const StudioIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M57.8 57.8c-6.9 2.5-13.4 7.5-16.9 12.9-6.2 9.9-6 3.8-5.7 129.4l.3 114.4 2.6 5.5c3.6 7.7 7.7 12.1 15.1 16.1l6.3 3.4 73.1.3 73.1.2-1.8-5.5c-1-3-2.3-8.2-3-11.5l-1.2-6h-66.8c-49.7 0-67.5-.3-69.4-1.2-5.8-2.6-5.5 2.9-5.5-117.8 0-79.9.3-111.7 1.1-113.3 3-5.8 1.6-5.7 48.2-5.7h42.8l20.2 19.6c15.2 14.8 21.6 20.3 25.7 22.3l5.5 2.6 81.5.5c63.6.4 82 .8 83.7 1.8 5.1 2.9 5.3 4.6 5.3 40.4v33.4l3.2 1.9c1.8 1 6.9 4.7 11.5 8.2l8.3 6.3v-42.4c0-39.7-.1-42.8-2-48.9-2.7-8.8-9.4-16.2-18.2-20.3l-6.3-2.9-81.6-.5-81.7-.5-19.7-19.3c-15.4-15.2-20.9-20-25.3-22l-5.7-2.7-46-.2c-41.4-.2-46.5-.1-50.7 1.5z" />
    <path d="M301.3 201c-40.1 5.7-72.7 34.6-83.5 74-2 7.3-2.3 10.8-2.3 25.5s.3 18.1 2.3 24.9c11.5 39.6 42.1 67.3 81.5 73.6 11.9 1.9 31 .8 42.2-2.5 37.3-10.9 63.6-39.5 71.6-78 1.9-8.8 1.6-29.4-.5-39-8.2-37.1-36.9-66.8-73.5-76.1-10.8-2.8-27.7-3.8-37.8-2.4zm28.5 23.6c43.3 8.9 70.8 52.6 59.7 94.9-6.5 24.9-26.8 46.4-50.8 54-21.4 6.8-44.3 4.4-63.2-6.7-9-5.3-22-18.3-27.3-27.3-13.4-22.8-14.2-50.7-2.2-74.5 15.3-30.3 50.5-47.3 83.8-40.4z" />
    <path d="M311.1 243.7c-1.7 1.4-3.8 6.5-7.5 17.9l-5.2 15.9-18.3.5c-16.6.5-18.4.7-19.7 2.4-1.5 2.1-1.8 4.5-.8 7.1.4.8 6.9 6.1 14.6 11.7 8.7 6.3 13.7 10.6 13.4 11.4-2.7 7-9.6 30.4-9.6 32.6 0 3 3.2 5.8 6.5 5.8 1.2 0 8.3-4.5 15.9-10.1 7.6-5.5 14.3-9.8 15-9.5.6.2 7 4.8 14.1 10 14.3 10.6 17.1 11.4 20.8 6.5l1.9-2.7-5.1-16c-2.8-8.8-5.1-16.6-5.1-17.4.1-.7 4.2-4.2 9.3-7.8 17-12.1 18.7-13.6 18.7-17.5 0-5.9-2-6.5-21.7-6.5h-17.2l-5.2-16.1c-2.9-8.8-5.8-16.7-6.3-17.4-1.9-2.4-6-2.8-8.5-.8z" />
  </svg>
)

export default StudioIcon
