import React, { createContext, useContext, useReducer } from "react";
import { initialState, dropDownReducer } from "./dropDownReducer";

// Prepares the dataLayer
const dropDownStateContext = createContext();
const dropDownDispatchContext = createContext();

export function useDropDownState() {
	const context = useContext(dropDownStateContext);
	if (context === undefined) {
		throw new Error('usedropDownState must be used within dropDownProvider');
	}

	return context;
}

export function useDropDownDispatch() {
	const context = useContext(dropDownDispatchContext);
	if (context === undefined) {
		throw new Error('usedropDownDispatch must be used within dropDownProvider');
	}

	return context;
}

export const DropDownProvider = ({ children }) => {
  const [dropDown, dispatch] = useReducer(dropDownReducer, initialState);

  return (
    <dropDownStateContext.Provider value={dropDown}>
      <dropDownDispatchContext.Provider value={dispatch}>
        {children}
      </dropDownDispatchContext.Provider>
    </dropDownStateContext.Provider>
  );
};
