import * as React from "react"

const DownloadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M218.3 58c-1.8 1-4 3.4-5 5.2-1.7 3.1-1.8 9.5-2.3 105.1l-.5 101.7-16-16.1c-8.8-8.8-17.2-16.7-18.7-17.5-3.7-1.9-9.7-1.8-13.7.4-5 2.7-7.3 7.5-6.8 14l.4 5.4 31.4 31.4c25.4 25.4 32.1 31.6 35.2 32.5 7.9 2.1 8.6 1.5 42.6-32.5l31.5-31.5.3-5.8c.7-10.3-5.1-15.9-15.4-15.1l-5.5.5-17.4 17.4-17.4 17.3-.2-101.9c-.3-96-.4-102.2-2.1-105.3-2.5-4.5-7.2-7.2-12.7-7.2-2.7 0-5.8.8-7.7 2z" />
    <path d="M94.1 161.6c-7.4 2-14.5 6-20.8 11.8-6.8 6.2-10.6 11.7-14.1 20.3l-2.7 6.8-.3 74.2c-.3 82.1-.3 81.5 6 93.7 6.6 12.6 21.4 23.6 35.6 26.5 3.7.8 42.9 1.1 128.5 1.1 135.8 0 129.8.2 142.1-6.3 7.8-4.1 17.2-13.5 21.3-21.3 6.4-12.1 6.4-11.6 6.1-93.7l-.3-74.2-2.7-6.8c-1.5-3.7-4.1-8.7-5.7-11.1-7-10.2-20.2-19.2-31.5-21.6-3.4-.7-17.6-1-40.4-.8l-35.1.3-3.1 3c-7.7 7.4-5.3 20 4.6 24.1 2.6 1.1 10.3 1.4 35.2 1.4h31.8l5.3 2.6c6.4 3.2 10.6 8.6 12.1 15.7.8 3.6 1 25.8.8 73.7-.3 64.6-.4 68.7-2.2 72-2.6 4.9-6.4 8.8-11.1 11.3l-4 2.2h-247l-4-2.2c-4.7-2.5-8.5-6.4-11.1-11.3-1.8-3.3-1.9-7.4-2.2-72-.2-47.9 0-70.1.8-73.7 1.5-7.1 5.7-12.5 12.1-15.7l5.3-2.6h31.8c24.9 0 32.6-.3 35.2-1.4 9.9-4.1 12.3-16.7 4.6-24.1l-3.1-3-36.2-.2c-29.2-.2-37.2.1-41.6 1.3z" />
  </svg>
)

export default DownloadIcon
