import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import classnames from "classnames";
import Alert from "./Alert";

import { useAlertState } from "../../contexts/alert/alertContext";

const Alerts = ({ enterTimeout, leaveTimeout }) => {
  const { alerts, alertType } = useAlertState();
  const nodeRef = React.useRef(null);
  // const handleRequestHide = (notification) => () => {
  //     if (onRequestHide) {
  //       onRequestHide(notification);
  //     }
  //   };

  const className = classnames("notification-container", {
    "notification-container-empty": alerts.length === 0,
  });
  return (
    <div className={className}>
      <TransitionGroup>
        {alerts.length > 0 &&
          alerts.map((notification) => {
            const key = new Date().getTime();
            return (
              <CSSTransition
                nodeRef={nodeRef}
                classNames="notification"
                key={key}
                timeout={{ exit: leaveTimeout, enter: enterTimeout }}
              >
                <div ref={nodeRef}>
                  <Alert
                    key={key}
                    type={alertType}
                    //title={notification.title}
                    message={
                      notification.message ? notification.message : notification
                    }
                    //timeOut={notification.timeOut}
                    //onClick={notification.onClick}
                    //onRequestHide={handleRequestHide(notification)}
                    customClassName={"filled"}
                  />
                </div>
              </CSSTransition>
            );
          })}
      </TransitionGroup>
    </div>
  );
};
Alerts.propTypes = {
  onRequestHide: PropTypes.func,
  enterTimeout: PropTypes.number,
  leaveTimeout: PropTypes.number,
};

Alerts.defaultProps = {
  onRequestHide: () => {},
  enterTimeout: 400,
  leaveTimeout: 1000,
};
export default Alerts;
