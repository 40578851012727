import * as React from "react"

const PrizeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 452 452"
    fill="currentColor"
    {...props}
  >
    <path d="M214.1 57.6c-16.6 4.4-27.7 17.1-30.4 34.5-2.5 16.2 4.7 33.9 17 41.9 2.3 1.5 2.1 1.7-18 32.7-11.1 17.2-20.5 31.2-20.8 31.3-.4 0-14.8-12.9-32.1-28.6l-31.5-28.6 2.6-6.1c2.2-5.4 2.6-7.7 2.6-17.2 0-10.5-.2-11.4-3.4-18.3-4.4-9.2-13.2-17.9-21.8-21.3-8.5-3.3-25.3-3.4-33.6 0-22.6 9-32.7 35.8-22.3 58.6 4.8 10.4 13.9 18.4 24.7 21.8l5.6 1.8L68 235.8l15.4 75.7.6 29.5c.5 29.2.5 29.6 3.1 35 3.7 8 8.1 12.5 15.7 16.2l6.7 3.3h233l6.7-3.3c7.6-3.7 12-8.2 15.7-16.2 2.6-5.4 2.6-5.8 2.8-32.5l.3-27 15.7-78.2 15.6-78.3 6.3-2c10.3-3.5 18.6-10.6 23.6-20.4 3-5.8 5.1-17.2 4.3-23.6-2.6-22.6-17.6-37.3-39.6-38.8-19.1-1.2-33.7 7.1-41.8 23.8-3.5 7.2-3.6 7.9-3.6 18.5 0 9.5.4 11.8 2.6 17.2l2.6 6.1-31.5 28.6c-17.3 15.7-31.7 28.6-32.1 28.6-.3-.1-9.7-14.1-20.8-31.3-20.1-30.9-20.2-31.2-18-32.7 16.1-10.5 22.2-35.2 13.4-54.4-8.2-17.9-30.2-27.5-50.6-22zm21.2 31.3c5.5 5.5 5.3 15.8-.5 20.6-2.4 2-4 2.5-8.8 2.5-4.8 0-6.4-.5-8.8-2.5-5.7-4.8-6-14.9-.6-20.6 3.1-3.3 5.2-3.9 11.4-3.3 3.2.3 5.3 1.2 7.3 3.3zm-166 17.4c1.4.9 3.3 3.6 4.2 6.1 2.3 6 1.3 11-3 15.3-2.9 2.9-4 3.3-8.4 3.3-10 0-14.7-5-13.9-14.7.5-5.5 3-9.5 7.3-11.4 2.9-1.3 11-.5 13.8 1.4zm331 1.5c2.4 2.5 3.1 4.1 3.5 8.5.8 9.7-3.9 14.7-13.9 14.7-4.4 0-5.5-.4-8.4-3.3-4.5-4.5-5.4-9.5-2.9-15.6 2.5-5.9 6.8-8.4 13.7-7.8 3.8.3 5.6 1.1 8 3.5zm-148.8 85.7c20.4 31.3 25.7 38.8 28.6 40.5 4.1 2.2 9 2.5 13.1.7 2.1-.9 41.5-36.1 69.9-62.4l4.6-4.3-12.8 64.3c-7.1 35.3-13.1 65.2-13.4 66.4l-.5 2.3H111l-.5-2.3c-.3-1.2-6.3-31.1-13.4-66.4L84.3 168l4.6 4.3c28.4 26.3 67.8 61.5 69.9 62.4 6.7 3 13.8.6 18.9-6.5 1.5-2.1 12.9-19.4 25.3-38.5s22.7-34.7 23-34.7c.3 0 11.7 17.3 25.5 38.5zm87.3 153.6c-.3 15.5-.5 17.2-2.2 18.5-3 2.1-218.2 2.1-221.2 0-1.7-1.3-1.9-3-2.2-18.5l-.3-17.1h226.2l-.3 17.1z" />
  </svg>
)

export default PrizeIcon
