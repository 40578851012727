import * as ClevertapEvents from '../constants/cleverTapEvents';
import * as Clevertap from './cleverTapUtil';

export const welcomeLoginClicked = () => Clevertap.logEvent(ClevertapEvents.loginClickedWelcome);
export const welcomeSignupClicked = () => Clevertap.logEvent(ClevertapEvents.signupClickedWelcome);
export const loginClicked = () =>
    Clevertap.logEvent(ClevertapEvents.loginClicked, {'login_type': "normal"});
export const forgotPassword = () => Clevertap.logEvent(ClevertapEvents.forgotPassword);
export const signupClicked = () => Clevertap.logEvent(ClevertapEvents.signupClicked);
export const loginSuccessful = ( successStatus) =>
    Clevertap.logEvent(ClevertapEvents.loginSuccessful,{
      'login_type': "normal",
      'success_status': successStatus,
    });
export const exploreButtonClicked = () => Clevertap.logEvent(ClevertapEvents.exploreButtonClicked);
export const campaignButtonClicked = () => Clevertap.logEvent(ClevertapEvents.campaignButtonClicked);
export const downloadsClicked = () => Clevertap.logEvent(ClevertapEvents.downloadsClicked);
export const cartClicked = () => Clevertap.logEvent(ClevertapEvents.cartClicked);
export const profileButtonClicked = () => Clevertap.logEvent(ClevertapEvents.profileButtonClicked);
export const photoClicked = ({
    photoId,
    caption, 
    tags, 
    sectionTitle, 
    clickedPhotographerName, 
    clickedPhotographerId,
}) =>
    Clevertap.logEvent(ClevertapEvents.photoClicked, {
      'photo_id': photoId,
      'caption': caption,
      'tags': tags,
      'section_title': sectionTitle,
      'clicked_photographer_name': clickedPhotographerName,
      'clicked_photographer_id': clickedPhotographerId
    });

export const photoLiked = ({
   photoId,
   caption,
   tags,
   clickedPhotographerName,
   clickedPhotographerId,
}) =>
    Clevertap.logEvent(ClevertapEvents.photoLiked, {
      'photo_id': photoId,
      'caption': caption,
      'tags': tags,
      'clicked_photographer_name': clickedPhotographerName,
      'clicked_photographer_id': clickedPhotographerId
    });

export const photoComment = ({
   photoId,
   caption,
   tags,
   commentText,
   clickedPhotographerName,
   clickedPhotographerId,
}) =>
    Clevertap.logEvent(ClevertapEvents.photoComment, {
      'photo_id': photoId,
      'caption': caption,
      'tags': tags,
      'comment_text': commentText,
      'clicked_photographer_name': clickedPhotographerName,
      'clicked_photographer_id': clickedPhotographerId
    });

export const addToStudio = ({
   photoId,
   clickedPhotographerName,
   clickedPhotographerId,
   studioName,
   dimensions,
   size,
   dpi,
}) =>
    Clevertap.logEvent(ClevertapEvents.addToStudio, {
      'photo_id': photoId,
      'clicked_photographer_name': clickedPhotographerName,
      'clicked_photographer_id': clickedPhotographerId,
      'studio_name': studioName,
      'dimensions': dimensions,
      'size': size,
      'dpi': dpi
    });

export const addToCart = ({
   photoId,
   clickedPhotographerName,
   clickedPhotographerId,
   dimensions,
   size,
   dpi,
}) =>
    Clevertap.logEvent(ClevertapEvents.addToCart, {
      'photo_id': photoId,
      'clicked_photographer_name': clickedPhotographerName,
      'clicked_photographer_id': clickedPhotographerId,
      'dimensions': dimensions,
      'size': size,
      'dpi': dpi
    });

export const photographerClicked = ({
   sourceOfEvent,
   isActive,
   countryId,
   countryName,
   clickedPhotographerName,
   clickedPhotographerId,
}) =>
    Clevertap.logEvent(ClevertapEvents.photographerClicked, {
      'source_of_event': sourceOfEvent,
      'is_active': isActive,
      'country_id': countryId,
      'country_name': countryName,
      'clicked_photographer_name': clickedPhotographerName,
      'clicked_photographer_id': clickedPhotographerId
    });

export const followClicked = ({
   sourceOfEvent,
   isActive,
   status,
   countryId,
   countryName,
   clickedPhotographerName,
   clickedPhotographerId,
}) =>
    Clevertap.logEvent(ClevertapEvents.followClicked, {
      'source_of_event': sourceOfEvent,
      'is_active': isActive,
      'status': status,
      'country_id': countryId,
      'country_name': countryName,
      'clicked_photographer_name': clickedPhotographerName,
      'clicked_photographer_id': clickedPhotographerId
    });

export const viewAllClicked = ({
   sectionTitle,
   isActive,
   countryId,
   countryName,
}) =>
    Clevertap.logEvent(ClevertapEvents.viewAllClicked, {
      'section_title': sectionTitle,
      'is_active': isActive,
      'country_id': countryId,
      'country_name': countryName
    });

export const pageRefreshed = () => Clevertap.logEvent(ClevertapEvents.pageRefreshed);

export const searchBar = ({
   searchText,
   buttonName,
}) =>
    Clevertap.logEvent(ClevertapEvents.searchBar, {
      'search_text': searchText,
      'button_name': buttonName,
    });

export const policyClicked = () => Clevertap.logEvent(ClevertapEvents.policyClicked);
export const contactUs = () => Clevertap.logEvent(ClevertapEvents.contactUs);
export const aboutUs = () => Clevertap.logEvent(ClevertapEvents.aboutUs);
export const logout = () => Clevertap.logEvent(ClevertapEvents.logout);
export const deleteAccount = () => Clevertap.logEvent(ClevertapEvents.deleteAccount);

export const deleteAccountConfirm = ({
   confirmed,
}) =>
    Clevertap.logEvent(ClevertapEvents.deleteAccountConfirm, {
      'confirmed': confirmed,
    });

export const myStudiosClicked = () => Clevertap.logEvent(ClevertapEvents.myStudiosClicked);

export const studioClicked = ({
   studioName,
   sourceOfEvent,
}) =>
    Clevertap.logEvent(ClevertapEvents.studioClicked, {
      'studio_name': studioName,
      'source_of_event': sourceOfEvent,
    });

export const setUpBrandClicked = () => Clevertap.logEvent(ClevertapEvents.setUpBrandClicked);
export const accountDetailsClicked = () => Clevertap.logEvent(ClevertapEvents.accountDetailsClicked);
export const checkOutNowClicked = () => Clevertap.logEvent(ClevertapEvents.checkOutNowClicked);

export const proceedToPaymentClicked = ({
   amount,
   itemsCount,
}) =>
    Clevertap.logEvent(ClevertapEvents.proceedToPaymentClicked, {
      'amount': amount,
      'items_count': itemsCount,
    });

export const downloadClicked = ({
   photoId,
   sectionTitle,
}) =>
    Clevertap.logEvent(ClevertapEvents.downloadClicked, {
      'photo_id': photoId,
      'section_title': sectionTitle,
    });

export const requestCampaign = ({
   campaignName,
   campaignDescription,
   campaignPrize,
   campaignTags,
   campaignStart,
   campaignEnd,
   numberWinners,
}) =>
    Clevertap.logEvent(ClevertapEvents.requestCampaign, {
      'campaign_name': campaignName,
      'campaign_description': campaignDescription,
      'campaign_prize': campaignPrize,
      'campaign_tags': campaignTags,
      'campaign_start': campaignStart,
      'campaign_end': campaignEnd,
      'number_winners': numberWinners,
    });

export const draftCampaign = ({
   campaignName,
   campaignDescription,
   campaignPrize,
   campaignTags,
   campaignStart,
   campaignEnd,
   numberWinners,
}) =>
    Clevertap.logEvent(ClevertapEvents.draftCampaign, {
      'campaign_name': campaignName,
      'campaign_description': campaignDescription,
      'campaign_prize': campaignPrize,
      'campaign_tags': campaignTags,
      'campaign_start': campaignStart,
      'campaign_end': campaignEnd,
      'number_winners': numberWinners,
    });