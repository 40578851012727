import {
  GET_PHOTOHRAPHER,
  GET_PHOTOHRAPHERS,
  PHOTOHRAPHER_ERROR,
  GET_MORE_PHOTOHRAPHERS,
  GET_MORE_PHOTOGRAPHER_PHOTOS,
  GET_PHOTOGRAPHER_PHOTOS,
  FOLLOW_PHOTOHRAPHER,
  UNFOLLOW_PHOTOHRAPHER,
  RESET_FOLLOW,
  ROOM_LOADED,
  ROOM_ERROR,
  GET_MORE_ROOM_PHOTOS,
} from "../../constants/photographer";
import { SET_LOADING, RESET_ALL_LOADED } from "../../constants/shared";

export const initialState = {
  loading: false,
  photographers: [],
  photographer: {},
  photos: [],
  pagination: {},
  photosPagination: {},
  allPhotosLoaded: false,
  allLoaded: false,
};

export const photographerReducer = (state, action) => {
  const { type, payload } = action;
  let copyOfObject = {};
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PHOTOHRAPHERS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        photographers: payload.data.data ? payload.data.data : payload.data,
        pagination: copyOfObject,
        allLoaded: payload.data.current_page === payload.data.last_page,
        loading: false,
      };
    case GET_MORE_PHOTOHRAPHERS:
      copyOfObject = { ...payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        photographers: state.photographers
          ? [...state.photographers, ...payload.data.data]
          : payload.data.data,
        pagination: copyOfObject,
        allLoaded: payload.data.current_page === payload.data.last_page,
        loading: false,
      };
    case GET_PHOTOHRAPHER:
      return {
        ...state,
        loading: false,
        photographer: payload,
      };
    case GET_PHOTOGRAPHER_PHOTOS:
      copyOfObject = { ...action.payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        photos: action.payload.data.data,
        photosPagination: copyOfObject,
        allPhotosLoaded:
          action.payload.data.current_page === action.payload.data.last_page,
        loading: false,
      };
    case GET_MORE_PHOTOGRAPHER_PHOTOS:
      copyOfObject = { ...action.payload.data };
      delete copyOfObject["data"];
      return {
        ...state,
        photos: state.photos
          ? [...state.photos, ...action.payload.data.data]
          : action.payload.data.data,
        photosPagination: copyOfObject,
        allPhotosLoaded:
          action.payload.data.current_page === action.payload.data.last_page,
        loading: false,
      };
    case FOLLOW_PHOTOHRAPHER:
      if (state.photographer)
      {
      state.photographer.is_follow = true;
      state.photographer.followers_count = state.photographer.followers_count + 1;
      }
      return{
        ...state,
        loading:false,
        is_follow: true,
      }
    
    
      case UNFOLLOW_PHOTOHRAPHER:
        if (state.photographer)
        {
        state.photographer.is_follow = false;
        state.photographer.followers_count = state.photographer.followers_count - 1;
        }
      return{
        ...state,
        loading:false,
        is_follow: false,
      }
    
      case ROOM_LOADED:
        copyOfObject = { ...payload.photos };
        delete copyOfObject["data"];
        var copyRoom = {...payload};
        delete copyRoom["photos"]
        return {
          ...state,
          room:copyRoom,
          roomPhotos:payload.photos.data,
          roomPagination: copyOfObject,
          allRoomPhotosLoaded:
          copyOfObject.current_page === copyOfObject.last_page,
          loading: false,
        };

        case GET_MORE_ROOM_PHOTOS:
          copyOfObject = { ...payload.photos };
          delete copyOfObject["data"];
          var copyRoom = {...payload};
          delete copyRoom["photos"]
      return {
        ...state,
        roomPhotos: state.roomPhotos
          ? [...state.roomPhotos, ...payload.photos.data]
          : payload.photos.data,
          roomPagination: copyOfObject,
          allRoomPhotosLoaded:
          copyOfObject.current_page === copyOfObject.last_page,
        loading: false,
      };
        case ROOM_ERROR:
          if (action.error?.find(err=> err.code === 400))
            state.ROOM = null;
          return {
            ...state,
            loading: false,
          };
    case PHOTOHRAPHER_ERROR:
      if (action.error?.find(err=> err.code === 1404))
        state.photographer = null;
      return {
        ...state,
        loading: false,
      };
    case RESET_ALL_LOADED:
      return {
        ...state,
        photographers: [],
        photographer: null,
        pagination: {},
        photosPagination: {},
        allPhotosLoaded: false,
        photos: [],
      };
      case RESET_FOLLOW:
        return {
          ...state,
          is_follow:undefined
        };

    default:
      return state;
  }
};
